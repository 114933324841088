/**
 * Group name.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import * as utils from '../../../utils';

export default async function ReadGroup(db, groupId, isGuest, uid) {
  try {
    //console.log(db, groupId);
    var group;
    if (isGuest) {
      const guestGrbRef = db
        .collection(utils.USERS_KEY + '/' + uid + '/' + utils.GROUP_KEY)
        .doc(groupId);
      const guesDoc = await guestGrbRef.get();
      if (guesDoc.exists) {
        group = guesDoc.data();
        group.isGuest = isGuest;
      } else {
        group = await readGroupFromPub();
      }
    } else {
      group = await readGroupFromPub();
    }
    return group;
  } catch (err) {
    console.error(err);
    return;
  }

  async function readGroupFromPub() {
    try {
      var groupData;
      const grbRef = db.collection(utils.GROUP_KEY).doc(groupId);
      const doc = await grbRef.get();

      if (doc.exists) {
        groupData = doc.data();
        groupData.isGuest = isGuest;
      }
      return groupData;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
}
