/**
 * Wizard log in
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useLanguageContext } from 'context/LanguageContext';
import useTranslation from 'hooks/translations';
//import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import Modal from 'react-bootstrap/Modal';
import loadingGif from 'assets/img/Spin98px.gif';

export default function WizardLogIn({ uiConfig, firebaseAuth, type }) {
  console.log(type);
  const { changeLanguage } = useLanguageContext();
  const translation = useTranslation();
  const urlResult = new URLSearchParams(window.location.search);
  var redirectUri = '/index.html';
  if (urlResult.has('id')) {
    const name = urlResult.has('name') ? urlResult.get('name') : '';
    const hw_major = urlResult.has('hw_major') ? urlResult.get('hw_major') : '';
    const hw_minor = urlResult.has('hw_minor') ? urlResult.get('hw_minor') : '';
    const hw_params = urlResult.has('hw_params')
      ? urlResult.get('hw_params')
      : '';
    const mech_variant = urlResult.has('mech_variant')
      ? urlResult.get('mech_variant')
      : '';
    const firmware = urlResult.has('firmware') ? urlResult.get('firmware') : '';
    const bootloader = urlResult.has('bootloader')
      ? urlResult.get('bootloader')
      : '';
    const analyticsId = urlResult.has('analytics_id')
      ? urlResult.get('analytics_id')
      : '';
    const serialNumber = urlResult.has('serial') ? urlResult.get('serial') : '';
    console.log(uiConfig);
    redirectUri +=
      '?id=' +
      urlResult.get('id') +
      '&name=' +
      name +
      '&hw_major=' +
      hw_major +
      '&hw_minor=' +
      hw_minor +
      '&hw_params=' +
      hw_params +
      '&mech_variant=' +
      mech_variant +
      '&firmware=' +
      firmware +
      '&bootloader=' +
      bootloader +
      '&analytics_id=' +
      analyticsId +
      '&serial=' +
      serialNumber;

    uiConfig.signInSuccessUrl = redirectUri;
    //hw_major=2&hw_minor=1&hw_params=127&mech_variant=0&firmware=47&bootloader=8
  }
  // var logInDefaultView = (
  //   <StyledFirebaseAuth uiConfig={config} firebaseAuth={firebaseAuth} />
  // );

  // if (
  //   type != undefined &&
  //   (type == 'deviceregistration' || type == 'smartthings')
  // ) {
  //   logInDefaultView = (
  //     <>
  //       <button
  //         className={`btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100`}
  //         type="button"
  //         onClick={android}
  //       >
  //         Android
  //       </button>
  //       <br />
  //       <button
  //         className={`btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100`}
  //         type="button"
  //         onClick={iOS}
  //       >
  //         iOS
  //       </button>
  //     </>
  //   );
  // }
  // const [config, setConfig] = useState(uiConfig);
  const [showModal, setModalShow] = useState(true);
  //const [logInView, setLogInView] = useState(logInDefaultView);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        userId: urlResult.has('analytics_id')
          ? urlResult.get('analytics_id')
          : '',
        userProject: 'Personal',
        page: 'Wizard login',
        analytics_id: urlResult.has('analytics_id')
          ? urlResult.get('analytics_id')
          : ''
      },
      dataLayerName: 'SaferaDataLayer'
    });

    var userLang = navigator.language || navigator.userLanguage;
    console.log(userLang);

    changeLanguage(userLang !== undefined ? userLang : 'en');
    setTimeout(function () {
      setModalShow(false);
    }, 2000);
  }, []);

  // function android() {
  //   console.log('Android');
  //   uiConfig.signInFlow = 'redirect';
  //   setConfig(uiConfig);
  //   setLogInView(
  //     <StyledFirebaseAuth uiConfig={config} firebaseAuth={firebaseAuth} />
  //   );
  // }

  // function iOS() {
  //   console.log('iOS');
  //   setLogInView(
  //     <StyledFirebaseAuth uiConfig={config} firebaseAuth={firebaseAuth} />
  //   );
  // }

  return (
    <>
      <div className="wizard-body device-settings-page">
        <header className="d-flex justify-content-center wizard-header sticky-top w-100">
          <nav className="wizard-header-content h-100 w-100">
            <div className="container d-flex flex-column w-100 h-100">
              <div className="row d-flex flex-grow-1 align-items-center">
                <div className="col d-flex flex-column justify-content-end align-items-center w-100">
                  <h5 className="text-truncate text-center text-white w-100 mb-0">
                    {translation.wizard_cloud_title}
                  </h5>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="wizard-div-above-container p-0">
          <div className="container wizard-container p-0 shadow-lg">
            <div className="d-flex flex-column wizard-content pb-4">
              <div className="d-flex flex-column wizard-upper-content">
                <div className="container h-100 py-4">
                  <div className="row d-flex flex-column justify-content-start align-items-center h-100">
                    <div className="col d-flex justify-content-center align-items-center mb-2">
                      <i className="fas fa-user-circle fs-1 me-2"></i>
                      <h6 className="text-start d-inline-flex mb-0">
                        {translation.wizard_cloud_log_in_step}
                      </h6>
                    </div>
                    <div className="col d-flex flex-grow-0 justify-content-center">
                      <h3 className="text-center">
                        {translation.wizard_cloud_log_in_heading}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-grow-1 align-items-start wizard-lower-content">
                <div className="row d-flex flex-column align-items-start w-100 m-0 h-100">
                  <div className="col d-inline-flex flex-column flex-grow-1 justify-content-start h-100">
                    <StyledFirebaseAuth
                      uiConfig={uiConfig}
                      firebaseAuth={firebaseAuth}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} role="dialog" tabIndex="-1" id="modal-loading">
        <div className="modal-body d-flex justify-content-center">
          <img src={loadingGif} alt="loading..." />
        </div>
      </Modal>
    </>
  );
}

WizardLogIn.propTypes = {
  firebaseAuth: PropTypes.object,
  uiConfig: PropTypes.object,
  type: PropTypes.string
};
