/**
 * Read device
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../../utils';
import CheckAccess from '../CheckAccess';
import ReadOwner from './ReadOwner';

export async function ReadDevice(db, deviceId, uid) {
  const access = await CheckAccess(db, deviceId, uid);
  if (!access.isValid) {
    console.log('Device read: Access denied!');
    return;
  }
  // var owner = uid;
  // if (!access.isOwner) {
  const owner = await ReadOwner(db, deviceId);
  //}
  //console.log(access);
  try {
    const usrDevRef = db.collection(utils.DEVICE_KEY).doc(deviceId);
    const devDoc = await usrDevRef.get();
    if (devDoc.exists) {
      var devData = devDoc.data();
      devData.owner = owner;

      return devData;
    }
  } catch (error) {
    console.error(error);
  }
  return;
}
