/**
 * Rename Group name.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import * as utils from '../../../utils';

export default async function RenameGroupName(
  db,
  groupId,
  newName,
  isGuest,
  uid
) {
  try {
    //console.log(db, groupId);
    if (isGuest) {
      const grbRef = db
        .collection(utils.USERS_KEY + '/' + uid + '/' + utils.GROUP_KEY)
        .doc(groupId);
      await grbRef.set({ name: newName }, { merge: true });
    } else {
      const grbRef = db.collection(utils.GROUP_KEY).doc(groupId);
      await grbRef.set({ name: newName }, { merge: true });
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
