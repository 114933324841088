/**
 * Battery control.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default function ConvertBatteryVoltageToPercent(voltage) {
  var vbat = voltage / 3000;

  if (vbat >= 1.43) {
    vbat = 84.768 * vbat - 29.742;
  } else if (vbat >= 1.25) {
    vbat = 302.46 * vbat - 340.26;
  } else {
    vbat = 86.654 * vbat - 70.07;
  }
  if (vbat > 100.0) {
    vbat = 100.0;
  } else if (vbat < 0) {
    vbat = 0;
  }

  if (isNaN(vbat)) {
    return 0;
  }

  return vbat;
}
