/**
 * Battery.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBatteryEmpty,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBatteryFull
} from '@fortawesome/free-solid-svg-icons';

export default function Battery({ batteryPercentage }) {
  if (batteryPercentage == undefined) {
    return '';
  }
  var icon = faBatteryEmpty;
  if (batteryPercentage > 95) {
    icon = faBatteryFull;
  } else if (batteryPercentage > 75) {
    icon = faBatteryThreeQuarters;
  } else if (batteryPercentage > 50) {
    icon = faBatteryHalf;
  } else if (batteryPercentage > 20) {
    icon = faBatteryQuarter;
  } else {
    icon = faBatteryEmpty;
  }

  var batteryIcon = <FontAwesomeIcon icon={icon} className="text-black" />;
  return batteryIcon;
}

Battery.propTypes = { batteryPercentage: PropTypes.number };
