/**
 * Parse daylog data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../../utils';
import ConvertBatteryVoltageToPercent from './ConvertBatteryVoltageToPercent';

export default function GetDayObjectV4(doc) {
  var dayObj = {};
  dayObj[utils.UNIX_EPOCH_NAME] = doc.data()[utils.UNIX_EPOCH_NAME];
  dayObj[utils.BATTERY_NAME] = ConvertBatteryVoltageToPercent(
    doc.data()[utils.BATTERY_NAME]
  );
  dayObj[utils.TOTAL_ALARM_COUNT_NAME] =
    doc.data()[utils.TOTAL_ALARM_COUNT_NAME];
  dayObj[utils.TOTAL_POWER_OFF_COUNT_NAME] =
    doc.data()[utils.TOTAL_POWER_OFF_COUNT_NAME];
  dayObj[utils.TOTAL_COOKING_COUNT_NAME] =
    doc.data()[utils.TOTAL_COOKING_COUNT_NAME];
  dayObj[utils.TOTAL_POWER_OFF_COUNT_NAME] =
    doc.data()[utils.TOTAL_POWER_OFF_COUNT_NAME];
  dayObj[utils.COOKING_KWH_NAME] = parseFloat(
    doc.data()[utils.COOKING_KWH_NAME] / 1000
  );
  var t_max = parseFloat(doc.data()[utils.TEMP_MAX], 10).toFixed(2);
  dayObj[utils.TEMP_MAX] = t_max / 100 - 50;
  dayObj[utils.TEMP_MAX_TIME_NAME] = doc.data()[utils.TEMP_MAX_TIME_NAME];
  var t_min = parseFloat(doc.data()[utils.TEMP_MIN], 10).toFixed(2);
  dayObj[utils.TEMP_MIN] = t_min / 100 - 50;
  dayObj[utils.TEMP_MIN_TIME_NAME] = doc.data()[utils.TEMP_MIN_TIME_NAME];
  dayObj[utils.TEMP_IR_MAX_NAME] = doc.data()[utils.TEMP_IR_MAX_NAME];
  dayObj[utils.TEMP_IR_MAX_TIME_NAME] = doc.data()[utils.TEMP_IR_MAX_TIME_NAME];
  dayObj[utils.TEMP_IR_MIN_NAME] = doc.data()[utils.TEMP_IR_MIN_NAME];
  dayObj[utils.TEMP_IR_MIN_TIME_NAME] = doc.data()[utils.TEMP_IR_MIN_TIME_NAME];
  dayObj[utils.RH_MIN] = doc.data()[utils.RH_MIN];
  dayObj[utils.RH_MIN_TIME_NAME] = doc.data()[utils.RH_MIN_TIME_NAME];
  dayObj[utils.RH_MAX] = doc.data()[utils.RH_MAX];
  dayObj[utils.RH_MAX_TIME_NAME] = doc.data()[utils.RH_MAX_TIME_NAME];
  dayObj[utils.PM25_MAX_NAME] = doc.data()[utils.PM25_MAX_NAME];
  dayObj[utils.PM25_MAX_TIME_NAME] = doc.data()[utils.PM25_MAX_TIME_NAME];
  dayObj[utils.TVOC_MAX] = doc.data()[utils.TVOC_MAX];
  dayObj[utils.TVOC_MAX_TIME_NAME] = doc.data()[utils.TVOC_MAX_TIME_NAME];
  dayObj[utils.ECO2_MAX] = doc.data()[utils.ECO2_MAX];
  dayObj[utils.ECO2_MAX_TIME_NAME] = doc.data()[utils.ECO2_MAX_TIME_NAME];
  dayObj[utils.PCU_STATUS_NAME] = doc.data()[utils.PCU_STATUS_NAME];
  dayObj[utils.MIU_FAILURE_NAME] = doc.data()[utils.MIU_FAILURE_NAME];
  dayObj[utils.PCU_FAILURE_NAME] = doc.data()[utils.PCU_FAILURE_NAME];
  dayObj[utils.MIU_STOVE_TYPE_NAME] = doc.data()[utils.MIU_STOVE_TYPE_NAME];
  dayObj[utils.MIU_INST_HEIGHT_NAME] = doc.data()[utils.MIU_INST_HEIGHT_NAME];
  dayObj[utils.MIU_INST_POS_NAME] = doc.data()[utils.MIU_INST_POS_NAME];
  dayObj[utils.MIU_TIMEZONE_NAME] = doc.data()[utils.MIU_TIMEZONE_NAME];
  dayObj[utils.MEAS_COUNT_30MIN] = doc.data()[utils.MEAS_COUNT_30MIN];
  dayObj[utils.SENSOR_DATA_NAME] = doc.data()[utils.SENSOR_DATA_NAME];
  return dayObj;
}
