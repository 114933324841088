/**
 * Firebase Config.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default function FirebaseConfig() {
  return {
    apiKey: 'AIzaSyC8lgNCueeSP77yl8L7haGK8OnOsljHkQU',
    authDomain: 'safera-sense.firebaseapp.com',
    databaseURL: 'https://safera-sense.firebaseio.com',
    projectId: 'safera-sense',
    storageBucket: 'safera-sense.appspot.com',
    messagingSenderId: '977795309346',
    appId: '1:977795309346:web:c13095671238e38ac08f7b',
    measurementId: 'G-K6FNF5NN7Z'

    // Safera Sense Test site

    // apiKey: 'AIzaSyD9Ew8OVUQTKfcwe9V4wLVWtDOoxaYfg1k',
    // authDomain: 'safera-sense-test.firebaseapp.com',
    // projectId: 'safera-sense-test',
    // storageBucket: 'safera-sense-test.appspot.com',
    // messagingSenderId: '1082400300790',
    // appId: '1:1082400300790:web:e2461c364a155cafcc56d7',
    // measurementId: 'G-P631Y8N648'
  };
}
