/**
 * Long International Time Format.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
export default function LongInternationalTimeFormat(timestamp, lang) {
  try {
    if (timestamp === undefined) {
      return '-';
    }
    if (lang === undefined) {
      lang = 'en';
    }
    var date = new Date(parseInt(timestamp));

    var options = {
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    return new Intl.DateTimeFormat(lang, options).format(date);
  } catch (error) {
    console.log(timestamp, lang);
    console.error(error);
    return '-';
  }
}
