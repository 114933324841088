/**
 * SMS check box.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import writeModel from '../../../../components/DataBase/DataBaseWriteModel';
import * as utils from '../../../../utils';
import SmsText from './SmsText';

class SmsCheckBox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.isChecked = false;
    if (props.device !== undefined) {
      if (props.device.isAlarm_sms_notify_enabled === undefined) {
        this.isChecked = true;
      } else if (props.device.isAlarm_sms_notify_enabled) {
        this.isChecked = true;
      }
    }
    this.state = { checked: this.isChecked };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // if (this.props.userID !== prevProps.userID) {
    //   this.fetchData(this.props.userID);
    // }

    if (this.props.device !== prevProps.device) {
      if (this.props.device.isAlarm_sms_notify_enabled === undefined) {
        this.setState({ checked: true });
      } else if (this.props.device.isAlarm_sms_notify_enabled) {
        this.setState({ checked: true });
      } else {
        this.setState({ checked: false });
      }
    }
  }

  handleChange({ target }) {
    var currentDevice = { ...this.props.device };
    if (target.checked) {
      //target.removeAttribute('checked');
      this.setState({ checked: true });
      currentDevice.isAlarm_sms_notify_enabled = true;
    } else {
      //target.setAttribute('checked', true);
      this.setState({ checked: false });
      currentDevice.isAlarm_sms_notify_enabled = false;
    }
    this.props.setDevice(currentDevice);
    writeModel.path =
      utils.USERS_KEY + '/' + this.props.user.uid + '/' + utils.DEVICE_KEY;
    writeModel.doc = this.props.device.docId;
    writeModel.data = {
      isAlarm_sms_notify_enabled: currentDevice.isAlarm_sms_notify_enabled
    };
    writeModel.callback = undefined;
    this.props.dbUpdate(writeModel);
  }
  render() {
    return (
      <div className="form-check d-flex align-items-start form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="smsCheck"
          onChange={this.handleChange}
          checked={this.state.checked}
          disabled={this.props.disabled}
        />
        <label className="form-check-label text-break mt-1" htmlFor="smsCheck">
          <SmsText />
        </label>
      </div>
    );
  }
}
export default SmsCheckBox;
SmsCheckBox.propTypes = {
  device: PropTypes.object,
  disabled: PropTypes.bool,
  user: PropTypes.object,
  setDevice: PropTypes.func,
  dbUpdate: PropTypes.func
};
