/**
 * Read device data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import * as utils from 'utils';
import { ReadDevice } from './ReadDevice';

export async function ReadDeviceData(db, deviceId, uid) {
  var deviceData;
  const usrDevRef = db
    .collection(utils.USERS_KEY + '/' + uid + '/' + utils.DEVICE_KEY)
    .where('device_id', '==', deviceId);
  const usrDevDoc = await usrDevRef.get();
  for (const doc of usrDevDoc.docs) {
    if (doc.data().isRegistered) {
      const devData = await ReadDevice(db, doc.data().device_id, uid);
      deviceData = doc.data();
      deviceData['docId'] = doc.id;
      if (devData !== undefined) {
        deviceData.data = devData;
      }
    }
  }
  return deviceData;
}
