/**
 * Device history view.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
//import { useStoreState } from 'easy-peasy';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import DataPeriod from './DataPeriod';
import Chart from './ChartComponent';
import PowerChart from './PowerChartComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import * as utils from 'utils';
import useTranslation from '../../../../hooks/translations';
import EventTable from './EventTable';
import ReadEvents from '../../../../components/DataBase/ReadEvents';
import ReadDayLogs from 'data/SensorData/ReadDayLogs';

export default function DeviceHistory({ db, device, user }) {
  const translation = useTranslation();

  const CHART_DEFAULT_HEIGHT = '350px';
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [isPCU, setIsPCU] = useState(false);
  const [totalPower, setTotalPower] = useState(0);
  const [supportedCharts, setSupportedCharts] = useState('');
  //const db = useStoreState(state => state.db);

  const handle = useFullScreenHandle();
  const [isFull] = useState();
  const [chartHeight, setChartHeight] = useState(CHART_DEFAULT_HEIGHT);
  const [eventDataStore, setEventDataStore] = useState([]);
  //var eventDataStore = '';
  const [filter, setFilter] = useState({
    alarm: true,
    error: true,
    others: true
  });

  useEffect(() => {
    readData({
      start: moment().subtract(29, 'days'),
      end: moment()
    });
    readEventData({
      start: moment().subtract(29, 'days'),
      end: moment()
    });
  }, []);

  useEffect(() => {
    //console.log(data);
    if (data.length == 0) {
      readData({
        start: moment().subtract(29, 'days'),
        end: moment()
      });
    }
    if (eventData.length == 0) {
      readEventData({
        start: moment().subtract(29, 'days'),
        end: moment()
      });
    }
  }, [device]);

  const [filterCount, setFilterCount] = useState(3);
  function setFullScreen() {
    console.log('Full');
    setChartHeight('50%');
    handle.enter();
  }

  function filterEventData() {
    var eventFilteredData = JSON.parse(JSON.stringify(eventDataStore));

    if (!filter.alarm) {
      eventFilteredData = eventFilteredData.filter(item => !isAlarm(item.code));
    }
    if (!filter.error) {
      eventFilteredData = eventFilteredData.filter(item => !isError(item.code));
    }
    if (!filter.others) {
      eventFilteredData = eventFilteredData.filter(
        item => isError(item.code) || isAlarm(item.code)
      );
    }
    setEventData(eventFilteredData);
  }

  function isAlarm(code) {
    if (
      code == utils.WE_STOVE_ALARM ||
      code == utils.WE_STOVE_ALARM_PCU_DISCONNECTED ||
      code == utils.WE_AIR_QUALITY_ALARM ||
      code == utils.WE_PCU_WATER_LEAKAGE_ALARM ||
      code == utils.WE_CTU_FIRE_EXTINGUISH_ALARM ||
      code == utils.WE_AIR_QUALITY_ALARM_CLEARED ||
      code == utils.WE_STOVE_ALARM_CLEARED ||
      code == utils.WE_AIR_QUALITY_ALARM_TEMP_LOW ||
      code == utils.WE_AIR_QUALITY_ALARM_TEMP_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_HUM_LOW ||
      code == utils.WE_AIR_QUALITY_ALARM_HUM_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_PM25_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_ECO2_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_TVOC_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_AQI_HIGH
    ) {
      return true;
    } else {
      return false;
    }
  }

  function isError(code) {
    if (
      code == utils.WE_CRITICAL_ERROR ||
      code == utils.WE_CRITICAL_ERROR_PCU_SHUTDOWN ||
      code == utils.WE_CRITICAL_ERROR_CLEARED
    ) {
      return true;
    } else {
      return false;
    }
  }

  function handleAlarmFilter({ target }) {
    filter.alarm = target.checked;
    setFilter(filter);
    filterEventData();
    countFilters();
  }
  function handleErrorFilter({ target }) {
    filter.error = target.checked;
    setFilter(filter);
    filterEventData();
    countFilters();
  }
  function handleOthersFilter({ target }) {
    filter.others = target.checked;
    setFilter(filter);
    filterEventData();
    countFilters();
  }

  function countFilters() {
    var count = 0;
    for (const key in filter) {
      if (filter[key]) {
        count++;
      }
    }
    setFilterCount(count);
  }

  async function readData(period) {
    const dayLogData = await ReadDayLogs(db, {
      device: device,
      startTime: parseInt(period.start.unix()) + utils.TIME_24H / 1000,
      endTime: parseInt(period.end.unix()) + utils.TIME_24H / 1000
    });
    //console.log(dayLogData);

    var sensorData = [];
    var PCUStatus = false;
    var dayLogChartData = [];
    var calculatedPower = 0;
    for (var sKey in dayLogData) {
      //if (dayLogData[sKey].pcu_status > 0) {
      PCUStatus = true;
      calculatedPower = calculatedPower + dayLogData[sKey].cooking_kwh;
      dayLogChartData.push({
        power: dayLogData[sKey].cooking_kwh,
        battery: parseInt(dayLogData[sKey][utils.BATTERY_NAME]),
        timestamp: dayLogData[sKey].unix_epoch * 1000 - utils.TIME_24H
      });
      //}

      sensorData.push.apply(sensorData, dayLogData[sKey].sensor_data);
    }
    //console.log(dayLogChartData);
    //console.log(calculatedPower);
    setTotalPower(calculatedPower);
    setIsPCU(PCUStatus);
    setData(sensorData.reverse());
    getSupportedCharts(dayLogChartData.reverse(), sensorData.reverse());
  }

  async function readEventData(period) {
    const events = await ReadEvents(db, {
      device: device,
      startTime: period.start.unix(),
      endTime: period.end.unix()
    });

    //console.log(events);
    //await new Promise(resolve => setTimeout(resolve, 500));
    setEventData(events);

    setEventDataStore(JSON.parse(JSON.stringify(events)));
  }

  function getSupportedCharts(pwrData, sensorData) {
    var secondChart = '';
    var series = {};
    var units = {};
    switch (
      device.data.hw_capabilities_id !== undefined
        ? device.data.hw_capabilities_id
        : 0
    ) {
      case utils.SENSE_HW_CAPABILITIES['BT']:
      case utils.SENSE_HW_CAPABILITIES['WIFI']:
        series.battery = 'battery';
        units.serie1 = '%';
        series.power = 'power';
        units.power = 'kWh';
        secondChart = isPCU
          ? getPowerChart(series, units, pwrData) + getTotalPowerContent()
          : getPowerChart({ battery: 'battery' }, { serie1: '%' }, pwrData);
        break;
      case utils.SENSE_HW_CAPABILITIES['PRO']:
      case utils.SENSE_HW_CAPABILITIES['CR_PRO']:
        if (
          device.data.hw_capabilities_id == utils.SENSE_HW_CAPABILITIES['PRO']
        ) {
          series.battery = 'battery';
          units.serie1 = '%';
        }
        series.power = 'power';
        units.power = 'kWh';
        secondChart = (
          <div>
            <Chart
              language={user.language}
              device={device}
              data={sensorData}
              chartHeight={chartHeight}
              id="airquality"
              serieNames={{
                serie1: 'tVOC',
                serie2: 'eCO2',
                serie3: 'PM2.5'
              }}
              serieValues={{
                serie1: utils.TVOC_NAME,
                serie2: utils.ECO2_NAME,
                serie3: utils.PM25_NAME,
                cooking: utils.IS_COOKING_NAME,
                alarm: utils.IS_ALARM_NAME
              }}
              serieUnit={{
                serie1: 'µg/m' + String.fromCharCode(179),
                serie2: 'ppm',
                serie3: 'µg/m' + String.fromCharCode(179)
              }}
            />
            {isPCU
              ? getPowerChart(series, units, pwrData)
              : getPowerChart({ battery: 'battery' }, { serie1: '%' }, pwrData)}
            {isPCU ? getTotalPowerContent() : ''}
          </div>
        );
        break;
      default:
    }
    setSupportedCharts(secondChart);
  }

  function getTotalPowerContent() {
    return (
      <p className="text-caenter" style={{ textAlign: 'center' }}>
        {translation.power_chart_total} {totalPower} kWh
      </p>
    );
  }

  function getPowerChart(series, units, pwrData) {
    var title = translation.power_battery_chart_title_b;
    if (series.power != undefined && series.battery != undefined) {
      title = translation.power_battery_chart_title_a;
    } else if (series.battery != undefined) {
      title = translation.power_battery_chart_title_c;
    }
    return (
      <div>
        <h6 className="pt-2">{title}</h6>
        <PowerChart
          language={user.language}
          device={device}
          data={pwrData}
          chartHeight={chartHeight}
          id="power"
          serieValues={series}
          serieUnit={units}
          translation={translation}
        />
      </div>
    );
  }

  return (
    <div className="container p-0 pb-3">
      <h6>{translation.events}</h6>
      <div className="card shadow">
        <header className="px-3 pb-3 bg-100 rounded-top">
          <div className="row pb-2 pt-1">
            <div className="col d-flex justify-content-end px-2 py-1">
              <button
                className="btn btn-link btn-sm"
                onClick={setFullScreen}
                type="button"
              >
                <FontAwesomeIcon icon={faExpand} className="me-2" />
                {translation.full_screen}
              </button>
            </div>
          </div>
          <DataPeriod
            setDataPeriod={readData}
            readEventData={readEventData}
            language={user.language}
          />
        </header>
        <div className="card-body px-0 py-0 border-bottom overflow-hidden">
          <div className="d-flex flex-column flex-grow-1 tabs-panel">
            <ul
              className="nav nav-tabs d-flex justify-content-center border-0 flex-nowrap border-bottom mx-3"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active py-2 mb-0"
                  role="tab"
                  data-bs-toggle="tab"
                  href="#tab-4"
                >
                  {translation.graphs}
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link py-2 mb-0"
                  role="tab"
                  data-bs-toggle="tab"
                  href="#tab-5"
                >
                  {translation.event_list}
                </a>
              </li>
            </ul>
            <div className="tab-content p-0 bg-white">
              <div className="tab-pane active p-3" role="tabpanel" id="tab-4">
                <h6 className="pt-2">{translation.air_quality}</h6>
                <div className="container p-0 pb-3">
                  <div>
                    <FullScreen
                      handle={handle}
                      enable={isFull}
                      className="bg-white"
                      onChange={isFull => {
                        if (isFull) {
                          setChartHeight('50%');
                        } else {
                          setChartHeight(CHART_DEFAULT_HEIGHT);
                        }
                      }}
                    >
                      <Chart
                        language={user.language}
                        device={device}
                        data={data}
                        chartHeight={chartHeight}
                        id="ambient"
                        serieNames={{
                          serie1: translation.temperature,
                          serie2: translation.humidity
                        }}
                        serieValues={{
                          serie1: utils.TEMP_AMBIENT_NAME,
                          serie2: utils.RH_NAME,
                          cooking: utils.AMBIENT_COOKING_NAME,
                          alarm: utils.AMBIENT_ALARM_NAME
                        }}
                        serieUnit={{
                          serie1: '°C',
                          serie2: '%'
                        }}
                      />
                      {supportedCharts}
                    </FullScreen>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane px-3 pb-3"
                role="tabpanel"
                id="tab-5"
                style={{ minHeight: '250px' }}
              >
                <div className="row px-3">
                  <div className="col-12 col-sm-auto d-flex justify-content-center py-3 px-0 w-100 border-bottom border-3">
                    <div className="dropdown">
                      <button
                        className="btn btn-sm link-primary shadow"
                        aria-expanded="false"
                        data-bs-toggle="dropdown"
                        type="button"
                      >
                        <i className="fas fa-filter me-2"></i>
                        {translation.filter_events}
                        <span className="badge bg-primary ms-2 rounded-pill">
                          {filterCount}
                        </span>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item">
                          <input
                            type="checkbox"
                            className="me-3"
                            defaultChecked={true}
                            onChange={handleAlarmFilter}
                          />
                          {translation.alarms}
                        </a>
                        <a className="dropdown-item">
                          <input
                            type="checkbox"
                            className="me-3"
                            defaultChecked={true}
                            onChange={handleErrorFilter}
                          />
                          {translation.error_messages}
                        </a>
                        <a className="dropdown-item">
                          <input
                            type="checkbox"
                            className="me-3"
                            defaultChecked={true}
                            onChange={handleOthersFilter}
                          />
                          {translation.other}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <EventTable eventData={eventData} language={user.language} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DeviceHistory.propTypes = {
  device: PropTypes.object,
  user: PropTypes.object,
  db: PropTypes.object
};
