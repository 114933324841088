/**
 * Update database document.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default async function Update(writeModel, db) {
  if (writeModel === null || writeModel === undefined) {
    return;
  }
  try {
    if (
      writeModel.path !== undefined &&
      writeModel.doc !== undefined &&
      writeModel.data !== undefined
    ) {
      const usrDevDocRef = db.collection(writeModel.path).doc(writeModel.doc);
      await usrDevDocRef.set(writeModel.data, { merge: true });
      if (writeModel.callback !== undefined) {
        writeModel.callback(true);
      }

      console.log('Done');
    } else {
      if (writeModel.callback !== undefined) {
        writeModel.callback(false);
      } else {
        console.error('ERROR: DB update fail! Data missing.');
      }
    }
  } catch (error) {
    console.error(error);
    if (writeModel.callback !== undefined) {
      writeModel.callback(false);
    }
  }
}
