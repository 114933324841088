import React, { useState, useContext } from 'react';
import Flex from 'components/common/Flex';
import { Card, Row, Col, Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailDetailHeader from './EmailDetailHeader';
import Avatar from 'components/common/Avatar';
//import team1 from 'assets/img/team/1.jpg';
//import { Link } from 'react-router-dom';
//import classNames from 'classnames';
//import shareLogo from 'assets/img/share.jpeg';
//import IconGroup from 'components/common/icon/IconGroup';
//import socialIcons from 'data/socialIcons';
import { useStoreState } from 'easy-peasy';
import useTranslation from 'hooks/translations';
import * as utils from 'utils';
import { AdvancedContext } from 'context/Context';
import { SetMessageIsRead } from 'components/DataBase/Message/SetMessageIsRead';
import MessageButton from './MessageButton';

const EmailDetail = () => {
  const translation = useTranslation();
  const advancedDispatch = useContext(AdvancedContext);

  const params = new URLSearchParams(window.location.search);
  var id;
  if (params.has('msgid')) {
    id = params.get('msgid');
  }
  const messages = useStoreState(state => state.messages);
  var initMessage;
  var index = 0;
  var msgIndex = 0;

  for (const msg of messages) {
    if (msg.id == id) {
      initMessage = msg;
      msgIndex = index;
    }
    index++;
  }
  var initButtonTxt = 'Ok';
  var initButtonColor = 'success';
  var initButtonDisabled = '';
  var initButtonHidden = '';
  var initDetails = '';
  initContent(initMessage);
  const [messageIndex, setMessageIndex] = useState(msgIndex);
  const [message, setMessage] = useState(initMessage);
  const [details, setDetails] = useState(initDetails);
  const [button, setButton] = useState({
    text: initButtonTxt,
    disabled: initButtonDisabled,
    hidden: initButtonHidden,
    color: initButtonColor
  });
  //const [marked, setMarked] = useState(true);
  const [emailDetails, setEmailDetails] = useState({
    sender: {
      name: message.user,
      avatarSrc: message.img,
      time: message.time,
      email: ''
    },
    subject: message.description,
    devices: messages.devices != undefined ? messages.devices : {}
  });

  function markMessageToRead(msg) {
    SetMessageIsRead(advancedDispatch.db, {
      read: true,
      messageId: msg.id,
      uid: advancedDispatch.user.uid
    });
    msg.read = true;
    console.log(msg);
    //updateMessage(msg);
  }

  function initContent(msg) {
    console.log(msg);
    initDetails = getDetails(msg);
    initMessage.message = getHeader(msg);
    switch (msg.type) {
      case utils.MESSAGE_TYPE['share']:
        initButtonTxt = translation.accept;
        initButtonColor = 'success';
        initButtonDisabled = '';
        initButtonHidden = '';

        break;
      case utils.MESSAGE_TYPE['share_done']:
        initButtonTxt = 'Accepted';
        initButtonDisabled = 'disabled';
        initButtonColor = 'dark';
        initButtonHidden = '';

        break;
      case utils.MESSAGE_TYPE['share_group']:
        initButtonTxt = translation.accept;
        initButtonDisabled = '';
        initButtonColor = 'success';
        initButtonHidden = '';

        break;
      case utils.MESSAGE_TYPE['share_group_done']:
        initButtonTxt = 'Accepted';
        initButtonDisabled = 'disabled';
        initButtonColor = 'dark';
        initButtonHidden = '';
        break;
      case utils.MESSAGE_TYPE['transfer_ownership']:
        initButtonTxt = translation.accept;
        initButtonDisabled = '';
        initButtonColor = 'success';
        initButtonHidden = '';
        break;
      case utils.MESSAGE_TYPE['transfer_ownership_done']:
        initButtonTxt = 'Accepted';
        initButtonDisabled = 'disabled';
        initButtonColor = 'dark';
        initButtonHidden = '';
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership']:
        initButtonTxt = translation.accept;
        initButtonDisabled = '';
        initButtonColor = 'success';
        initButtonHidden = '';
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership_done']:
        initButtonTxt = 'Accepted';
        initButtonDisabled = 'disabled';
        initButtonColor = 'dark';
        initButtonHidden = '';
        break;
      case utils.MESSAGE_TYPE['info']:
        initButtonHidden = 'd-none';
        break;
      case utils.MESSAGE_TYPE['new_chat_message']:
        initButtonHidden = 'd-none';
        break;
      default:
        initButtonHidden = 'd-none';
        break;
    }
  }

  function updateContent(msg) {
    setDetails(getDetails(msg));
    msg.message = getHeader(msg);
    setMessage(msg);
    switch (msg.type) {
      case utils.MESSAGE_TYPE['share']:
        setButton({
          text: translation.accept,
          disabled: 'disabled',
          hidden: '',
          color: 'dark'
        });

        break;
      case utils.MESSAGE_TYPE['share_done']:
        setButton({
          text: 'Accepted',
          disabled: 'disabled',
          hidden: '',
          color: 'dark'
        });

        break;
      case utils.MESSAGE_TYPE['share_group']:
        setButton({
          text: translation.accept,
          disabled: '',
          hidden: '',
          color: 'success'
        });
        break;
      case utils.MESSAGE_TYPE['share_group_done']:
        setButton({
          text: 'Accepted',
          disabled: 'disabled',
          hidden: '',
          color: 'dark'
        });
        break;
      case utils.MESSAGE_TYPE['transfer_ownership']:
        setButton({
          text: translation.accept,
          disabled: '',
          hidden: '',
          color: 'success'
        });
        break;
      case utils.MESSAGE_TYPE['transfer_ownership_done']:
        setButton({
          text: 'Accepted',
          disabled: 'disabled',
          hidden: '',
          color: 'dark'
        });
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership']:
        setButton({
          text: translation.accept,
          disabled: '',
          hidden: '',
          color: 'success'
        });
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership_done']:
        setButton({
          text: 'Accepted',
          disabled: 'disabled',
          hidden: '',
          color: 'dark'
        });

        break;
      case utils.MESSAGE_TYPE['info']:
        setButton({
          text: '',
          disabled: 'disabled',
          hidden: 'd-none',
          color: 'dark'
        });
        break;
      case utils.MESSAGE_TYPE['new_chat_message']:
        setButton({
          text: '',
          disabled: '',
          hidden: 'd-none',
          color: 'dark'
        });
        break;
      default:
        setButton({
          text: '',
          disabled: 'disabled',
          hidden: 'd-none',
          color: 'dark'
        });

        break;
    }
  }

  function getHeader(msg) {
    var retVal = '';
    switch (msg.type) {
      case utils.MESSAGE_TYPE['share']:
      case utils.MESSAGE_TYPE['share_done']:
        retVal = (
          <>
            <p>
              {msg.user} {translation.message_share_header}
            </p>
            <br />
            <p>
              {translation.device_name}: {msg.deviceName}
            </p>
            <br />
          </>
        );
        break;

      case utils.MESSAGE_TYPE['share_group']:
      case utils.MESSAGE_TYPE['share_group_done']:
        retVal = (
          <>
            <p>
              {msg.user} {translation.message_share_group_header}
            </p>
            <br />
            <p>
              {translation.group_name}: {msg.groupName}
            </p>
            <br />
          </>
        );
        break;
      case utils.MESSAGE_TYPE['transfer_ownership']:
      case utils.MESSAGE_TYPE['transfer_ownership_done']:
        retVal = (
          <>
            <p>
              {msg.user} {translation.message_ownership_header}
            </p>
            <br />
            <p>
              {translation.device_name}: {msg.deviceName}
            </p>
            <br />
          </>
        );
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership']:
      case utils.MESSAGE_TYPE['transfer_group_ownership_done']:
        retVal = (
          <>
            <p>
              {msg.user} {translation.message_group_ownership_header_a}{' '}
              {translation.message_group_ownership_header_b}
            </p>
            <br />
            <p>
              {translation.group_name}: {msg.groupName}
            </p>
            <br />
          </>
        );
        break;
      default:
        retVal = '';
        break;
    }
    return retVal;
  }

  function getDetails(msg) {
    if (!msg.read) {
      markMessageToRead(msg);
    }
    var retVal = '';
    const devices = msg.devices != undefined ? msg.devices : [];
    console.log(devices);
    switch (msg.type) {
      case utils.MESSAGE_TYPE['share']:
        retVal = <p>{translation.message_share_details}</p>;
        break;
      case utils.MESSAGE_TYPE['share_done']:
        retVal = <p>{translation.message_share_done_details}</p>;
        break;
      case utils.MESSAGE_TYPE['share_group']:
        if (msg.devices != undefined) {
          retVal = (
            <>
              {getDeviceTable(devices)}
              <p>{translation.message_share_group_details}</p>
            </>
          );
        } else {
          retVal = <p>{translation.message_share_group_details}</p>;
        }
        break;
      case utils.MESSAGE_TYPE['share_group_done']:
        if (msg.devices != undefined) {
          retVal = (
            <>
              {getDeviceTable(devices)}
              <p>{translation.message_share_done_details}</p>
            </>
          );
        } else {
          retVal = <p>{translation.message_share_done_details}</p>;
        }
        break;
      case utils.MESSAGE_TYPE['transfer_ownership']:
        retVal = <p>{translation.message_transfer_ownership_details}</p>;
        break;
      case utils.MESSAGE_TYPE['transfer_ownership_done']:
        retVal = <p>{translation.message_transfer_ownership_done_details}</p>;
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership']:
        if (msg.devices != undefined) {
          retVal = (
            <>
              {getDeviceTable(devices)}
              <p>{translation.message_transfer_group_ownership_details}</p>
            </>
          );
        } else {
          retVal = (
            <p>{translation.message_transfer_group_ownership_details}</p>
          );
        }
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership_done']:
        if (msg.devices != undefined) {
          retVal = (
            <>
              {getDeviceTable(devices)}
              <p>{translation.message_transfer_ownership_done_details}</p>
            </>
          );
        } else {
          retVal = <p>{translation.message_transfer_ownership_done_details}</p>;
        }

        break;
      default:
        retVal = '';
        break;
    }
    return retVal;
  }

  function getDeviceTable(devices) {
    return (
      <Table className="table-borderless">
        <thead>
          <tr>
            <th>#</th>
            <th>{translation.device_id}</th>
            <th>{translation.device_name}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(devices).map((key, index) => {
            return (
              <tr key={index}>
                <th>{index + 1}</th>
                <td>{key}</td>
                <td>{devices[key].name}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  function previousMsg() {
    console.log('PREVIOUS', messageIndex);
    if (messageIndex > 0) {
      setEmailDetails({
        sender: {
          name: messages[messageIndex - 1].user,
          avatarSrc: messages[messageIndex - 1].img,
          time: messages[messageIndex - 1].time,
          email: ''
        },
        subject: messages[messageIndex - 1].description,
        devices: messages[messageIndex - 1].devices
      });
      setMessage(messages[messageIndex - 1]);
      updateContent(messages[messageIndex - 1]);
      setMessageIndex(messageIndex - 1);
    }
  }

  function nextMsg() {
    console.log('NEXT', messageIndex);
    if (messageIndex < messages.length - 1) {
      setEmailDetails({
        sender: {
          name: messages[messageIndex + 1].user,
          avatarSrc: messages[messageIndex + 1].img,
          time: messages[messageIndex + 1].time,
          email: ''
        },
        subject: messages[messageIndex + 1].description,
        devices: messages[messageIndex + 1].devices
      });
      setMessage(messages[messageIndex + 1]);
      updateContent(messages[messageIndex + 1]);
      setMessageIndex(messageIndex + 1);
    }
  }

  return (
    <>
      <EmailDetailHeader />
      <Card className="overflow-scroll">
        <Card.Header>
          <Row>
            <Col md as={Flex}>
              <Avatar src={emailDetails.sender.avatarSrc} size="2xl" />
              <div className="flex-1 ms-2">
                <h5 className="mb-0">
                  {message.title} &ndash; {emailDetails.subject}
                </h5>
                {/* <a
                  className="text-800 fs--1"
                  href={`mailto:${emailDetails.sender.email}`}
                > */}
                <span className="fw-semi-bold">{emailDetails.sender.name}</span>
                {/* <span className="ms-1 text-500">
                    &lt;{emailDetails.sender.email}&gt;
                  </span> */}
                {/* </a> */}
              </div>
            </Col>
            <Col
              md="auto"
              as={Flex}
              alignItems="center"
              className="ps-6 ps-md-3"
            >
              <small>{emailDetails.sender.time}</small>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="justify-content-center">
            <Col lg={8} xxl={6}>
              <Card className="shadow-none mb-3">
                {/* <img src={shareLogo} alt="" className="card-img-top" /> */}
                <Card.Body>
                  <h3 className="fw-semi-bold">{message.message}</h3>
                  {details}
                  <p></p>
                  <div className="text-center">
                    <MessageButton button={button} message={message} />
                    <small className="d-block">
                      For any technical issues faced, please contact{' '}
                      <a href={translation.support_home_page_url}>
                        Customer Support
                      </a>
                      .
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row className="justify-content-between">
            <Col></Col>
            <Col xs="auto" as={Flex} alignItems="center">
              <small>
                {messageIndex + 1} of {index + 1}
              </small>

              <IconButton
                variant="falcon-default"
                size="sm"
                icon="chevron-left"
                className="ms-2"
                onClick={() => previousMsg()}
              />

              <IconButton
                variant="falcon-default"
                size="sm"
                icon="chevron-right"
                className="ms-2"
                onClick={() => nextMsg()}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default EmailDetail;
