/**
 * Move Group.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import * as utils from '../../../utils';

export default async function MoveGroup(
  db,
  groupId,
  isGuest,
  uid,
  parentGroupId
) {
  try {
    //console.log(db, groupId);

    if (isGuest) {
      const guestGrbRef = db
        .collection(utils.USERS_KEY + '/' + uid + '/' + utils.GROUP_KEY)
        .doc(groupId);
      await guestGrbRef.set({ parent: parentGroupId }, { merge: true });
    } else {
      const grbRef = db.collection(utils.GROUP_KEY).doc(groupId);
      await grbRef.set({ parent: parentGroupId }, { merge: true });
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
