/**
 * Event Code.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default function ErrorCode(errorCode, translation) {
  if (errorCode === undefined || translation === undefined) {
    return [];
  }
  var errors = getMiuErrors(errorCode & 0xffff);

  function getMiuErrors(errorCode) {
    var errors = [];
    if ((errorCode & 1) === 1) {
      errors.push(translation.miu_error_exc);
    }
    if ((errorCode & 2) === 2) {
      errors.push(translation.miu_error_tof);
    }
    if ((errorCode & 4) === 4) {
      errors.push(translation.miu_error_adc);
    }
    if ((errorCode & 8) === 8) {
      errors.push(translation.miu_error_801);
    }
    if ((errorCode & 16) === 16) {
      errors.push(translation.miu_error_zmod);
    }
    if ((errorCode & 32) === 32) {
      errors.push(translation.miu_error_lpc);
    }
    if ((errorCode & 64) === 64) {
      errors.push(translation.miu_error_mma);
    }
    if ((errorCode & 128) === 128) {
      errors.push(translation.miu_error_hum);
    }
    if ((errorCode & 256) === 256) {
      errors.push(translation.miu_error_tilt);
    }
    if ((errorCode & 512) === 512) {
      errors.push(translation.miu_error_bat_low);
    }
    if ((errorCode & 1024) === 1024) {
      errors.push(translation.miu_error_pcu_radio);
    }
    if ((errorCode & 2048) === 2048) {
      errors.push(translation.miu_error_mcu);
    }
    if ((errorCode & 4096) === 4096) {
      errors.push(translation.miu_error_lens_dirty);
    }
    return errors;
  }

  function getPcuErrors(errorCode) {
    var errors = [];
    if ((errorCode & 1) === 1) {
      errors.push(translation.pe_voltmeas);
    }
    if ((errorCode & 2) === 2) {
      errors.push(translation.pe_curmeas);
    }
    if ((errorCode & 4) === 4) {
      errors.push(translation.pe_watermeas);
    }
    if ((errorCode & 8) === 8) {
      errors.push(translation.pe_mcu);
    }
    if ((errorCode & 16) === 16) {
      errors.push(translation.pe_tempwd);
    }
    if ((errorCode & 32) === 32) {
      errors.push(translation.pe_psu);
    }
    if ((errorCode & 64) === 64) {
      errors.push(translation.pe_relay);
    }
    if ((errorCode & 128) === 128) {
      errors.push(translation.pe_overheat);
    }
    if ((errorCode & 256) === 256) {
      errors.push(translation.pe_curmeas_diff);
    }
    if ((errorCode & 512) === 512) {
      errors.push(translation.pe_curmeas_backup);
    }
    if ((errorCode & 1024) === 1024) {
      errors.push(translation.pe_overcur);
    }
    if ((errorCode & 2048) === 2048) {
      errors.push(translation.pe_mma_z_jammed);
    }

    return errors;
  }

  return errors.concat(getPcuErrors(errorCode >> 16));
}
