/**
 * Text validator.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default function TextSanitizing(text, lineBreak) {
  if (text === undefined || text === null) {
    return '';
  }
  var retVal = text.replace(/<\/?[^>]+(>|$)/g, '');
  if (lineBreak) {
    // convert line breaks to html <br>
    retVal = retVal.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
  return retVal;
}
