/**
 * Phonenumber.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStoreActions } from 'easy-peasy';
import ToastMessage from '../ToastMessage';
import * as utils from '../../../utils';
import writeModel from '../../../components/DataBase/DataBaseWriteModel';
import TextSanitizing from '../../../components/TextSanitizing';
import Modal from 'react-bootstrap/Modal';
import useTranslation from '../../../hooks/translations';

export default function DisplayName({ user }) {
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const [toastContent, setToastContent] = useState({
    message: '',
    success: true
  });

  const { userUpdate, dbUpdate } = useStoreActions(actions => ({
    userUpdate: actions.userUpdate,
    dbUpdate: actions.dbUpdate
  }));
  const [warningText, setWarningText] = useState('');
  const [showModal, setModalShow] = useState(false);
  //console.log(user);
  const handleModalShow = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);
  const nameRef = useRef();
  var displayName = user.displayName;

  const withoutName = (
    <div className="flex-shrink-1 flex-grow-1 w-100">
      <h4 className="text-break">{translation.name}</h4>
      <div className="text-truncate w-100">
        <span className="w-100">
          <a className="text-break" onClick={handleModalShow}>
            {translation.add_name}
          </a>
        </span>
      </div>
      <hr className="bg-400 mb-0" />
    </div>
  );
  const withName = (
    <div className="flex-shrink-1 flex-grow-1 w-100">
      <div className="w-100">
        <div className="container p-0">
          <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
            <div className="col d-flex flex-grow-1">
              <h4 className="text-break">{translation.name}</h4>
            </div>
            <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
              <a className="text-break" onClick={handleModalShow}>
                {translation.change}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="text-truncate mb-2">
        <span className="text-break">{displayName}</span>
      </div>
      <hr className="bg-400 mb-0" />
    </div>
  );
  const state = { content: withoutName };

  if (user.displayName === undefined || user.displayName === '') {
    state.content = withoutName;
  } else {
    state.content = withName;
  }

  function updateName() {
    const newName = TextSanitizing(nameRef.current.value, false);
    if (newName !== nameRef.current.value) {
      setWarningText(
        <>
          <p className="text-warning mt-2 mb-0 fs--1">
            {translation.please_use_only_letters_and_numbers}
          </p>
          <p className="text-warning mb-0 fs--1">
            {translation.characters_that_are_not_allowed_are_removed}
          </p>
        </>
      );
      nameRef.current.value = newName;
    } else {
      user.displayName = newName;
      userUpdate(user);
      state.content = withName;
      setToastContent({ message: translation.changes_saved, success: true });
      saveNameToDataBase(newName, user.uid);
      setModalShow(false);
    }
  }

  function showToast(isSaved) {
    console.log(isSaved);
    if (!isSaved) {
      setToastContent({ message: translation.saving_failed, success: false });
    }
    setShow(isSaved);
  }

  async function saveNameToDataBase(name, uid) {
    try {
      console.log(uid);

      writeModel.path = utils.USERS_KEY;
      writeModel.doc = uid;
      writeModel.data = {
        username: name !== undefined ? name : ''
      };
      writeModel.callback = showToast;
      dbUpdate(writeModel);
    } catch (error) {
      console.error(error);
      setToastContent({ message: translation.saving_failed, success: false });
      setShow(true);
    }
  }

  return (
    <>
      {state.content}
      <Modal
        show={showModal}
        role="dialog"
        tabIndex="-1"
        id="modal-display-name"
      >
        <div className="modal-header pe-4 border-0">
          <h4 className="modal-title text-break pe-5">
            {translation.save_name}
            <br />
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <label className="form-label">{translation.name}</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            defaultValue={displayName}
            ref={nameRef}
          />
          {warningText}
        </div>
        <div className="modal-footer border-0 pt-0 pb-4">
          <div className="w-100">
            <button
              className="btn btn-primary w-100 shadow"
              type="button"
              onClick={() => updateName()}
            >
              {translation.save}
            </button>
          </div>
        </div>
      </Modal>
      <ToastMessage
        setShow={setShow}
        success={toastContent.success}
        show={show}
        message={toastContent.message}
      />
    </>
  );
}

DisplayName.propTypes = {
  setShow: PropTypes.func,
  user: PropTypes.object
};
