/**
 * Mattery or grease icon.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React from 'react';
import PropTypes from 'prop-types';
import Battery from './Battery';
import * as utils from '../../../utils';

export default function BattryOrGeaseFilterIcon({ device, divClass }) {
  if (divClass === undefined) {
    divClass = '';
  }
  if (device.data === undefined) {
    device.data = {};
  }
  var value = (
    <>
      {device.data.battery_level !== undefined
        ? device.data.battery_level + ' %'
        : ''}
    </>
  );
  var battryOrGeaseFilter = '';

  // 1=Sense BT, 67=Sense Wi-Fi, 127=Sense Pro, 255=Sense CR Pro
  switch (
    device.data.hw_capabilities_id !== undefined
      ? device.data.hw_capabilities_id
      : 0
  ) {
    case utils.SENSE_HW_CAPABILITIES['BT']:
      battryOrGeaseFilter = (
        <Battery batteryPercentage={device.data.battery_level} />
      );
      break;
    case utils.SENSE_HW_CAPABILITIES['WIFI']:
      battryOrGeaseFilter = (
        <Battery batteryPercentage={device.data.battery_level} />
      );
      break;
    case utils.SENSE_HW_CAPABILITIES['PRO']:
      battryOrGeaseFilter = (
        <Battery batteryPercentage={device.data.battery_level} />
      );
      break;
    case utils.SENSE_HW_CAPABILITIES['CR_PRO']:
      battryOrGeaseFilter = ''; //<i className="icon-grease-filter"></i>;
      value = '';
      break;
    default:
      battryOrGeaseFilter = (
        <Battery batteryPercentage={device.data.battery_level} />
      );
  }

  return (
    <>
      <div className="row row-cols-2">
        <div
          className={`col-auto d-flex justify-content-center align-items-center ${divClass} p-0 lh-base`}
        >
          {battryOrGeaseFilter}
        </div>
        <div className="col-11 text-truncate text-black p-0 ps-2">
          <p className="m-0 lh-base fs--1">{value}</p>
        </div>
      </div>
    </>
  );
}

BattryOrGeaseFilterIcon.propTypes = {
  device: PropTypes.object,
  divClass: PropTypes.string
};
