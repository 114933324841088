/**
 * Language English.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import safera_fr from './safera_fr';

export default {
  ...safera_fr,
  ...{
    m001_verification_button: 'Confirmez votre adresse électronique',
    m001_verification_modal_title: 'Vérification de l’e-mail',
    m001_verification_modal_content_p1a: 'Un message de vérification',
    m001_verification_modal_content_p1b:
      'vient d’être envoyé à votre adresse électronique.',
    m001_verification_modal_content_p2:
      'Suivez les instructions dans l’e-mail pour vérifier votre adresse.',
    m002_name_change_content_p1b: '.',
    m003_number_change_heading: 'Modification du compte utilisateur',
    m004_new_device_subject_a: 'Le dispositif',
    m004_new_device_heading:
      'Un nouveau dispositif a été ajouté à votre compte',
    m004_new_device_content_bullet1:
      'Alarmes à distance en cas d’incident, pour que vous puissiez réagir rapidement.',
    m004_new_device_content_bullet2:
      'Notifications d’un éventuel besoin de maintenance du dispositif, pour vous assurer qu’il est toujours en état de marche.',
    m004_new_device_content_bullet3:
      'Affichage des données d’historique enregistrées par le dispositif, pour suivre notamment l’historique des alarmes.',
    m004_new_device_content_p2:
      'Vous pouvez vérifier ou modifier les paramètres d’alarme et de notification à distance du dispositif ici :',
    m004_new_device_button: 'Ouvrez les paramètres du dispositif',
    m005_share_device_subject_a: 'Confirmez le dispositif',
    m005_share_device_heading:
      'Confirmez le transfert ou le partage du dispositif sur votre compte',
    m005_share_device_p1b: 'veut transférer ou partager le dispositif',
    m005_share_device_p1c: 'sur votre compte utilisateur.',
    m006_add_device_reminder_heading:
      'Connectez votre produit Safera à votre compte utilisateur Safera Cloud pour bénéficier des avantages suivants',
    m006_add_device_reminder_p1:
      'L’ajout d’un dispositif ne prend que quelques minutes :',
    m006_add_device_reminder_button: 'Ajoutez un dispositif à votre compte',
    m007_thanks_for_order_subject:
      'Merci de vous être abonné (essai gratuit en cours) – Alarmes par SMS',
    m007_thanks_for_order_heading:
      'Merci de vous être abonné au service SMS – essai gratuit en cours',
    m007_thanks_for_order_p1a:
      'L’essai gratuit des alarmes SMS est en cours et se termine le',
    m007_thanks_for_order_p1b: '.',
    m007_thanks_for_order_p2a:
      'Après la période d’essai, le service se poursuivra automatiquement sous forme d’abonnement à durée indéterminée et une redevance mensuelle sera facturée',
    m007_thanks_for_order_p2b:
      '. Vous pouvez résilier le service à tout moment, après quoi il sera à votre disposition jusqu’à la fin de la période de facturation en cours. La redevance mensuelle comprend jusqu’à 30 SMS par mois sans restriction de dispositif.',
    m007_thanks_for_order_p3:
      'Vous pouvez afficher et modifier les paramètres d’alarme de votre dispositif ici.',
    m007_thanks_for_order_service_heading: 'Service souscrit :',
    m007_thanks_for_order_service:
      'Alarmes SMS 1 mois, abonnement valable jusqu’à nouvel ordre',
    m007_thanks_for_order_cost_heading: 'Redevance mensuelle pour le service :',
    m007_price: '1,99 €/mois',
    m007_thanks_for_order_number_heading: 'Numéro d’abonnement :',
    m007_thanks_for_order_date_heading: 'Date de l’abonnement :',
    m007_thanks_for_order_how_to_cancel_heading:
      'Résiliation de l’abonnement :',
    m007_thanks_for_order_terms_heading: 'Conditions du service :',
    m007_thanks_for_order_support_heading: 'Assistance produit :',
    m007_sms_thanks_for_order_p1b:
      'a activé les alarmes SMS pour les incidents liés à la cuisinière sur ce numéro depuis le dispositif',
    m007_sms_thanks_for_order_p1c: '.',
    m007_sms_thanks_for_order_p2: 'En savoir plus :',
    m008_trial_ending_subject:
      'Donnez votre avis : votre essai gratuit des alarmes SMS sera prolongé d’un mois',
    m008_trial_ending_heading:
      'L’essai gratuit des alarmes SMS touche à sa fin - Rédigez un avis sur le produit pour le prolonger d’un mois',
    m008_trial_ending_p1a:
      'L’essai gratuit des alarmes SMS se termine dans 7 jours',
    m008_trial_ending_p1b: '.',
    m008_trial_ending_p2:
      'Les dispositifs suivants envoient des alarmes à distance par SMS',
    m008_trial_ending_device_list: 'au numéro :',
    m008_trial_ending_p3:
      'Nous espérons que le service et le produit vous ont donné satisfaction. Nous apprécions tout commentaire et espérons que vous partagerez votre expérience avec nous et les autres clients.',
    m008_trial_ending_p4a:
      'Rédigez un avis sur le produit et nous prolongerons votre essai gratuit des alarmes SMS',
    m008_trial_ending_p4b: 'jusqu’au :',
    m008_trial_ending_button:
      'Rédigez un avis sur le produit - votre essai gratuit sera prolongé d’un mois',
    m009_trial_ending_subcription_continues_subject:
      'L’essai gratuit des alarmes SMS touche à sa fin - L’abonnement continue automatiquement',
    m009_trial_ending_subcription_continues_p3:
      'L’essai gratuit des alarmes SMS se termine le [date].',
    m009_trial_ending_subcription_continues_p4a:
      'Après la période d’essai, le service se poursuivra automatiquement sous forme d’abonnement à durée indéterminée et une redevance mensuelle sera facturée',
    m009_trial_ending_subcription_continues_p4b:
      'selon le mode de paiement de votre choix.',
    m009_sms_trial_ending_subscription_continues_p1a:
      'Safera Cloud - L’essai gratuit des alarmes SMS se termine le',
    m009_sms_trial_ending_subscription_continues_p1b:
      ', après quoi l’abonnement se poursuivra automatiquement jusqu’à nouvel ordre et une redevance mensuelle sera facturée',
    m009_sms_trial_ending_subscription_continues_p1c: '.',
    m009_sms_trial_ending_subscription_continues_p2: 'Gérez votre abonnement :',
    m010_receipt_heading: 'Merci pour votre paiement',
    m010_receipt_p1: 'Nous avons reçu votre paiement pour le service :',
    m010_receipt_sum: 'Montant :',
    m010_receipt_payment_date: 'Date de paiement :',
    m010_receipt_p2a:
      'Les alarmes SMS sont à nouveau disponibles jusqu’à la fin de la période de facturation',
    m010_receipt_p2b:
      ', après quoi la période suivante sera facturée automatiquement.',
    m010_receipt_payment_info_heading: 'Détails de paiement',
    m010_receipt_payment_info_content:
      'Vous pouvez consulter l’historique de vos paiements ou modifier votre mode de paiement dans votre compte sous « Détails de paiement ».',
    m011_sms_service_ending_subject: 'Abonnement résilié - Fin des alarmes SMS',
    m011_sms_service_ending_heading: 'Les alarmes SMS prennent fin le',
    m011_sms_service_ending_p1a:
      'L’abonnement aux alarmes SMS a été résilié. Les alarmes SMS ne seront plus disponibles après la fin de la période de facturation en cours',
    m011_sms_service_ending_p1b: '.',
    m011_sms_service_ending_button: 'Abonnez-vous au service SMS pour',
    m011_sms_p1b: '.',
    m012_account_delete_heading:
      'La demande de suppression de votre compte Safera Cloud est en cours',
    m012_account_delete_p2:
      'Vous pouvez revenir dans le service Safera Cloud à tout moment en ouvrant un nouveau compte utilisateur. Une fois la suppression du compte précédent terminée, vous pourrez créer un nouveau compte avec la même adresse e-mail.',
    m013_personal_information_heading:
      'Vos données personnelles stockées dans Safera Cloud sont ci-jointes',
    m013_personal_information_p2:
      'Le mot de passe permettant d’ouvrir le fichier PDF vous sera envoyé séparément, soit par courriel, soit par SMS sur votre numéro de portable si vous l’avez enregistré dans notre service.',
    m014_payment_failed_1_subject:
      'Mettez à jour votre mode de paiement – L’abonnement aux alarmes SMS n’a pas pu être débité',
    m014_payment_failed_1_heading: 'Échec du paiement',
    m014_payment_failed_1_p1b: 'Alarmes SMS 1,99 €/mois',
    m014_payment_failed_device_list:
      'Abonnement aux alarmes SMS pour les dispositifs :',
    m014_sms_failed_1_p2:
      'Connectez-vous et sélectionnez dans votre compte « Détails de paiement » :',
    m015_payment_failed_2_subject:
      'Mettez à jour votre mode de paiement - L’abonnement aux alarmes SMS prendra fin le [date]',
    m015_payment_failed_2_heading:
      'Échec du paiement - Mettez à jour vos détails de paiement pour continuer le service',
    m015_payment_failed_2_p2a:
      'Si le mode de paiement n’est pas mis à jour, les alarmes SMS prendront fin le',
    m015_payment_failed_2_p2b: 'pour les dispositifs :',
    m015_sms_payment_failed_2_p1b:
      'sauf si le mode de paiement est mis à jour.',
    m015_sms_payment_failed_2_p2:
      'Connectez-vous et sélectionnez dans votre compte « Détails de paiement » :',
    m016_payment_ok_heading:
      'Échec du paiement – L’abonnement aux alarmes SMS a été résilié',
    m016_payment_ok_p1:
      'Les alarmes SMS pour les dispositifs suivants ont pris fin faute de pouvoir débiter la redevance pour ces alarmes :',
    m016_payment_ok_button: 'Abonnez-vous au service SMS pour 1,99 €/mois',
    m017_payment_info_updated_heading:
      'Les détails de paiement ont été mises à jour',
    m017_sms_payment_info_updated_p1:
      'L’abonnement aux alarmes SMS Safera Cloud sur ce numéro a été renouvelé.',
    m018_replace_batteries_1_subject: 'Remplacez les piles -',
    m018_replace_batteries_1_heading:
      'Remplacez les piles - Elles seront bientôt épuisées',
    m018_replace_batteries_1_p1b:
      'le niveau de charge des piles est faible. Remplacez les piles dès que possible.',
    m018_replace_batteries_1_button:
      'Consultez les instructions : comment remplacer les piles',
    m018_replace_batteries_1_p2:
      'Si les piles sont complètement déchargées, le dispositif vous empêchera d’utiliser la cuisinière pour des raisons de sécurité.',
    m018_replace_batteries_1_bullets_1:
      'Le dispositif n’envoie pas d’alarmes à distance.',
    messages_footer_v3:
      'Si vous ne souhaitez pas recevoir ces notifications, modifiez les paramètres de notification dans les paramètres du dispositif.',
    m019_replace_batteries_2_heading:
      'Remplacez les piles – Le dispositif ne peut pas se connecter au réseau Wi-Fi',
    m019_replace_batteries_2_p1a: 'Le niveau de charge des piles',
    m019_replace_batteries_2_p1b:
      'du dispositif est faible ou elles sont épuisées, ce qui empêche la connexion au réseau Wi-Fi. Remplacez les piles dès que possible.',
    m019_sms_replace_batteries_p1a: 'Alarmes SMS depuis le dispositif',
    m019_sms_replace_batteries_p2: 'Consultez les instructions :',
    m020_wifi_disconnected_subject: 'Comment dépanner la connexion Wi-Fi -',
    m020_wifi_disconnected_p1a: 'Reconnectez le dispositif',
    m020_wifi_disconnected_p1b: 'au réseau Wi-Fi dès que possible.',
    m020_wifi_disconnected_button:
      'Consultez les instructions : Connectez le dispositif au réseau Wi-Fi',
    m020_wifi_disconnected_p3:
      'Le dispositif a été connecté au Wi-Fi en dernier lieu :',
    m020_wifi_disconnected_p4:
      'Si vous avez besoin d’une assistance supplémentaire, contactez notre service d’assistance produit :',
    m021_wifi_ok_subject: 'Connexion Wi-Fi restaurée -',
    m021_wifi_ok_p1:
      'Si vous avez des problèmes récurrents avec votre connexion Wi-Fi, consultez les instructions ici :',
    m021_wifi_ok_button:
      'Consultez les instructions : Dépannage de la connexion Wi-Fi',
    m021_sms: 'M019_SMS_replace_batteries_p2',
    m022_malfunction_subject: 'Réparez le dysfonctionnement -',
    m022_malfunction_heading: 'Réparez le dysfonctionnement',
    m022_malfunction_p1a: 'Un dysfonctionnement',
    m022_malfunction_p1b: 'a été détecté dans le dispositif :',
    m022_malfunction_button: 'Consultez les instructions : dépannage',
    m022_malfunction_p2:
      'Si vous avez besoin d’une assistance supplémentaire, contactez notre service d’assistance produit et indiquez-nous ce code d’erreur pour un service plus rapide :',
    m022_sms_malfunction_p1b:
      'sont hors service car un dysfonctionnement a été détecté.',
    m023_cooking_alarm_subject:
      'Alarme causée par un incident lié à la cuisinière -',
    m023_cooking_alarm_heading:
      'Alarme causée par un incident lié à la cuisinière',
    m023_cooking_alarm_p1b:
      'a détecté un incident lié à la cuisinière et a mis celle-ci hors tension :',
    m023_cooking_alarm_p2:
      'Pour remettre la cuisinière sous tension, appuyez sur la touche OK du dispositif.',
    m023_sms_cooking_alarm_p2:
      'La cuisinière a été mise hors tension. Pour remettre la cuisinière sous tension, appuyez sur la touche OK du dispositif.',
    m024_cooking_alarm_cleared_subject:
      'Effacé - Alarme causée par un incident lié à la cuisinière -',
    m024_cooking_alarm_cleared_heading: 'L’alarme a été effacée',
    m024_cooking_alarm_cleared_p1a: 'L’alarme envoyée par le dispositif',
    m024_cooking_alarm_cleared_p1b:
      'concernant un incident lié à la cuisinière a été effacée :',
    m024_sms_cooking_alarm_cleared_p1:
      'Effacé : Alarme causée par un incident lié à la cuisinière',
    m025_water_leak_alarm_subject: 'Alarme de fuite d’eau -',
    m025_water_leak_alarm_heading: 'Alarme de fuite d’eau',
    m025_water_leak_alarm_p1b: 'a détecté une fuite d’eau :',
    m025_water_leak_alarm_p2:
      'Vérifiez la cause de l’alarme et prenez les mesures de suivi nécessaires pour éviter tout dommage.',
    m025_water_leak_alarm_button:
      'Consulter les instructions : effacer une alarme',
    m026_water_leak_cleared_subject: 'Effacé - Alarme de fuite d’eau -',
    m026_water_leak_cleared_p1a: 'L’alarme',
    m026_water_leak_cleared_p1b:
      'de fuite d’eau envoyée par le dispositif a été effacée :',
    m027_aq_alarm_subject:
      'Dépassement du seuil d’alarme de la qualité de l’air -',
    m027_aq_alarm_heading:
      'Dépassement du seuil d’alarme de la qualité de l’air',
    m027_aq_alarm_p1b:
      'a détecté un dépassement du seuil d’alarme de la qualité de l’air :',
    m027_aq_alarm_button:
      'Affichez l’historique de la qualité de l’air du dispositif',
    m027_aq_alarm_p4: 'Quelles sont les causes d’un mauvais air intérieur ?',
    m027_aq_alarm_p5:
      'Découvrez les causes les plus courantes de la mauvaise qualité de l’air dans votre maison :',
    m027_aq_alarm_button2:
      'Les causes les plus courantes de la mauvaise qualité de l’air dans votre maison',
    m028_cooking_alarm_no_pcu_p1b:
      'a détecté un incident lié à la cuisinière :',
    m028_cooking_alarm_no_pcu_p2:
      'Vérifiez la cause de l’incident et prenez les mesures nécessaires pour l’arrêter. Pour effacer l’alarme, appuyez sur la touche OK du dispositif.',
    i001_cooking_remote_alarm_p1:
      'Vous pouvez activer une alarme à distance en cas d’incident lié à la cuisinière.',
    i001_cooking_remote_alarm_p2:
      'Lorsqu’il détecte un incident lié à la cuisinière, le dispositif envoie une alarme à distance et émet une alarme sonore. Le dispositif envoie également un message lorsque l’utilisateur a effacé l’alarme.',
    i002_battery_replacement:
      'Vous pouvez activer une alarme à distance en cas de faible charge des piles.',
    i004_air_quality:
      'Vous pouvez activer les alarmes à distance pour la qualité de l’air ambiant.',
    m029_air_quality_ok_again_subject:
      'Qualité de l’air revenue au niveau cible -',
    m029_air_quality_ok_again_heading:
      'La qualité de l’air est revenue au niveau cible',
    m029_air_quality_ok_again_p1b:
      'a détecté que la qualité de l’air est repassée sous le seuil d’alarme et est revenue au niveau cible :',
    m029_air_quality_ok_again_p2a:
      'Vous pouvez consulter l’historique de la qualité de l’air enregistré par le dispositif',
    m029_air_quality_ok_again_p2b: 'dans le service :',
    m029_air_quality_ok_again_p3a:
      'Vous pouvez contrôler les seuils d’alarme de la qualité de l’air en vous connectant au dispositif',
    m029_air_quality_ok_again_p3b: 'via l’appli mobile.',
    m030_malfunction_fixed_subject:
      'Dysfonctionnement corrigé, dispositif en état de marche -',
    m030_malfunction_fixed_heading:
      'Le dispositif est à nouveau en état de marche et le dysfonctionnement a été corrigé',
    m030_malfunction_fixed_p1a: 'Le dysfonctionnement',
    m030_malfunction_fixed_p1b:
      'détecté a été corrigé et le dispositif est à nouveau en état de marche.',
    m030_malfunction_multiple_errors: 'Dysfonctionnements multiples',
    m030_sms_p1a: 'Les alarmes SMS du dispositif',
    m030_sms_p1b:
      'sont à nouveau activées et le dispositif est à nouveau en état de marche.',
    device_status_ok_heading: 'Le dispositif est en état de marche',
    device_status_ok_p1a: 'Le dispositif',
    device_status_footer:
      'Le dispositif envoie les données enregistrées pendant la journée au service cloud à la fin de journée. Les alarmes à distance importantes et les messages sur le besoin de maintenance sont toutefois envoyés immédiatement.',
    saving_failed: 'Échec de l’enregistrement !',
    subscribe_to_sms_alarm_service: 'Abonnez-vous aux alarmes SMS',
    sms_alarms_service_payment_info_1:
      'Les alarmes SMS sont un service optionnel payant. Le service se poursuivra automatiquement sous forme d’abonnement à durée indéterminée et une redevance mensuelle sera facturée',
    sms_alarms_service_payment_info_2: '',
    per_month: '/mois',
    sms_service_terminate_info:
      'Vous pouvez résilier le service à tout moment. La redevance mensuelle comprend jusqu’à 30 SMS par mois sans restriction de dispositif.',
    i_agree_to_the_terms_and_policy:
      'J’accepte les conditions d’utilisation et la politique de confidentialité',
    sms_service_try_30_days_for_free:
      'Abonnez-vous aux alarmes SMS - essai gratuit pendant 30 jours',
    sms_service_try_30_days_for_free_info_1:
      'Les alarmes SMS sont un service optionnel payant que vous pouvez essayer gratuitement pendant 30 jours.',
    sms_service_try_30_days_for_free_info_2:
      'Après la période d’essai, le service se poursuivra automatiquement sous forme d’abonnement à durée indéterminée et une redevance mensuelle sera facturée',
    sms_service_try_30_days_for_free_info_3:
      'Vous pouvez résilier le service à tout moment.',
    sms_alarms_is_an_additional_service_1:
      'Les alarmes SMS sont un service optionnel pour lequel une redevance mensuelle [1,99 €/mois] est facturée.',
    sms_alarms_is_an_additional_service_2:
      'Vous pouvez résilier ce service optionnel à tout moment. Sélectionnez les alarmes souhaitées dans les paramètres d’alarme à distance des dispositifs.',
    are_you_sure_cancel_sms_subscription:
      'Êtes-vous sûr(e) de vouloir résilier l’abonnement aux alarmes SMS ?',
    are_you_sure_cancel_sms_subscription_info:
      'Si vous résiliez l’abonnement, les alarmes SMS ne seront plus disponibles après la fin de la période de facturation en cours',
    i_keep_my_subscription: 'Je poursuis mon abonnement',
    cancel_my_subscription: 'Je résilie mon abonnement',
    sms_alarms_are_now_enabled: 'Les alarmes SMS sont désormais activées',
    following_devices_send_remote_alarms_via_sms:
      'Les dispositifs connectés à votre compte enverront des alarmes à distance sur les incidents liés à la cuisinière par SMS au numéro :',
    device_alarm_settings_info_1:
      'Vous pouvez gérer les alarmes SMS pour chaque dispositif en ouvrant leur fiche sur la page d’accueil.',
    device_alarm_settings_info_2: '',
    ok: 'OK',
    delete_user_account_be_sure:
      'Êtes-vous sûr(e) de vouloir supprimer votre compte utilisateur ?',
    delete_user_account_info:
      'Votre compte utilisateur sera supprimé dans la semaine suivant l’envoi de la demande. Après la suppression, vous ne pourrez plus vous connecter à votre compte, tous les services de votre compte seront désactivés et les données associées à votre compte seront supprimées conformément à notre déclaration de confidentialité [lien].',
    delete_user_account_info_2:
      'Une fois le compte utilisateur supprimé, il ne peut plus être restauré',
    delete_user_account_info_3_a:
      'Vous pourrez créer un nouveau compte utilisateur avec la même adresse e-mail après avoir reçu un message de confirmation de notre part concernant la suppression du compte.',
    delete_user_account_info_3_b: '',
    cancel: 'Annuler',
    delete_user_account: 'Supprimer mon compte utilisateur',
    no_active_subscriptions: 'Aucun abonnement actif',
    subscription_is_active: 'Abonnement en cours de validité',
    add_on_sms_alarms: 'Service optionnel : alarmes SMS : SMS',
    payment_method: 'Modes de paiement',
    transactions: 'Transactions',
    sms_alarms_service_1_month: 'Service optionnel : Alarmes SMS 1 mois',
    signing_in_to_paypal: 'Modifier en se connectant à Paypal',
    print_receipt: 'Imprimer le reçu',
    email_receipt: 'Envoyer le reçu par e-mail',
    privacy_policy: 'Déclaration de confidentialité',
    cookie_policy: 'Politique en matière de cookies',
    necessary_cookies: 'Cookies nécessaires',
    necessary_cookies_info:
      'Les cookies nécessaires sont requis pour le bon fonctionnement du site web. Ils ne stockent pas de données personnelles.',
    performance_cookies: 'Cookies de performance',
    performance_cookies_info:
      'Les cookies de performance servent à collecter des informations sur l’utilisation du site, telles que le nombre et la durée des visites, et sont utilisés pour améliorer le site et son contenu.',
    remote_alarm_settings: 'Paramètres des alarmes à distance',
    history_view: 'Vue de l’historique',
    device_information: 'Informations sur le dispositif',
    rename_the_device: 'Renommer le dispositif',
    enter_new_name: 'Entrer le nouveau nom',
    remove_the_device: 'Supprimer le dispositif de mon compte',
    recipient_of_remote_alarms:
      'Destinataire des alarmes à distance du dispositif',
    device_settings: 'Paramètres du dispositif',
    my_account: 'Mes coordonnées',
    device_remote_alarm_settings:
      'Paramètres d’alarme à distance du dispositif',
    remote_alarm_cooking_hazard:
      'Alarme à distance causée par un incident lié à la cuisinière',
    email: 'Courriel',
    sms: 'SMS',
    sms_alarms_enabled_in_part_a: 'Alarmes activées :',
    sms_alarms_enabled_in_part_b: 'sur le dispositif',
    additional_alarms: 'Alarmes optionnelles',
    alarm_air_quality_exceeds:
      'Alarme à distance si la qualité de l’air ambiant dépasse le seuil d’alarme',
    alarm_air_quality_info_1:
      'Le dispositif mesure en permanence la qualité de l’air ambiant (selon le modèle : température ambiante, humidité, PM2.5, COVt et CO2e). Vous pouvez définir les limites inférieures et supérieures pour l’alarme de qualité de l’air',
    alarm_air_quality_info_2:
      'dans l’onglet « Qualité de l’air ». Vous pouvez activer les alarmes à distance par e-mail ci-dessus.',
    remote_alarm_for_battery_replacement:
      'Alarme à distance lorsque les piles doivent être remplacées',
    remote_alarm_for_wifi_and_offline:
      'Alarme à distance si le dispositif de sécurité pour cuisinière ne peut pas se connecter au réseau Wi-Fi et au service Cloud',
    remote_alarm_for_device_errors:
      'Alarme à distance sur le besoin de maintenance du dispositif',
    more_information: 'Plus d’informations',
    time_period: 'Période',
    full_screen: 'Plein écran',
    graphs: 'Graphiques',
    air_quality: 'Qualité de l’air',
    cooking_event: 'Événement d’utilisation de la cuisinière',
    alarm: 'Alarme',
    temperature: 'Température',
    humidity: 'Humidité de l’air',
    event_list: 'Liste des événements',
    filter_events: 'Filtrer les événements',
    alarms: 'Alarmes',
    error_messages: 'Messages d’erreur',
    other: 'Autre',
    date: 'Date',
    event: 'Événement',
    installation_settings: 'Paramètres d’installation',
    stove_type: 'Type de cuisinière',
    stove_width: 'Largeur de la cuisinière',
    mounting_position: 'Méthode d’installation',
    pcu: 'Unité de contrôle de la puissance (PCU)',
    installation_height: 'Hauteur d’installation',
    stove_wiring: 'Raccordement de la cuisinière',
    installation_settings_info_1:
      'Vous pouvez modifier les paramètres d’installation de l’appareil',
    installation_settings_info_2:
      'en sélectionnant « Assistant d’installation » dans le menu.',
    information: 'Informations',
    device: 'Dispositif',
    device_id: 'Identifiant du dispositif (ID)',
    fw_version: 'Version du logiciel (FW)',
    hw_version: 'Version du matériel (HW)',
    mechanical_variant: 'Variation mécanique',
    hw_id_minor: 'Sous-identifiant du dispositif (HW ID)',
    mechanics_version: 'Version mécanique',
    device_status: 'Statut du dispositif',
    notification: 'Notification :',
    welcome: 'Bienvenue !',
    log_in_or_sign_up: 'Connectez vous ou créez un nouveau compte',
    continue_with_google: 'Utilisez votre compte Google',
    continue_with_email: 'Utilisez votre adresse e-mail',
    continue_with_facebook: 'Utilisez votre compte Facebook',
    agree_terms_and_policy:
      'En utilisant le service, vous acceptez nos conditions d’utilisation et notre déclaration de confidentialité.',
    confirm_the_removal_of_the_device: 'Confirmer la suppression du dispositif',
    remove_device_be_sure:
      'Êtes-vous sûr(e) de vouloir supprimer le dispositif :',
    remove_device_info_1:
      'Si vous supprimez le dispositif, vous ne pourrez plus consulter son historique et il n’enverra plus d’alarmes à distance. Une fois un dispositif supprimé, ses données ne peuvent plus être restaurées.',
    remove_device_info_2:
      'Après la suppression, vous pourrez le connecter à votre compte utilisateur comme un nouveau dispositif',
    cancel_device_remove: 'Annuler la suppression du dispositif',
    remove_device: 'Supprimer le dispositif',
    device_removed: 'Dispositif supprimé',
    sms_alarms_enabled_info_1:
      'Choisissez cette option si vous souhaitez activer les alarmes SMS uniquement pour le dispositif',
    sms_alarms_enabled_info_2:
      'Vous pourrez également gérer les alarmes à distance pour chaque appareil plus tard dans leurs paramètres.',
    cancel_payment_1:
      'Êtes-vous sûr(e) de vouloir résilier l’abonnement aux alarmes SMS ?',
    cancel_payment_2:
      'Si vous résiliez l’abonnement, les alarmes SMS ne seront plus disponibles après la fin de la période de facturation en cours.',
    cancel_payment_3: 'Poursuivre l’abonnement',
    cancel_payment_4: 'Résilier l’abonnement',
    phone_number_missing: 'Numéro de téléphone manquant !',
    order_id: 'Numéro d’abonnement',
    order_date: 'Date de l’abonnement',
    order_type: 'Type d’abonnement',
    order_status: 'Statut de l’abonnement',
    order_plan_id: 'Identifiant du plan d’abonnement',
    subscriber: 'Abonné',
    amount: 'Montant',
    status: 'Statut',
    wizard_cloud_welcome_text:
      'Vous enregistrez votre appareil dans Safera Cloud. Vous pouvez maintenant accéder à la page de connexion.',
    wizard_cloud_log_in_step: 'Étape 1/3',
    wizard_cloud_logged_in_heading:
      'Vous êtes connecté(e) au compte en tant que',
    wizard_cloud_next_button: 'Suivant',
    wizard_cloud_previous_button: 'Retour à l’étape précédente',
    wizard_cloud_logged_log_out_button: 'Utiliser un autre compte utilisateur',
    wizard_cloud_new_account_heading: 'Votre nouveau compte utilisateur',
    wizard_cloud_connect_device_step: 'Étape 2/3',
    wizard_cloud_connect_device_input_label: 'Nom du dispositif',
    wizard_cloud_connect_device_input_placeholder:
      'Donnez un nom au dispositif',
    wizard_cloud_remote_alarms_step: 'Étape 3/3',
    wizard_cloud_remote_alarms_heading:
      'Configurez les alarmes à distance du dispositif pour terminer',
    wizard_cloud_remote_alarms_p2:
      'Vous pouvez également activer les alarmes SMS pour les incidents liées à la cuisinière.',
    wizard_cloud_remote_alarms_button:
      'Ouvrez les paramètres d’alarme à distance du dispositif',
    wizard_cloud_device_settings_button: 'Terminé',
    wizard_cloud_ready_heading: 'Terminé !',
    wizard_cloud_ready_p1:
      'Les alarmes à distance sont maintenant activées pour le dispositif',
    wizard_cloud_connect_device_failed_heading:
      'Échec de la connexion du dispositif',
    wizard_cloud_connect_device_failed1_p1:
      'Le dispositif ne peut être connecté qu’à un seul compte utilisateur à la fois. Ce dispositif est déjà connecté à un autre compte utilisateur. Consultez les instructions ici :',
    wizard_cloud_connect_device_failed_button: 'Retour en arrière',
    wizard_cloud_connect_device_failed2_p1:
      'Le dispositif a déjà été connecté à votre compte utilisateur auparavant. Connectez-vous à Safera Cloud pour vérifier le statut et les paramètres du dispositif.',
    wizard_cloud_connect_device_failed3_p1:
      'Le dispositif ne peut pas être connecté au service. Consultez les instructions ici :',
    wizard_cloud_connect_device_failed4_p1:
      'Échec de la connexion du dispositif. Veuillez réessayer.',
    wizard_cloud_connect_device_failed4_button: 'Essayez à nouveau',
    wizard_cloud_connect_device_failed5_p1:
      'Échec de la connexion du dispositif car il n’est pas connecté à Internet. Vérifiez les paramètres Wi-Fi du dispositif. Consultez les instructions ici :',
    wizard_cloud_connect_device_heading2:
      'Connexion du dispositif à votre compte...',
    list_previous: 'Précédent',
    list_rows: 'Afficher :',
    list_to: '-',
    list_of: '/',
    accept: 'j’accepte',
    skip: 'Ignorer',
    change: 'Changer',
    activate_subscription: 'Activer l’abonnement',
    in_progress: 'En attente de confirmation',
    add_phonenumber: 'Ajouter un numéro de téléphone',
    add_name: 'Ajouter un nom',
    save_name: 'Enregistrer le nom',
    events: 'Événements',
    n_a: 'Non disponible',
    stove_alarm: 'Alarme cuisinière !',
    stove_alarm_pcu: 'Alarme cuisinière, mise hors tension de la cuisinière',
    cooking_action_start: 'Début de la cuisson',
    cooking_action_stop: 'Fin de la cuisson',
    ok_button: 'Touche OK appuyée',
    button_1: 'Touche 1 appuyée',
    button_2: 'Touche 2 appuyée',
    factory_reset: 'Restauration des paramètres d’usine',
    cloud_connection: 'Test de connexion au cloud effectué',
    stove_unused: 'Cuisinière inutilisée depuis trop longtemps',
    cooked_unusual_time: 'Cuisson à une heure inhabituelle',
    low_battery: 'Pile déchargée',
    critical_error: 'Erreur critique',
    water_leakage: 'Le capteur de fuite d’eau a détecté de l’humidité',
    water_alarm_over: 'L’alarme de fuite d’eau est terminée.',
    fire_extinguishing: 'Extinction liquide de l’incendie effectuée',
    factory_connection_test: 'Test de connexion en usine',
    manual_connection_test: 'Test de connexion depuis l’appli Safera',
    air_quality_cleared:
      'La qualité de l’air est revenue à un niveau acceptable',
    power_shut_down:
      'Mise hors tension de la cuisinière en raison d’une erreur critique',
    critical_error_cleared: 'Le problème causé par cette erreur a été corrigé',
    low_temperature: 'Température de l’air basse',
    high_temperature: 'Température de l’air élevée',
    low_humidity: 'Humidité de l’air basse',
    high_humidity: 'Humidité de l’air élevée',
    pm25_high: 'PM2.5 élevées',
    air_quality_alarm: 'Alarme de qualité de l’air',
    high_eco2: 'CO2e élevé',
    high_tvoc: 'COVt élevés',
    high_aqi: 'Indice combiné de qualité de l’air élevé',
    day_log_received: 'Journal reçu',
    stove_alarm_cleared: 'L’alarme de la cuisinière est terminée',
    device_reboot: 'Le dispositif a été redémarré.',
    stove: 'Cuisinière',
    oven: 'Four',
    ceramic: 'Céramique',
    induction: 'Induction',
    iron: 'Fonte',
    iron_coil: 'Bobine en fonte',
    gas: 'Gaz',
    unknown: 'Inconnu',
    not_installed: 'Non installé',
    installed: 'Installé',
    wall: 'Mur',
    hood: 'Hotte',
    yesterday: 'Hier',
    last_7_days: '7 derniers jours',
    last_30_days: '30 derniers jours',
    this_month: 'Ce mois-ci',
    previous_month: 'Mois précédent',
    apply: 'Appliquer',
    custom_range: 'Spécifier l’heure',
    miu_error_exc: 'Erreur du capteur de température',
    miu_error_tof: 'Erreur du capteur de distance',
    miu_error_adc: 'Erreur du capteur analogique',
    miu_error_801: 'Erreur du capteur de gaz 1',
    miu_error_zmod: 'Erreur du capteur de gaz 2',
    miu_error_lpc: 'Erreur du capteur de particules',
    miu_error_mma: 'Erreur du capteur d’orientation',
    miu_error_hum: 'Erreur du capteur d’humidité',
    miu_error_tilt: 'Orientation incorrecte du capteur',
    miu_error_bat_low: 'Batterie du capteur très faible',
    miu_error_pcu_radio:
      'Erreur de communication radio dans l’unité de contrôle de la puissance',
    miu_error_mcu: 'Erreur du processeur principal du capteur',
    miu_error_lens_dirty: 'Lentilles du capteur encrassées',
    pe_voltmeas: 'Défaut de mesure de la tension CA',
    pe_curmeas: 'Défaut de mesure du courant',
    pe_watermeas: 'Défaut de mesure LDS',
    pe_mcu: 'Problème électronique/MCU',
    pe_tempwd: 'Défaut dans la puce du moniteur de température',
    pe_psu:
      'Défaut d’alimentation PCU (tension insuffisante ou ondulation excessive)',
    pe_relay:
      'Le PCU 3.5.16-3.5.17 signale ceci après environ 10 opérations de relais',
    pe_overheat:
      'Le moniteur de température s’est déclenché et le PCU a surchauffé, la cuisinière a été éteinte',
    pe_curmeas_diff:
      'Les méthodes de calcul du courant principal et de secours donnent des résultats différents',
    pe_curmeas_backup: 'Méthode de calcul du débit de secours utilisée',
    pe_overcur: 'Déconnexion par surintensité déclenchée',
    pe_mma_z_jammed:
      'Axe Z de l’accéléromètre du MMA bloqué, tentative de sortir de la sauvegarde de sécurité.',
    contact_information: 'Coordonnées',
    plans_and_subscriptions: 'Services et abonnements',
    billing: 'Détails de paiement',
    privacy: 'Protection des données',
    sign_out: 'Se déconnecter',
    back: 'Retour',
    user_account: 'Compte utilisateur',
    name: 'Nom',
    mobile_phone: 'Numéro de téléphone',
    email_address: 'Courriel',
    password: 'Mot de passe',
    language: 'Langue',
    edit: 'Modifier',
    enter_users_name: 'Entrez un nom d’utilisateur',
    eg_first_name_last_name: 'Par exemple Prénom Nom de famille',
    save: 'Enregistrer',
    enter_users_phone_number: 'Entrez le numéro de téléphone de l’utilisateur',
    mobile_format: 'Au format +33 0 00 00 00 00',
    please_enter_the_mobile_number_in_format:
      'Veuillez renseigner le numéro de téléphone au format :',
    enter_your_mobile_number: 'Enregistrez votre numéro de téléphone portable',
    mobile_number: 'Numéro de téléphone portable',
    choose_language: 'Choisissez la langue',
    change_your_account_email:
      'Modifiez l’adresse e-mail de votre compte utilisateur',
    new_email_address: 'Nouvelle adresse e-mail',
    please_use_only_letters_and_numbers:
      'Veuillez saisir uniquement des lettres et des chiffres dans le nom.',
    characters_that_are_not_allowed_are_removed:
      'Les caractères non autorisés ont été supprimés.',
    service_plan: 'Forfait',
    free: 'Gratuit',
    free_plan_info_1: 'Le forfait gratuit',
    free_plan_info_2:
      'comprend les alarmes à distance par e-mail et une vue historique des dispositifs permettant de consulter les données enregistrées par ceux-ci.',
    add_ons: 'Services optionnels',
    sms_alarms: 'Alarmes à distance par SMS',
    disabled: 'Non activé',
    enabled: 'Activé',
    try_30_days_for_free_1: 'Essai de 30 jours',
    try_30_days_for_free_2: 'gratuit',
    sms_service_info_1:
      'Les alarmes SMS sont un service optionnel que vous pouvez essayer gratuitement pendant 30 jours. Après la période d’essai, une redevance mensuelle sera facturée',
    sms_service_info_2: '',
    sms_service_info_3:
      'Vous pouvez résilier le service à tout moment. Le service comprend jusqu’à 30 SMS par mois sans restriction de dispositif.',
    start_30_day_free_trial: 'Commencer l’essai gratuit',
    cancel_free_trial: 'Mettre fin à l’essai',
    free_trial_ends_in_1: 'L’essai gratuit se termine dans :',
    free_trial_ends_in_2: 'jours',
    cancel_the_subscription: 'Résilier l’abonnement au service SMS',
    sms_alarms_info_1:
      'Les alarmes SMS sont un service optionnel pour lequel une redevance mensuelle est facturée',
    terms_of_service: 'Conditions d’utilisation du service',
    open: 'Ouvrir',
    changes_saved: 'Changements enregistrés',
    power_battery_chart_title_a: 'Consommation d’énergie et pile',
    power_battery_chart_title_b: 'Consommation d’énergie',
    power_battery_chart_title_c: 'Pile',
    power_chart_total: 'Consommation totale :',
    cancel_smartthing_link_remove: 'Ne pas dissocier SmartThings',
    remove_smartthings_link: 'Dissocier SmartThings',
    smartthings_add_device_info_a:
      'After linking, you must add the device to the SmartThings App.',
    smartthings_add_device_info_b: 'Add a new device from the SmartThings App.',
    smartthings_add_device_info_d:
      'Now you can add the device with the SmartThings App.',
    smartthings_add_device_button: 'Create a link to the SmartThings',
    smartthings_remove_device_info:
      'After unlinking, you must also remove the device from the SmartThings App.',
    smartthings_add_dropdown: 'Add device link to SmartThings',
    device_name: 'Nom du dispositif',
    group_name: 'Group name',
    message_share_details:
      'After accepting the invitation, you can see the device information and device data.',
    message_share_done_details: 'You have already accepted the invitation.',
    message_share_group_details:
      "After accepting the invitation, you get read access to the group's device information and device data.",
    message_transfer_ownership_details:
      'After accepting the invitation, you become the new owner of the device.',
    message_transfer_ownership_done_details:
      'You have already accepted the ownership.',
    message_transfer_group_ownership_details:
      'After accepting the invitation, you become the owner of all the devices in the group.',
    message_share_header: 'wants to share the device with you.',
    message_share_group_header: 'wants to share the group with you.',
    message_ownership_header: 'wants to give you ownership of the device.',
    message_group_ownership_header_a:
      'wants to give you ownership of the group. ',
    message_group_ownership_header_b:
      'All devices in the group will be transferred to your ownership.',
    message_share_title: 'Device share',
    message_group_title: 'Group share',
    message_ownership_title: 'Transfer of ownership',
    email_verification_button: 'Envoyer email de vérification',
    email_verification_message_a:
      'Your email address has not been verified. Please verify your email address first.',
    email_verification_message_b1:
      'You have now been sent a verification email to ',
    email_verification_message_b2:
      'Check your email. When the verification is complete, press the "Verification done" button.',
    email_verification_done_button: 'Vérification effectuée'
  }
};
