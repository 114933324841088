/**
 * Device type.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';

export default function DeviceType({ id }) {
  var type = id;
  switch (id) {
    case 1:
      type = 'Sense BT';
      break;
    case 67:
      type = 'Sense Wi-Fi';
      break;
    case 127:
      type = 'Sense Pro';
      break;
    case 255:
      type = 'Sense CR Pro';
      break;
    default:
      type = 'Sense';
  }
  return <>{type}</>;
}

DeviceType.propTypes = {
  id: PropTypes.number
};
