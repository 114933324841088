/**
 * Collect device sensor data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';

export default function collect10minSensorV6Data(deviceDocData) {
  //console.log('V6');
  function sensorDataGetTimemapArr(val) {
    const timemap = (val >> 4).toString(2);
    return timemap.split('');
  }
  var sensorDataArray = [];
  for (var key in deviceDocData.data) {
    const slots = deviceDocData.data[key].measured_slot_count;
    const human_presence_timemapArr = sensorDataGetTimemapArr(
      deviceDocData.data[key].human_presence_count_and_timemap
    );
    const cooking_timemapArr = sensorDataGetTimemapArr(
      deviceDocData.data[key].cooking_count_and_timemap
    );
    const other_appliance_timemapArr = sensorDataGetTimemapArr(
      deviceDocData.data[key].other_appliance_count_and_timemap
    );
    const alarm_timemapArr = sensorDataGetTimemapArr(
      deviceDocData.data[key].alarm_count_and_timemap
    );
    const poweroff_timemapArr = sensorDataGetTimemapArr(
      deviceDocData.data[key].poweroff_count_and_timemap
    );

    //console.log(deviceDocData);

    var time = deviceDocData.data[key].unix_epoch - slots * 600;
    for (let index = 0; index < slots; index++) {
      //console.log(time);
      var sensorObj = {};
      sensorObj[utils.SENSOR_TIMESTAMP_NAME] =
        (time - deviceDocData.timezone_sec) * 1000;
      sensorObj[utils.IS_COOKING_NAME] =
        cooking_timemapArr[index] != undefined
          ? parseInt(cooking_timemapArr[index])
          : 0;
      sensorObj[utils.IS_ALARM_NAME] =
        alarm_timemapArr[index] != undefined
          ? parseInt(alarm_timemapArr[index])
          : 0;
      sensorObj[utils.IS_POWEROFF_NAME] =
        poweroff_timemapArr[index] != undefined
          ? parseInt(poweroff_timemapArr[index])
          : 0;
      sensorObj[utils.IS_HUMAN_PRESENCE] =
        human_presence_timemapArr[index] != undefined
          ? parseInt(human_presence_timemapArr[index])
          : 0;
      sensorObj[utils.IS_OTHER_APPLIANCE] =
        other_appliance_timemapArr[index] != undefined
          ? parseInt(other_appliance_timemapArr[index])
          : 0;

      sensorObj[utils.TEMP_AMBIENT_NAME] =
        deviceDocData.data[key].temp_abient.data[index] / 100 - 50;
      sensorObj[utils.TEMP_IR_NAME] =
        deviceDocData.data[key][utils.TEMP_IR_NAME].data[index] / 100 - 50;
      sensorObj[utils.RH_NAME] =
        deviceDocData.data[key][utils.RH_NAME].data[index] / 2;
      sensorObj[utils.ECO2_NAME] =
        deviceDocData.data[key][utils.ECO2_NAME].data[index];
      sensorObj[utils.TVOC_NAME] =
        deviceDocData.data[key][utils.TVOC_NAME].data[index];
      sensorObj[utils.PM1_NAME] =
        deviceDocData.data[key][utils.PM1_NAME].data[index] / 5;
      sensorObj[utils.PM25_NAME] =
        deviceDocData.data[key][utils.PM25_NAME].data[index] / 5;
      sensorObj[utils.PM10_NAME] =
        deviceDocData.data[key][utils.PM10_NAME].data[index] / 5;
      sensorObj[utils.PCU_POWER_NAME] =
        deviceDocData.data[key][utils.PCU_POWER_NAME].data[index] * 20;
      sensorObj[utils.LIGHT_NAME] =
        deviceDocData.data[key][utils.LIGHT_NAME].data[index];
      sensorObj[utils.SOUND_AVG_NAME] =
        deviceDocData.data[key][utils.SOUND_AVG_NAME].data[index] / 2 + 30;
      sensorObj[utils.SOUND_DETECTION_NAME] =
        deviceDocData.data[key][utils.SOUND_DETECTION_NAME].data[index];
      sensorObj[utils.AREA_ACTIVITY_NAME] =
        deviceDocData.data[key][utils.AREA_ACTIVITY_NAME].data[index];
      sensorObj[utils.VENT_CONTROL_NAME] =
        deviceDocData.data[key][utils.VENT_CONTROL_NAME].data[index];
      sensorObj[utils.MOTOR1_SPEED_NAME] =
        deviceDocData.data[key][utils.MOTOR1_SPEED_NAME].data[index] / 2.55;
      sensorObj[utils.MOTOR2_SPEED_NAME] =
        deviceDocData.data[key][utils.MOTOR2_SPEED_NAME].data[index] / 2.55;
      sensorObj[utils.MOTOR1_POWER_NAME] =
        deviceDocData.data[key][utils.MOTOR1_POWER_NAME].data[index] * 10;
      sensorObj[utils.MOTOR2_POWER_NAME] =
        deviceDocData.data[key][utils.MOTOR2_POWER_NAME].data[index] * 10;
      sensorObj[utils.VALVE_STATE_NAME] =
        deviceDocData.data[key][utils.VALVE_STATE_NAME].data[index];
      sensorObj[utils.AUX_LOG_NAME] =
        deviceDocData.data[key][utils.AUX_LOG_NAME].data[index];
      sensorDataArray.push(sensorObj);
      time = time + 600;
    }
  }
  sensorDataArray.sort((a, b) =>
    a.sensor_timestamp < b.sensor_timestamp ? 1 : -1
  );
  //console.log(sensorDataArray);
  return sensorDataArray;
}
