/**
 * Installation info.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'hooks/translations';
import ReadLatestDayLog from 'data/SensorData/ReadLatestDaylog';
//import { useStoreState } from 'easy-peasy';
import GetStoveTypeName from 'data/SensorData/GetStoveTypeName';
import * as utils from 'utils';

export default function InstallationInfo({ db, device, user }) {
  const translation = useTranslation();
  //const db = useStoreState(state => state.db);
  //const user = useStoreState(state => state.user);
  //const [daylog, setDaylog] = useState({});
  const [info, setInfo] = useState({
    stoveType: translation.n_a,
    installationHeight: translation.n_a,
    installationPosition: translation.n_a,
    pcuInstalled: translation.n_a
  });
  //const daylog = ReadLatestDayLog(db, device.device_id, user.uid);

  async function readDaylog() {
    try {
      const log = await ReadLatestDayLog(db, device.device_id, user.uid);
      //console.log(log);
      const typeText = GetStoveTypeName(
        log != undefined ? log.miu_stovetype : utils.STOVE_TYPE['RT_UNKNOWN'],
        translation
      );
      const pcuStatus =
        parseInt(log != undefined ? log.pcu_status : 0) === 0
          ? translation.not_installed
          : translation.installed;
      const position =
        parseInt(log != undefined ? log.miu_inst_pos : 0) === 0
          ? translation.wall
          : translation.hood;
      setInfo({
        stoveType: typeText !== undefined ? typeText : translation.n_a,
        installationHeight:
          log !== undefined ? log.miu_inst_height_cm + ' cm' : translation.n_a,
        installationPosition: position,
        pcuInstalled: pcuStatus
      });
      //console.log(log);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    readDaylog();
  }, []);
  return (
    <>
      <h6>{translation.installation_settings}</h6>
      <div className="container p-0 pb-3">
        <div className="card shadow">
          <div className="card-body px-3 py-3 pb-2">
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">{translation.stove_type}</h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{info.stoveType}</p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">{translation.pcu}</h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{info.pcuInstalled}</p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">
                    {translation.mounting_position}
                  </h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{info.installationPosition}</p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">
                    {translation.installation_height}
                  </h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{info.installationHeight}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        <p className="fs--1 text-muted">
          {translation.installation_settings_info_1} {translation.apps_name}
        </p>
      </div>
    </>
  );
}

InstallationInfo.propTypes = {
  device: PropTypes.object,
  user: PropTypes.object,
  db: PropTypes.object
};
