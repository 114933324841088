/**
 * PayPal subscription note.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import * as utils from '../../../utils';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import useTranslation from 'hooks/translations';

export default function PayPalSubscriptionNote({ sub }) {
  //console.log(sub);
  const translation = useTranslation();
  const devices = useStoreState(state => state.devices);
  var isSubscriptionActive = false;
  var subscriptionNote = (
    <h5 className="text-success">
      {translation.try_30_days_for_free_1}&nbsp;
      <strong>{translation.try_30_days_for_free_2}</strong>
      <br />
    </h5>
  );

  try {
    const TRIAL_DAYS = 30;
    if (sub != undefined) {
      if (sub.status == utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
        isSubscriptionActive = true;
        const now = new Date().getTime();
        const subscriptionCreated = new Date(sub.create_time).getTime();
        var start = moment(now);
        var end = moment(subscriptionCreated);
        const diffDays = start.diff(end, 'days');
        if (diffDays <= TRIAL_DAYS) {
          subscriptionNote = (
            <>
              <h5 className="text-success">
                {translation.free_trial_ends_in_1} {TRIAL_DAYS - diffDays}{' '}
                {translation.free_trial_ends_in_2}
                <br />
                <p className="mb-1 fs--1 text-black">
                  {translation.sms_alarms_enabled_in_part_a}{' '}
                  {numOfActiveDevices()}{' '}
                  {translation.sms_alarms_enabled_in_part_b}
                </p>
              </h5>
            </>
          );
        } else {
          subscriptionNote = (
            <p className="mb-1 fs--1">
              {translation.sms_alarms_enabled_in_part_a} {numOfActiveDevices()}{' '}
              {translation.sms_alarms_enabled_in_part_b}
            </p>
          );
        }
      } else {
        if (!isSubscriptionActive) {
          subscriptionNote = '';
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  function numOfActiveDevices() {
    var count = 0;
    if (devices === undefined) {
      return count;
    }
    for (const device of devices) {
      if (device.isAlarm_sms_notify_enabled === undefined) {
        count++;
      } else if (device.isAlarm_sms_notify_enabled) {
        count++;
      }
    }
    return count;
  }

  return subscriptionNote;
}

PayPalSubscriptionNote.propTypes = {
  sub: PropTypes.object
};
