/**
 * Contact information.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStoreActions } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-bootstrap/Toast';
import Phonenumber from './Phonenumber';
import DisplayName from './DisplayName';
import useTranslation from '../../../hooks/translations';
import writeModel from '../../../components/DataBase/DataBaseWriteModel';
import * as utils from '../../../utils';

export default function ContactInfo({
  user,
  changeLanguage,
  setUser,
  updateCloudPackage
}) {
  const { userUpdate, dbUpdate } = useStoreActions(actions => ({
    userUpdate: actions.userUpdate,
    dbUpdate: actions.dbUpdate
  }));
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const languageRef = useRef();
  function handleChange() {
    console.log(languageRef.current);
    user.language =
      languageRef.current.value !== undefined
        ? languageRef.current.value
        : 'en';
    userUpdate(user);
    changeLanguage(languageRef.current.value);
    writeModel.path = utils.USERS_KEY;
    writeModel.doc = user.uid;
    writeModel.data = {
      language:
        languageRef.current.value !== undefined
          ? languageRef.current.value
          : 'en'
    };
    dbUpdate(writeModel);
  }
  return (
    <>
      <div className="container p-0 pb-3">
        <div className="card shadow">
          <div className="card-body px-3 py-3 pb-3">
            <div className="d-flex mb-3 align-items-center w-100">
              <DisplayName user={user} />
            </div>
            <div className="d-flex mb-3 align-items-center w-100">
              <Phonenumber
                user={user}
                setUser={setUser}
                updateCloudPackage={updateCloudPackage}
              />
            </div>
            <div className="d-flex mb-3 align-items-center w-100">
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <h4 className="text-break">{translation.email}</h4>
                <div className="text-truncate w-100">
                  <span className="text-break">{user.email}</span>
                </div>
                <hr className="bg-400 mb-0" />
              </div>
            </div>
            <div className="d-flex mb-3 align-items-center w-100">
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <div className="w-100">
                  <div className="container p-0">
                    <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                      <div className="col d-flex flex-grow-1">
                        <h4 className="text-break">{translation.password}</h4>
                      </div>
                      <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                        <a className="text-break" href="#">
                          {translation.change}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="bg-400 mb-0 mt-2" />
              </div>
            </div>
            <div className="d-flex align-items-center w-100">
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <div className="w-100">
                  <h4 className="text-break">{translation.language}</h4>
                </div>
                <div className="text-truncate mb-2">
                  <select
                    className="form-select"
                    onChange={handleChange}
                    defaultValue={user.language}
                    ref={languageRef}
                  >
                    <optgroup label="Valitse kieli">
                      <option value="en">English</option>
                      <option value="fi">Suomi</option>
                      <option value="fr">France</option>
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" role="dialog" tabIndex="-1" id="modal-email">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header pe-4 border-0">
              <h4 className="modal-title pe-5">
                {translation.change_your_account_email}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label className="form-label">
                {translation.new_email_address}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="sahkoposti@osoite.fi"
              />
            </div>
            <div className="modal-footer border-0 pt-0 pb-4">
              <div className="w-100">
                <button
                  className="btn btn-primary w-100 shadow"
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={() => setShow(true)}
                >
                  {translation.save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toast
        onClose={() => setShow(false)}
        delay={3000}
        autohide
        show={show}
        className="bg-dark position-fixed bottom-0 p-3 mb-4 shadow-lg text-light start-50 translate-middle-x"
      >
        <Toast.Header className="text-break bg-dark text-light fs-0">
          <strong className="me-auto">{translation.changes_saved}</strong>
          <FontAwesomeIcon icon={faCheck} className="text-success" />
        </Toast.Header>
      </Toast>
    </>
  );
}

ContactInfo.propTypes = {
  user: PropTypes.object,
  changeLanguage: PropTypes.func,
  setUser: PropTypes.func,
  updateCloudPackage: PropTypes.func
};
