/**
 * Read device daylog data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';
import CheckAccess from 'components/DataBase/CheckAccess';

export default async function ReadLatestDayLogDoc(db, deviceId, uid) {
  try {
    //console.log(db, deviceId, uid);
    const access = await CheckAccess(db, deviceId, uid);
    var daylog;
    //console.log(access);
    if (access.isValid) {
      const sensorDataRef = db
        .collection(utils.DEVICE_KEY + '/' + deviceId + '/' + utils.DAYLOG_KEY)
        .where(utils.UNIX_EPOCH_NAME, '<=', new Date().getTime() / 1000)
        .orderBy(utils.UNIX_EPOCH_NAME, 'desc')
        .limit(1);
      const sensorDoc = await sensorDataRef.get();
      for (const doc of sensorDoc.docs) {
        // console.log(doc.id);
        // console.log('Today: ', doc.data()[utils.UNIX_EPOCH_NAME]);
        daylog = doc;
      }
    } else {
      console.error('Access denied!');
    }
    return daylog;
  } catch (error) {
    console.error(error);
    return daylog;
  }
}
