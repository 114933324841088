/**
 * Collect device sensor data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import Collect30minSensorData from './DayLogV4/Collect30minSensorData';
import collect10minSensorV6Data from './DayLogV6/collect10minSensorV6Data';
import * as utils from 'utils';

export default function CollectSensorData(deviceDocData) {
  var sensorDataArray = [];
  if (deviceDocData == undefined) {
    return sensorDataArray;
  }
  var recordVersion =
    deviceDocData[utils.RECORD_VERSION_NAME] != undefined
      ? deviceDocData[utils.RECORD_VERSION_NAME]
      : 0;
  switch (recordVersion) {
    case 4:
      sensorDataArray = Collect30minSensorData(deviceDocData);
      break;
    case 6:
      sensorDataArray = collect10minSensorV6Data(deviceDocData);
      break;
    default:
      break;
  }
  //console.log(sensorDataArray);
  return sensorDataArray;
}
