/**
 * Safera brand translations (EN).
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default {
  m001_verification_subject:
    'Confirm the email address of your Safera Cloud user account',
  m001_verification_heading: 'Welcome to Safera Cloud',
  m001_verification_content_p1: 'Your new Safera Cloud account is ready.',
  m002_name_change_content_p1a:
    'The Safera Cloud user account name has been changed. The new name is ',
  m002_name_change_subject:
    'The Safera Cloud user account name has been changed',
  messages_footer_v1:
    'Did you receive this message without creating an account? See instructions on the Safera product support website.',
  messages_footer_v2:
    "If you received this message even though you haven't made any changes to your user account, please see the instructions on the Safera product support website.",
  m003_number_change_subject:
    'The phone number for the Safera Cloud user account has been changed',
  m003_number_change_content_p1a:
    'The phone number for the Safera Cloud user account has been changed. The new number is ',
  m003_number_change_content_p1b:
    'The phone number for the Safera Cloud user account has been removed. ',
  m004_new_device_subject_b: 'has been added to your Safera Cloud user account',
  m004_new_device_content_p1b:
    'has now been added to your Safera Cloud user account, so you have the following benefits:',
  m005_share_device_subject_b: 'to your Safera Cloud user account',
  m005_share_device_p1a: 'The Safera Cloud user ',
  m005_share_device_p2:
    'To accept or decline the request, log in to Safera Cloud:',
  m005_share_device_button: 'Log in to Safera Cloud',
  m006_add_device_reminder_subject:
    'Take advantage of Safera Cloud - Connect your Safera product to your user account',
  m007_thanks_for_order_how_to_cancel_content:
    'You may terminate the service at any time by logging in to Safera Cloud and selecting account settings from your user account.',
  m007_thanks_for_order_support_site:
    'Product support website: support.safera.com',
  m007_thanks_for_order_support_contact: 'Contact: support@safera.com',
  m007_sms_thanks_for_order_p1a: 'The user of the Safera Cloud service ',
  m009_trial_ending_subcription_continues_button:
    'Write a review of your Safera product',
  m010_receipt_subject:
    'Your receipt from Safera Cloud - Additional service for SMS alarms 1 month',
  m011_sms_service_ending_p2:
    'To re-enable remote alarms via SMS, subscribe to SMS alarms on Safera Cloud:',
  m011_sms_p1a: 'SMS alarms for Safera Cloud to this number will end on ',
  m012_account_delete_subject:
    'The deletion of your Safera Cloud user account is in progress',
  m012_account_delete_p1:
    'Your request to delete your Safera Cloud user account is pending and will be processed within a week, after which you will no longer be able to log in to your account.',
  m013_personal_information_subject:
    'Response to your service request - Your personal information stored on Safera Cloud',
  m013_personal_information_p1:
    'Upon request, we will send you the encrypted PDF file that contains your personal information stored and processed on the Safera Cloud service.',
  m013_personal_information_p3:
    'If you have any questions, you can contact us at tietosuoja@safera.fi.',
  m014_payment_failed_1_p1: 'Safera Cloud could not charge for the service:',
  m014_payment_failed_1_p3:
    'You can also log in to the service by opening the Safera App mobile app and selecting "Visit Safera Cloud" from the menu.',
  m014_sms_failed_1_p1:
    'Update your payment method: Safera Cloud could not charge for the service ',
  m014_payment_failed_1_p2:
    'Please update your payment method in the “payment details" section of your Safera Cloud account as soon as possible so that you can continue using the service without interruption:',
  m014_payment_failed_1_p4:
    'If you do not wish to continue using the paid service, you may cancel the subscription from the Safera Cloud service under account settings in your user account.',
  m015_sms_payment_failed_2_p1a:
    'Update your payment method: SMS alarms for Safera Cloud to this number will end on ',
  m016_payment_ok_subject: 'Safera Cloud SMS alarms service ended',
  m016_payment_ok_p2:
    'To resubscribe to SMS alarms from your device settings, sign in to Safera Cloud:',
  m016_sms_payment_ok_p1:
    'Safera Cloud SMS alarms service to this number has ended.',
  m017_payment_info_updated_subject:
    'Payment details updated - Subscription to Safera Cloud SMS alarms has been activated',
  m017_payment_info_updated_p1:
    'The subscription for SMS alarms for Safera Cloud has been activated. You will receive a separate receipt for your payment shortly.',
  m018_replace_batteries_1_p3:
    'When the battery level is very low, the device enters a power-saving mode, which means that the device is also not connected to the Safera Cloud service and its functionality is limited:',
  m018_replace_batteries_1_bullets_2:
    'The device does not send error messages and its operation cannot be confirmed via the Safera Cloud service.',
  m018_replace_batteries_1_bullets_3:
    'The device does not send the stored data to Safera Cloud.',
  m019_replace_batteries_2_p2:
    'Please note that before the batteries are replaced and the device is reconnected to the Wi-Fi network and Safera Cloud:',
  m019_sms_replace_batteries_p1b:
    'are disabled because the device cannot connect to the Wi-Fi network and Safera Cloud.',
  m020_wifi_disconnected_heading:
    'The device cannot connect to the Wi-Fi network and Safera Cloud',
  m020_wifi_disconnected_p2:
    'Please note that before your device is reconnected to the Safera Cloud service:',
  m021_wifi_ok_heading:
    'The device is operational again and connected to the Safera Cloud service',
  m021_sms_wifi_ok_p1b:
    'are enabled again, and the device is connected to a Wi-Fi network and Safera Cloud.',
  m027_aq_alarm_p2:
    'You can view the air quality history in Safera Cloud stored by your device:',
  m027_aq_alarm_p3:
    'You can control the air quality alarm limits by connecting to the device with the Safera App mobile application.',
  i003_wifi_disconnected_p1:
    'You can enable remote notifications for Wi-Fi and Safera Cloud disconnection.',
  i003_wifi_disconnected_p2:
    'When the device is not connected to the Wi-Fi network and Safera Cloud:',
  device_status_ok_p1b:
    'is up and running and connected to the Wi-Fi network and Safera Cloud service normally',
  brand_name: 'Safera',
  site_name: 'Personal',
  full_brand_name: 'Safera Cloud Personal',
  brand_service_name: 'Safera Cloud Personal service',
  short_brand_name: 'Safera Cloud',
  by_app: 'Safera App',
  apps_name: 'Safera App`s',
  in_app: 'in the Safera App',
  brand_company: 'Safera Oy',
  company_url: 'http://www.safera.com',
  wizard_cloud_title: 'Enable Safera Cloud',
  wizard_cloud_log_in_heading: 'Create a new account or log in to Safera Cloud',
  wizard_cloud_connect_device_p1:
    'Give the device a name will you recognise easily in Safera Cloud.',
  wizard_cloud_remote_alarms_p1:
    'The device will send the remote alarms via Safera Cloud. With default settings you will get remote alarms by email for cooking hazard and maintenance needs.',
  wizard_cloud_connect_device_heading:
    'Link the device to your Safera Cloud account',
  wizard_cloud_ready_p2a:
    'Log in to the Safera Cloud either from the main menu of the Safera App or enter the following address in the web browser:',
  wizard_cloud_ready_p2b: 'https://cloud.safera.com',
  terms_and_policy_url: 'https://safera.imagerelay.com/share/gdpr-eng',
  terms_url:
    'https://safera.imagerelay.com/share/warranty-and-license-terms?_ga=2.86347672.1200527814.1584089005-136302795.1582637603',
  wizard_cloud_connect_device_failed2_button: 'Log in to Safera Cloud',
  wizard_cloud_connect_device_heading3:
    'The device is now connected to your Safera Cloud account',
  support_temp_error_url: 'https://safera.fi/guide/00',
  support_tof_error_url: 'https://safera.fi/guide/01',
  support_adc_error_url: 'https://safera.fi/guide/02',
  support_gas1_error_url: 'https://safera.fi/guide/03',
  support_gas2_error_url: 'https://safera.fi/guide/04',
  support_lpc_error_url: 'https://safera.fi/guide/05',
  support_orientation_error_url: 'https://safera.fi/guide/06',
  support_humidity_error_url: 'https://safera.fi/guide/07',
  support_invalid_sensor_orientation_url: 'https://safera.fi/guide/08',
  support_battery_low_error_url: 'https://safera.fi/guide/09',
  support_pcu_connection_error_url: 'https://safera.fi/guide/10',
  support_sensor_prosessor_error_url: 'https://safera.fi/guide/11',
  support_lens_dirty_error_url: 'https://safera.fi/guide/12',
  support_pcu_error_url: 'https://safera.fi/guide/13',
  support_pcu_voltage_error_url: 'https://safera.fi/guide/14',
  support_pcu_current_error_url: 'https://safera.fi/guide/15',
  support_pcu_lds_error_url: 'https://safera.fi/guide/16',
  support_pcu_prosessor_error_url: 'https://safera.fi/guide/17',
  support_pcu_twd_error_url: 'https://safera.fi/guide/18',
  support_pcu_power_supply_error_url: 'https://safera.fi/guide/19',
  support_pcu_relay_error_url: 'https://safera.fi/guide/20',
  support_pcu_overheating_error_url: 'https://safera.fi/guide/21',
  support_pcu_curmeas_diff_error_url: 'https://safera.fi/guide/22',
  support_pcu_curmeas_backup_error_url: 'https://safera.fi/guide/23',
  support_pcu_overcurrent_error_url: 'https://safera.fi/guide/24',
  support_pcu_z_jammed_error_url: 'https://safera.fi/guide/25',
  support_critical_error_pcu_shutdown_url: 'https://safera.fi/guide/26',
  support_offline_error_url: 'https://safera.fi/guide/27',
  support_acknowledging_an_alarm_url: 'https://safera.fi/guide/28',
  support_acknowledging_water_alarm_utl: 'https://safera.fi/guide/29',
  support_wifi_error_url: 'https://safera.fi/guide/44',
  support_home_page_url: 'https://support.safera.com/en/support/home',
  company_logo_url:
    'https://www.safera.com/wp-content/uploads/2020/10/safera-logo.png',
  company_linkedin_url: 'https://www.linkedin.com/company/safera-oy',
  company_facebook_url: 'https://www.facebook.com/stoveguard/',
  company_twitter_url: 'https://twitter.com/SAFERA_Oy',
  company_instagram_url: 'https://www.instagram.com/safera_oy/',
  company_support_email: 'support@safera.com',
  company_street_address: 'Safera Oy, Tekniikantie 4 B 02150 Espoo, Finland',
  smartthings_add_device_info_c: 'Select the Safera.'
};
