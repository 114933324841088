/**
 * Data base write model.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default {
  path: undefined,
  doc: undefined,
  data: undefined,
  callback: undefined
};
