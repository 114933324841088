import * as utils from '../../utils';

export default async function CheckAccess(db, deviceId, uid) {
  try {
    if (db === undefined || deviceId === undefined || uid === undefined) {
      return { isValid: false, isOwner: false };
    }
    const usrRef = db
      .collection(utils.DEVICE_KEY + '/' + deviceId + '/' + utils.USERS_KEY)
      .doc(uid);
    const doc = await usrRef.get();
    var isOwner = false;
    if (doc.exists) {
      if (!doc.data().isBanned && doc.data().isRegistered) {
        isOwner = doc.data().isOwner !== undefined ? doc.data().isOwner : false;
        return { isValid: true, isOwner: isOwner };
      }
    }
    return { isValid: false, isOwner: isOwner };
  } catch (err) {
    console.error(err);
    return { isValid: false, isOwner: false };
  }
}
