/**
 * International phone number validator.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default function PhonenumberValidator(phone) {
  if (phone === undefined || phone === null) {
    return false;
  }
  if (phone === '') {
    return true;
  }
  var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  if (regex.test(phone)) {
    return true;
  } else {
    return false;
  }
}
