/**
 * Login page content.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import useTranslation from 'hooks/translations';
import Modal from 'react-bootstrap/Modal';
import loadingGif from 'assets/img/Spin98px.gif';

export default function LoginPage({ uiConfig, firebaseAuth }) {
  const translation = useTranslation();
  const [showModal, setModalShow] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setModalShow(false);
    }, 2000);
  }, []);

  return (
    <>
      <div className="d-flex flex-column device-settings-page">
        <div className="container d-flex flex-fill justify-content-center align-items-center align-items-sm-center log-in-container">
          <div className="row d-flex justify-content-center log-in-panel bg-100 rounded-3 shadow-lg">
            <div className="col-auto d-flex justify-content-center p-3 pt-5 pt-sm-4 pb-0 pb-sm-4 w-100 w-sm-auto">
              <div className="row d-flex flex-column align-items-center log-in-welcome-panel h-25 m-0 h-md-100 w-100">
                <div className="col-6 d-flex flex-column flex-fill justify-content-between align-items-center w-100">
                  <div className="row w-100 mb-3">
                    <div className="col p-0">
                      <div className="d-flex justify-content-center justify-content-sm-start mb-2">
                        <img
                          className="log-in-logo"
                          src="img/Safera-logo-cloud.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-inline-flex flex-column flex-fill m-0 w-auto">
                    <div className="col d-flex align-items-end w-auto p-0">
                      <h2 className="text-nowrap text-center text-sm-start w-auto mb-1">
                        {translation.short_brand_name}
                      </h2>
                    </div>
                  </div>
                  <div className="row d-flex flex-fill w-100 mb-sm-4 mb-0">
                    <div className="col w-100 p-0">
                      <h5 className="text-center text-sm-start text-primary w-100">
                        {translation.welcome}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center log-in-continue-with-panel p-3">
              <div className="row w-100">
                <div className="col w-100">
                  <hr className="horizontal-divider w-100" />
                </div>
              </div>
              <div className="row d-flex w-100 my-3">
                <div className="col d-flex flex-column justify-content-end align-items-start">
                  <h3 className="text-center w-100">
                    {translation.log_in_or_sign_up}
                  </h3>
                </div>
              </div>
              <div className="row d-flex flex-column w-100 m-0">
                <div className="col d-inline-flex flex-column flex-grow-1 justify-content-center">
                  <StyledFirebaseAuth
                    uiConfig={uiConfig}
                    firebaseAuth={firebaseAuth}
                  />
                </div>
              </div>
              <div className="row d-flex w-100 mt-3">
                <div className="col">
                  <p className="text-center lh-sm fs--2 text-muted">
                    By continuing, you are indicating that you accept
                    <a
                      href="https://safera.imagerelay.com/share/warranty-and-license-terms?_ga=2.86347672.1200527814.1584089005-136302795.1582637603"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://safera.imagerelay.com/share/gdpr-eng"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} role="dialog" tabIndex="-1" id="modal-loading">
        <div className="modal-body d-flex justify-content-center">
          <img src={loadingGif} alt="loading..." />
        </div>
      </Modal>
    </>
  );
}

LoginPage.propTypes = {
  firebaseAuth: PropTypes.object,
  uiConfig: PropTypes.object
};
