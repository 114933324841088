/**
 * Group card.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle,
  faCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import * as utils from 'utils';
import GroupDropdown from './GroupDropdown';
import RenameGroupName from 'components/DataBase/Group/RenameGroupName';
import RemoveGroup from 'components/DataBase/Group/RemoveGroup';
import { useStoreActions, useStoreState } from 'easy-peasy';
import MoveGroup from 'components/DataBase/Group/MoveGroup';
//import useTranslation from 'hooks/translations';

export default function GroupCard({
  groupId,
  setPage,
  groupDevices,
  numOfChildGroups,
  db,
  user
}) {
  //console.log(groupDevices);
  //const translation = useTranslation();
  const groups = useStoreState(state => state.groups);
  const { setDevice, updateGroupName, updateGroupParent } = useStoreActions(
    actions => ({
      setDevice: actions.setDevice,
      updateGroupName: actions.updateGroupName,
      updateGroupParent: actions.updateGroupParent
    })
  );

  const GROUP_STATUS = { ok: 0, nok: 1, unknown: 2 };
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    //console.log(groups[groupId].name);
    if (groups[groupId] != undefined) {
      setGroupName(groups[groupId].name);
    }
  }, [groups]);
  if (groupDevices.lenght == 0) {
    console.log('Group is empty!');
  }
  var status = GROUP_STATUS['ok'];
  var statusShow = (
    <FontAwesomeIcon icon={faCheckCircle} className="text-success fa-lg" />
  );
  var deviceShow = [];
  var deviceObj = {};
  var isGuestGroup = false;
  var isChildGroups = false;
  for (const device of groupDevices) {
    if (status == GROUP_STATUS['ok']) {
      status = isDeviceStatusOk(device.data);
    }
    if (device.isGuest) {
      isGuestGroup = true;
    }
    switch (device.data.hw_capabilities_id) {
      case utils.SENSE_HW_CAPABILITIES['BT']:
        if (deviceObj.bt == undefined) {
          deviceObj.bt = { title: 'Sense BT devices: ', num: 1 };
        } else {
          deviceObj.bt.num = deviceObj.bt.num + 1;
        }

        break;
      case utils.SENSE_HW_CAPABILITIES['WIFI']:
        if (deviceObj.wifi == undefined) {
          deviceObj.wifi = { title: 'Sense Wifi devices: ', num: 1 };
        } else {
          deviceObj.wifi.num = deviceObj.wifi.num + 1;
        }
        break;
      case utils.SENSE_HW_CAPABILITIES['PRO']:
        if (deviceObj.pro == undefined) {
          deviceObj.pro = { title: 'Sense Pro devices: ', num: 1 };
        } else {
          deviceObj.pro.num = deviceObj.pro.num + 1;
        }
        break;
      case utils.SENSE_HW_CAPABILITIES['CR_PRO']:
        if (deviceObj.cr == undefined) {
          deviceObj.cr = { title: 'Sense Integral devices: ', num: 1 };
        } else {
          deviceObj.cr.num = deviceObj.cr.num + 1;
        }
        break;
      default:
        if (deviceObj.unknow == undefined) {
          deviceObj.unknow = { title: 'Unknow devices: ', num: 1 };
        } else {
          deviceObj.unknow.num = deviceObj.unknow.num + 1;
        }
        break;
    }
  }
  const [isGuest] = useState(isGuestGroup);

  for (const content in deviceObj) {
    deviceShow.push(deviceObj[content]);
  }
  if (numOfChildGroups > 0) {
    deviceShow.push({ title: 'Groups: ', num: numOfChildGroups });
    isChildGroups = true;
  }

  if (status == GROUP_STATUS['nok']) {
    statusShow = (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className="text-danger fa-lg"
      />
    );
  } else if (status == GROUP_STATUS['unknown']) {
    statusShow = (
      <FontAwesomeIcon icon={faCircle} className="text-muted fa-lg" />
    );
  }

  async function renameGroup(newName) {
    console.log(newName, isGuest);
    var retVal = await RenameGroupName(db, groupId, newName, isGuest, user.uid);
    console.log(retVal);
    setGroupName(newName);
    updateGroupName({ groupId: groupId, name: newName });
  }

  function isDeviceStatusOk(deviceData) {
    if (deviceData == undefined) {
      //console.log('1');
      return GROUP_STATUS['nok'];
    }
    if (deviceData.last_activity_timestamp != undefined) {
      if (deviceData.last_activity_timestamp != 1) {
        if (
          deviceData.last_activity_timestamp == undefined ||
          deviceData.last_activity_timestamp <
            new Date().getTime() - utils.TIME_24H
        ) {
          //console.log('2');
          return GROUP_STATUS['nok'];
        }
      }
    }
    if (deviceData.latest_air_quality_event != undefined) {
      const aqEvent = deviceData.latest_air_quality_event;
      if (isAirQualityAlarm(aqEvent.code)) {
        //console.log('3');
        return GROUP_STATUS['nok'];
      }
    }
    if (deviceData.latest_alarm_event != undefined) {
      const alarmEvent = deviceData.latest_alarm_event;
      if (
        alarmEvent.code == utils.WE_STOVE_ALARM ||
        alarmEvent.code == utils.WE_STOVE_ALARM_PCU_DISCONNECTED ||
        alarmEvent.code == utils.WE_CTU_FIRE_EXTINGUISH_ALARM
      ) {
        //console.log('4');
        return GROUP_STATUS['nok'];
      }
    }
    if (deviceData.latest_error_event != undefined) {
      const errorEvent = deviceData.latest_error_event;
      if (errorEvent.code == utils.WE_CRITICAL_ERROR) {
        //console.log('5');
        return GROUP_STATUS['nok'];
      }
    }
    if (deviceData.latest_water_leakage_event != undefined) {
      const waterEvent = deviceData.latest_water_leakage_event;
      if (waterEvent.code == utils.WE_PCU_WATER_LEAKAGE_ALARM) {
        //console.log('6');
        return GROUP_STATUS['nok'];
      }
    }

    if (deviceData.last_activity_timestamp == 1) {
      return GROUP_STATUS['unknown'];
    }
    return GROUP_STATUS['ok'];
  }

  function isAirQualityAlarm(code) {
    if (
      code === utils.WE_AIR_QUALITY_ALARM ||
      code == utils.WE_AIR_QUALITY_ALARM_CLEARED ||
      code == utils.WE_AIR_QUALITY_ALARM_TEMP_LOW ||
      code == utils.WE_AIR_QUALITY_ALARM_TEMP_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_HUM_LOW ||
      code == utils.WE_AIR_QUALITY_ALARM_HUM_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_PM25_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_ECO2_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_TVOC_HIGH ||
      code == utils.WE_AIR_QUALITY_ALARM_AQI_HIGH
    ) {
      return true;
    } else {
      return false;
    }
  }

  function goToGroup() {
    console.log(groupName);
    setPage({
      groupId: groupId,
      groupName: groupName,
      isGuest: isGuest,
      groupDevices: groupDevices,
      page: utils.ADVANCED_PAGES['DEVICE_LIST']
    });
  }

  // async function readGroupName(isGuestGroup) {
  //   try {
  //     const group = await ReadGroup(db, groupId, isGuestGroup, user.uid);
  //     if (mountedRef.current) {
  //       console.log('read group data');
  //       setGroupName(group.name);
  //       addGroup({ groupId: groupId, data: group });
  //     }
  //     return;
  //   } catch (error) {
  //     console.error('Group ERROR:', error);
  //   }
  // }

  async function removeGroup() {
    try {
      const retVal = await RemoveGroup(db, groupDevices, isGuest, user.uid);
      if (!retVal) {
        console.error('Something went wrong!');
      }
      for (var device of groupDevices) {
        if (isGuest) {
          device.groupId = '';
        } else {
          device.data.groupId = '';
        }
        setDevice(device);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function moveGroup(parentGroupId) {
    console.log(parentGroupId);
    if (parentGroupId != undefined) {
      await MoveGroup(db, groupId, isGuest, user.uid, parentGroupId);
      updateGroupParent({ groupId: groupId, parent: parentGroupId });
    }
  }

  const [statusIcon] = useState(statusShow);

  return (
    <>
      <div className="d-flex justify-content-center col">
        <div className="card device shadow-lg">
          <div className={`card-body px-3 py-0 rounded`}>
            <div>
              <div className="">
                <div
                  className="row row-cols-3 py-2 px-2 rounded-top"
                  style={{
                    backgroundImage: 'linear-gradient(#00426C, #025B95)'
                  }}
                >
                  <div className="col-1 d-flex justify-content-center align-items-center">
                    {statusIcon}
                  </div>
                  <div
                    className="d-flex flex-grow-1 align-items-center p-0 ps-2"
                    onClick={goToGroup}
                  >
                    <h5 className="device-cards-name  m-0">{groupName}</h5>
                  </div>
                  <GroupDropdown
                    isGuest={isGuest}
                    renameGroup={renameGroup}
                    groupName={groupName}
                    groupId={groupId}
                    removeGroup={removeGroup}
                    isChildGroups={isChildGroups}
                    moveGroup={moveGroup}
                  />
                </div>
              </div>
              <div>
                <div
                  className="overflow-auto mt-3 ms-2"
                  style={{
                    height: '120px'
                  }}
                  onClick={goToGroup}
                >
                  {deviceShow.map(content => (
                    <div key={uuidv4()}>
                      {content.title} {content.num}
                      <br />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

GroupCard.propTypes = {
  setPage: PropTypes.func,
  groupId: PropTypes.string,
  groupDevices: PropTypes.array,
  db: PropTypes.object,
  user: PropTypes.object,
  numOfChildGroups: PropTypes.number
};
