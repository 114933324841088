import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {
  faArrowsAlt,
  faEllipsisH,
  faPen,
  faShare,
  faShareSquare
} from '@fortawesome/free-solid-svg-icons';
import TextSanitizing from 'components/TextSanitizing';
import { Modal } from 'react-bootstrap';
import useTranslation from 'hooks/translations';
import ToastMessage from '../ToastMessage';
import MoveGroupModal from './MoveGroupModal';

export default function GroupDropdown({
  isGuest,
  groupName,
  groupId,
  renameGroup,
  removeGroup,
  isChildGroups,
  moveGroup
}) {
  var ownersDropdown = '';
  const translation = useTranslation();

  const nameRef = useRef();
  const [show, setShow] = useState(false);
  const [warningText, setWarningText] = useState('');
  const [showModal, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);
  const [showRemoveGroupModal, setRemoveGroupModalShow] = useState(false);
  const handleRemoveGroupModalShow = () => setRemoveGroupModalShow(true);
  const handleRemoveGroupModalClose = () => setRemoveGroupModalShow(false);
  const [showErrorModal, setErrorModalShow] = useState(false);
  const handleErrorModalShow = () => setErrorModalShow(true);
  const handleErrorModalClose = () => setErrorModalShow(false);
  const [showMoveGroupModal, setMoveGroupModalShow] = useState(false);
  const handleMoveGroupModalShow = () => setMoveGroupModalShow(true);
  const handleMoveGroupModalClose = () => setMoveGroupModalShow(false);

  const [toastContent, setToastContent] = useState({
    message: '',
    success: true
  });

  if (!isGuest) {
    var transferOwnerShip = (
      <a className="dropdown-item d-flex align-items-center">
        <div className="d-inline-flex dropdown-menu-icon">
          <FontAwesomeIcon
            icon={faShareSquare}
            className="icon-tiny-button text-primary"
          />
        </div>
        Transfer Group Ownership
      </a>
    );
    if (isChildGroups) {
      transferOwnerShip = '';
    }
    ownersDropdown = (
      <>
        <a className="dropdown-item d-flex align-items-center">
          <div className="d-inline-flex dropdown-menu-icon">
            <FontAwesomeIcon
              icon={faShare}
              className="icon-tiny-button text-primary"
            />
          </div>
          Share Group
        </a>
        {transferOwnerShip}
      </>
    );
  }

  function renameNameHandler() {
    const newName = TextSanitizing(nameRef.current.value, false);
    console.log(newName);
    if (newName !== nameRef.current.value) {
      setWarningText(
        <>
          <p className="text-warning mt-2 mb-0 fs--1">
            {translation.please_use_only_letters_and_numbers}
          </p>
          <p className="text-warning mb-0 fs--1">
            {translation.characters_that_are_not_allowed_are_removed}
          </p>
        </>
      );
      nameRef.current.value = newName;
    } else {
      setWarningText('');

      renameGroup(newName);

      setToastContent({ message: translation.changes_saved, success: true });
      setModalShow(false);
    }
  }

  function handleGroupModalHandler() {
    if (isChildGroups) {
      handleErrorModalShow();
    } else {
      handleRemoveGroupModalShow();
    }
  }

  function removeGroupHandler() {
    console.log(groupName);
    removeGroup();
    setRemoveGroupModalShow(false);
  }

  return (
    <>
      <div className="col-auto d-inline-flex justify-content-end align-items-center p-0 ps-3">
        <div className="dropdown d-inline-flex justify-content-end align-items-center justify-content-sm-center align-items-sm-center">
          <button
            className="btn bg-primary d-inline-flex justify-content-center align-items-center flex-nowrap m-auto btn-circle-with-icon shadow"
            aria-expanded="false"
            data-bs-toggle="dropdown"
            type="button"
          >
            <FontAwesomeIcon
              icon={faEllipsisH}
              className="text-dark icon-tiny-button"
            />
          </button>
          <div className="dropdown-menu dropdown-menu-end">
            <a
              className="dropdown-item d-flex align-items-center"
              onClick={handleModalShow}
            >
              <div className="d-inline-flex dropdown-menu-icon">
                <FontAwesomeIcon
                  icon={faPen}
                  className="icon-tiny-button text-primary"
                />
              </div>
              Rename Group
            </a>
            <a
              className="dropdown-item d-flex align-items-center"
              onClick={handleMoveGroupModalShow}
            >
              <div className="d-inline-flex dropdown-menu-icon">
                <FontAwesomeIcon
                  icon={faArrowsAlt}
                  className="icon-tiny-button text-primary"
                />
              </div>
              Move
            </a>
            {ownersDropdown}
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item link-danger"
              onClick={handleGroupModalHandler}
            >
              Remove Group
            </a>
          </div>
        </div>
      </div>
      <Modal show={showModal} role="dialog" tabIndex="-1" id="modal-group-name">
        <div className="modal-header pe-4 border-0">
          <h4 className="modal-title text-break pe-5">
            {translation.save_name}
            <br />
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <label className="form-label">{translation.name}</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            defaultValue={groupName}
            ref={nameRef}
          />
          {warningText}
        </div>
        <div className="modal-footer border-0 pt-0 pb-4">
          <div className="w-100">
            <button
              className="btn btn-primary w-100 shadow"
              type="button"
              onClick={() => renameNameHandler()}
            >
              {translation.save}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={showRemoveGroupModal}
        role="dialog"
        tabIndex="-1"
        id="modal-remove-group"
      >
        <div className="modal-header pe-4 border-0">
          <h4 className="modal-title text-break pe-5">
            {groupName}
            <br />
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleRemoveGroupModalClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <label className="form-label">
            Are you sure you want to remove the group?
          </label>
        </div>
        <div className="modal-footer border-0 pt-0 pb-4">
          <div className="w-100">
            <button
              type="button"
              className="btn btn-light w-100 shadow mb-3"
              onClick={handleRemoveGroupModalClose}
            >
              {translation.cancel}
            </button>
            <button
              className="btn btn-danger w-100 shadow"
              type="button"
              onClick={() => removeGroupHandler()}
            >
              Remove
            </button>
          </div>
        </div>
      </Modal>
      <Modal show={showErrorModal} role="dialog" tabIndex="-1" id="modal-error">
        <div className="modal-header pe-4 border-0">
          <h4 className="modal-title text-break pe-5">
            Prohibited
            <br />
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleErrorModalClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <label className="form-label">
            First, remove the groups from within the group.
          </label>
        </div>
        <div className="modal-footer border-0 pt-0 pb-4">
          <div className="w-100">
            <button
              type="button"
              className="btn btn-primary w-100 shadow mb-3"
              onClick={handleErrorModalClose}
            >
              {translation.ok}
            </button>
          </div>
        </div>
      </Modal>
      <MoveGroupModal
        showModal={showMoveGroupModal}
        handleClose={handleMoveGroupModalClose}
        groupId={groupId}
        moveGroup={moveGroup}
      />
      <ToastMessage
        setShow={setShow}
        success={toastContent.success}
        show={show}
        message={toastContent.message}
      />
    </>
  );
}

GroupDropdown.propTypes = {
  isGuest: PropTypes.bool,
  groupId: PropTypes.string,
  groupName: PropTypes.string,
  renameGroup: PropTypes.func,
  removeGroup: PropTypes.func,
  isChildGroups: PropTypes.bool,
  moveGroup: PropTypes.func
};
