/**
 * Wizard handler
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StepWizard from 'react-step-wizard';
import WizardLogInSuccessfully from './WizardLogInSuccessfully';
import WizardNewUser from './WizardNewUser';
import WizardDeviceName from './WizardDeviceName';
import WizardConnectDevice from './WizardConnectDevice';
import WizardRemoteAlarms from './WizardRemoteAlarms';
import { useLanguageContext } from 'context/LanguageContext';
import useTranslation from 'hooks/translations';
//import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

export default function WizardHandler({
  db,
  user,
  config,
  auth,
  firebaseSignout,
  showCustomPage
}) {
  const { changeLanguage } = useLanguageContext();
  const translation = useTranslation();
  const [state, updateState] = useState({
    form: {},
    db: db,
    demo: true // uncomment to see more
  });
  var analyticsUserId = user.uid;
  if (config.analytics_id != undefined) {
    analyticsUserId = config.analytics_id;
  }

  useEffect(() => {
    //console.log(user);
    changeLanguage(user.language !== undefined ? user.language : 'en');
    TagManager.dataLayer({
      dataLayer: {
        userId: analyticsUserId,
        userProject: 'Personal',
        page: 'Device registration Wizard start',
        analytics_id:
          config.analytics_id != undefined ? config.analytics_id : ''
      },
      dataLayerName: 'SaferaDataLayer'
    });
  }, []);

  const updateForm = (key, value) => {
    const { form } = state;

    form[key] = value;
    updateState({
      ...state,
      form
    });
  };

  // Do something on step change
  const onStepChange = stats => {
    TagManager.dataLayer({
      dataLayer: {
        userId: analyticsUserId,
        userProject: 'Personal',
        page: 'Wizard step ' + stats.activeStep,
        analytics_id:
          config.analytics_id != undefined ? config.analytics_id : ''
      },
      dataLayerName: 'SaferaDataLayer'
    });

    // ReactGA.send({
    //   hitType: 'pageview',
    //   page: 'Wizard step ' + stats.activeStep,
    //   analytics_id: config.analytics_id
    // });
    console.log(stats);
  };

  const setInstance = SW =>
    updateState({
      ...state,
      SW
    });

  return (
    <div className="wizard-body device-settings-page">
      <header className="d-flex justify-content-center wizard-header sticky-top w-100">
        <nav className="wizard-header-content h-100 w-100">
          <div className="container d-flex flex-column w-100 h-100">
            <div className="row d-flex flex-grow-1 align-items-center">
              <div className="col d-flex flex-column justify-content-end align-items-center w-100">
                <h5 className="text-truncate text-center text-white w-100 mb-0">
                  {translation.wizard_cloud_title}
                </h5>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <StepWizard
        onStepChange={onStepChange}
        isHashEnabled
        transitions={state.transitions}
        instance={setInstance}
      >
        <Singin
          hashKey={'FirstStep'}
          update={updateForm}
          user={user}
          auth={auth}
          firebaseSignout={firebaseSignout}
          db={db}
        />
        <DeviceName form={state.form} update={updateForm} config={config} />
        <ConnectDevice
          form={state.form}
          user={user}
          db={db}
          deviceId={config.id}
          config={config}
        />
        <RemoteAlarms
          form={state.form}
          showCustomPage={showCustomPage}
          user={user}
          db={db}
          deviceId={config.id}
          config={config}
        />
      </StepWizard>
    </div>
  );
}

/** Steps */

const Singin = props => {
  // const update = e => {
  //   props.update(e.target.name, e.target.value);
  // };
  //console.log(update);
  if (props.user.policyAccepted) {
    return <WizardLogInSuccessfully props={props} />;
  } else {
    return <WizardNewUser props={props} />;
  }
};

const DeviceName = props => {
  return <WizardDeviceName props={props} />;
};

const ConnectDevice = props => {
  return <WizardConnectDevice props={props} />;
};

const RemoteAlarms = props => {
  return <WizardRemoteAlarms props={props} />;
};

WizardHandler.propTypes = {
  props: PropTypes.object,
  nextStep: PropTypes.func,
  firebaseSignout: PropTypes.func,
  user: PropTypes.object,
  db: PropTypes.object,
  auth: PropTypes.object,
  config: PropTypes.object,
  showCustomPage: PropTypes.func
};
