/**
 * Status popover.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import * as utils from '../../../utils';
import { v4 as uuidv4 } from 'uuid';
import LongInternationalTimeFormat from '../../../components/LongInternationalTimeFormat';
import {
  faCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import useTranslation from 'hooks/translations';
import ErrorCode from 'components/ErrorCode';

export default function StatusPopover(device, user) {
  const translation = useTranslation();
  var lastActivityTime = '';
  if (device.data === undefined) {
    device.data = {};
  }
  if (device.data.last_activity_timestamp !== undefined) {
    lastActivityTime = LongInternationalTimeFormat(
      device.data.last_activity_timestamp,
      user.language
    );
  }
  var eventList = [];

  if (device.data.last_activity_timestamp == 1) {
    eventList.push({
      key: uuidv4(),
      statusIcon: faCircle,
      statusColor: 'lh-base fa-lg text-muted',
      header: translation.m020_wifi_disconnected_heading,
      statusIconColor: 'text-muted me-2',
      popBody: (
        <div>
          <p>
            {translation.m020_wifi_disconnected_subject} {device.name}
          </p>
          <p>
            {translation.m020_wifi_disconnected_p1a} {device.name}{' '}
            {translation.m020_wifi_disconnected_p1b}
          </p>
          <p>
            <strong>{translation.m020_wifi_disconnected_p3}</strong>
          </p>
          <p>{lastActivityTime}</p>
        </div>
      ),
      popFooter: (
        <div>
          <p className=" fs--1">{translation.m020_wifi_disconnected_p4}</p>
          <a
            href={translation.support_wifi_error_url}
            target="_blank"
            rel="noreferrer"
          >
            support.safera.com{' '}
          </a>
        </div>
      )
    });
  } else if (
    device.data.last_activity_timestamp <
    new Date().getTime() - utils.TIME_24H
  ) {
    const OfflinePopContent = {
      key: uuidv4(),
      statusIcon: faExclamationTriangle,
      statusColor: 'lh-base fa-lg text-danger',
      header: translation.m020_wifi_disconnected_heading,
      statusIconColor: 'text-danger me-2',
      popBody: (
        <div>
          <p>
            {translation.m020_wifi_disconnected_subject} {device.name}
          </p>
          <p>
            {translation.m020_wifi_disconnected_p1a} {device.name}{' '}
            {translation.m020_wifi_disconnected_p1b}
          </p>
          <p>
            <strong>{translation.m020_wifi_disconnected_p3}</strong>
          </p>
          <p>{lastActivityTime}</p>
        </div>
      ),
      popFooter: (
        <div>
          <p className=" fs--1">{translation.m020_wifi_disconnected_p4}</p>
          <a
            href={translation.support_wifi_error_url}
            target="_blank"
            rel="noreferrer"
          >
            support.safera.com{' '}
          </a>
        </div>
      )
    };

    eventList.push(OfflinePopContent);
  }

  if (device.data.latest_alarm_event !== undefined) {
    const alarmEvent = device.data.latest_alarm_event;
    if (
      alarmEvent.code === utils.WE_STOVE_ALARM ||
      alarmEvent.code === utils.WE_STOVE_ALARM_PCU_DISCONNECTED
    ) {
      var bodyTxt = translation.m028_cooking_alarm_no_pcu_p1b;
      var footerTxt = translation.m028_cooking_alarm_no_pcu_p2;
      if (alarmEvent.code === utils.WE_STOVE_ALARM_PCU_DISCONNECTED) {
        bodyTxt = translation.m023_cooking_alarm_p1b;
        footerTxt = translation.m023_cooking_alarm_p2;
      }
      const alarmPopContent = {
        key: uuidv4(),
        statusIcon: faExclamationTriangle,
        statusColor: 'lh-base fa-lg text-danger',
        header: translation.m023_cooking_alarm_heading,
        statusIconColor: 'text-danger me-2',
        popBody: (
          <div>
            <p>
              {translation.m004_new_device_subject_a}&nbsp;{device.name}&nbsp;
              {bodyTxt}
            </p>
            <p>
              {LongInternationalTimeFormat(
                parseInt(alarmEvent.timestamp) * 1000,
                user.language
              )}
            </p>
          </div>
        ),
        popFooter: (
          <div>
            <p className=" fs--1">{footerTxt}</p>
            <a
              href="https://support.safera.com"
              target="_blank"
              rel="noreferrer"
            >
              support.safera.com
            </a>
          </div>
        )
      };

      eventList.push(alarmPopContent);
    }
  }

  if (device.data.latest_water_leakage_event !== undefined) {
    const waterEvent = device.data.latest_water_leakage_event;
    if (waterEvent.code === utils.WE_PCU_WATER_LEAKAGE_ALARM) {
      const waterPopContent = {
        key: uuidv4(),
        statusIcon: faExclamationTriangle,
        statusColor: 'lh-base fa-lg text-danger',
        header: translation.m025_water_leak_alarm_heading,
        statusIconColor: 'text-danger me-2',
        popBody: (
          <div>
            <p>
              {translation.m004_new_device_subject_a}&nbsp;{device.name}&nbsp;
              {translation.m025_water_leak_alarm_p1b}
            </p>
            <p>
              {LongInternationalTimeFormat(
                parseInt(waterEvent.timestamp) * 1000,
                user.language
              )}
            </p>
          </div>
        ),
        popFooter: (
          <div>
            <p className=" fs--1">{translation.m025_water_leak_alarm_p2}</p>
            <a
              href="https://support.safera.com"
              target="_blank"
              rel="noreferrer"
            >
              support.safera.com
            </a>
          </div>
        )
      };

      eventList.push(waterPopContent);
    }
  }

  if (device.data.latest_air_quality_event !== undefined) {
    const airEvent = device.data.latest_air_quality_event;

    if (
      airEvent.code === utils.WE_AIR_QUALITY_ALARM ||
      airEvent.code === utils.WE_AIR_QUALITY_ALARM_TEMP_LOW ||
      airEvent.code === utils.WE_AIR_QUALITY_ALARM_TEMP_HIGH ||
      airEvent.code === utils.WE_AIR_QUALITY_ALARM_HUM_LOW ||
      airEvent.code === utils.WE_AIR_QUALITY_ALARM_HUM_HIGH ||
      airEvent.code === utils.WE_AIR_QUALITY_ALARM_PM25_HIGH ||
      airEvent.code === utils.WE_AIR_QUALITY_ALARM_ECO2_HIGH ||
      airEvent.code === utils.WE_AIR_QUALITY_ALARM_TVOC_HIGH ||
      airEvent.code === utils.WE_AIR_QUALITY_ALARM_AQI_HIGH
    ) {
      var airPopContent = {
        key: uuidv4(),
        statusIcon: faExclamationTriangle,
        statusColor: 'lh-base fa-lg text-warning',
        header: translation.m027_aq_alarm_heading,
        statusIconColor: 'text-warning me-2',
        popBody: (
          <div>
            <p>
              {translation.m004_new_device_subject_a}&nbsp;{device.name}&nbsp;
              {translation.m027_aq_alarm_p1b}
            </p>
            <p>
              {LongInternationalTimeFormat(
                parseInt(airEvent.timestamp) * 1000,
                user.language
              )}
              <br />
              {getAirQualityAlarmReason(airEvent.code)}
            </p>
          </div>
        ),
        popFooter: (
          <div>
            <p className=" fs--1">{translation.m025_water_leak_alarm_p2}</p>
            <a
              href="https://support.safera.com"
              target="_blank"
              rel="noreferrer"
            >
              support.safera.com
            </a>
          </div>
        )
      };

      eventList.push(airPopContent);
    }
  }
  if (device.data.latest_error_event !== undefined) {
    const errorEvent = device.data.latest_error_event;
    if (
      errorEvent.code === utils.WE_CRITICAL_ERROR ||
      errorEvent.code === utils.WE_CRITICAL_ERROR_PCU_SHUTDOWN
    ) {
      const errorPopContent = {
        key: uuidv4(),
        statusIcon: faExclamationTriangle,
        statusColor: 'lh-base fa-lg text-warning',
        header: translation.m022_malfunction_heading,
        statusIconColor: 'text-warning me-2',
        popBody: (
          <div>
            <p>
              {translation.m022_malfunction_p1a}&nbsp;{device.name}&nbsp;
              {translation.m022_malfunction_p1b}
            </p>

            {ErrorCode(errorEvent.code, translation).map((errorTxt, i) => (
              <div key={i}>
                {errorTxt} <br />
              </div>
            ))}
            <br />
            <p>
              {LongInternationalTimeFormat(
                parseInt(errorEvent.timestamp) * 1000,
                user.language
              )}
            </p>
          </div>
        ),
        popFooter: (
          <div>
            <p className=" fs--1">{translation.m022_malfunction_p2}</p>
            <a
              href={translation.support_critical_error_pcu_shutdown_url}
              target="_blank"
              rel="noreferrer"
            >
              {translation.support_critical_error_pcu_shutdown_url}
            </a>
          </div>
        )
      };

      eventList.push(errorPopContent);
    }
  }

  function getAirQualityAlarmReason(code) {
    var reasonTxt = '';
    switch (code) {
      case utils.WE_AIR_QUALITY_ALARM:
        break;
      case utils.WE_AIR_QUALITY_ALARM_TEMP_LOW:
        reasonTxt = translation.low_temperature;
        break;
      case utils.WE_AIR_QUALITY_ALARM_TEMP_HIGH:
        reasonTxt = translation.high_temperature;
        break;
      case utils.WE_AIR_QUALITY_ALARM_HUM_LOW:
        reasonTxt = translation.low_humidity;
        break;
      case utils.WE_AIR_QUALITY_ALARM_HUM_HIGH:
        reasonTxt = translation.high_humidity;
        break;
      case utils.WE_AIR_QUALITY_ALARM_PM25_HIGH:
        reasonTxt = translation.pm25_high;
        break;
      case utils.WE_AIR_QUALITY_ALARM_ECO2_HIGH:
        reasonTxt = translation.high_eco2;
        break;
      case utils.WE_AIR_QUALITY_ALARM_TVOC_HIGH:
        reasonTxt = translation.high_tvoc;
        break;
      case utils.WE_AIR_QUALITY_ALARM_AQI_HIGH:
        reasonTxt = translation.high_aqi;
        break;
      default:
        break;
    }
    //console.log('reason: ', reasonTxt);
    return reasonTxt;
  }

  return eventList;
}
