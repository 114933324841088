/**
 * Safera brand translations (FI).
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default {
  m001_verification_subject:
    'Vahvista Safera Cloud -käyttäjätilisi sähköpostiosoite',
  m001_verification_heading: 'Tervetuloa Safera Cloud -palvelun käyttäjäksi',
  m001_verification_content_p1:
    'Uusi Safera Cloud -tilisi on nyt luotu. Voit kirjautua palveluun:',
  m002_name_change_content_p1a:
    'Safera Cloud -käyttäjätilin nimi on muutettu. Uusi nimi on ',
  m002_name_change_subject: 'Safera Cloud -käyttäjätilin nimi on muutettu',
  messages_footer_v1:
    'Saitko tämän viestin, vaikka et ole luonut tiliä? Katso ohjeet Safera Tuotetukisivustolta.',
  messages_footer_v2:
    'Jos sait tämän viestin, vaikka et ole tehnyt muutoksia käyttäjätiliisi, katso ohjeet Safera Tuotetukisivustolta.',
  m003_number_change_subject:
    'Safera Cloud -käyttäjätilin puhelinnumero on muutettu',
  m003_number_change_content_p1a:
    'Safera Cloud -käyttäjätilin puhelinnumero on muutettu. Uusi numero on ',
  m003_number_change_content_p1b:
    'Safera Cloud -käyttäjätilin puhelinnumero on poistettu. ',
  m004_new_device_subject_b: 'on lisätty Safera Cloud -käyttäjätiliisi',
  m004_new_device_content_p1b:
    'on nyt lisätty Safera Cloud -käyttäjätiliisi, joten käytössäsi on seuraavat edut:',
  m005_share_device_subject_b:
    'siirto tai jakaminen Safera Cloud -käyttäjätiliisi',
  m005_share_device_p1a: 'Safera Cloud -käyttäjä ',
  m005_share_device_p2:
    'Voit hyväksyä tai hylätä pyynnön kirjautumalla Safera Cloud -palveluun:',
  m005_share_device_button: 'Kirjaudu Safera Cloud -palveluun',
  m006_add_device_reminder_subject:
    'Hyödynnä Safera Cloud -palvelun edut - Liitä Safera-tuote käyttäjätiliisi',
  m007_thanks_for_order_how_to_cancel_content:
    'Voit lopettaa palvelun milloin tahansa kirjautumalla Safera Cloud -palveluun ja valitsemalla käyttäjätilistäsi Tilin asetukset.',
  m007_thanks_for_order_support_site: 'Tuotetukisivusto: support.safera.com',
  m007_thanks_for_order_support_contact: 'Ota yhteyttä: support@safera.com',
  m007_sms_thanks_for_order_p1a: 'Safera Cloud -palvelun käyttäjä ',
  m009_trial_ending_subcription_continues_button:
    'Kirjoita arvostelu Safera-tuotteestasi',
  m010_receipt_subject:
    'Kuitti Safera Cloud -palvelusta - Tekstiviestihälytysten lisäpalvelu 1 kk',
  m011_sms_service_ending_p2:
    'Jos haluat etähälytykset tekstiviestillä uudelleen käyttöön, tilaa tekstiviestihälytykset Safera Cloud -palvelussa:',
  m011_sms_p1a:
    'Safera Cloud -palvelun tekstiviestihälytykset tähän numeroon päättyvät ',
  m012_account_delete_subject:
    'Safera Cloud -käyttäjätilisi poistaminen on käsittelyssä',
  m012_account_delete_p1:
    'Pyyntösi poistaa Safera Cloud -käyttäjätilisi on käsittelyssä ja toteutetaan viikon kuluessa, jonka jälkeen et voi enää kirjautua tilillesi.',
  m013_personal_information_subject:
    'Vastaus palvelyyntöösi - Safera Cloud -palveluun tallennetut henkilötietosi',
  m013_personal_information_p1:
    'Lähetämme pyynnöstäsi oheisen salatun PDF-tiedoston, johon on koottu Safera Cloud -palveluun tallennetut ja palvelussa käsiteltävät henkilötietosi.',
  m013_personal_information_p3:
    'Mikäli sinulla on kysyttävää, voit olla yhteydessä osoitteeseen tietosuoja@safera.fi.',
  m014_payment_failed_1_p1:
    'Safera Cloud ei voinut veloittaa maksua palvelusta:',
  m014_payment_failed_1_p3:
    'Voit myös kirjautua palveluun avaamalla Safera App -mobiilisovelluksen ja valitsemalla valikosta "Visit Safera Cloud".',
  m014_sms_failed_1_p1:
    'Päivitä maksutapa: Safera Cloud ei voinut veloittaa maksua palvelusta  ',
  m014_payment_failed_1_p2:
    'Päivitä maksutapa Safera Cloud -käyttäjätiliisi kohdasta "Maksutiedot" mahdollisimman pian, jotta voit jatkaa palvelun käyttöä keskeytyksettä:',
  m014_payment_failed_1_p4:
    'Jos et halua jatkaa maksullisen palvelun käyttöä, voit lopettaa palvelun tilaamisen Safera Cloud -palvelussa käyttäjätilisi kohdasta Tilin asetukset.',
  m015_sms_payment_failed_2_p1a:
    'Päivitä maksutapa: Safera Cloud -palvelun tekstiviestihälytykset tähän numeroon päättyvät ',
  m016_payment_ok_subject: 'Safera Cloud -tekstiviestihälytykset on lopetettu',
  m016_payment_ok_p2:
    'Voit tilata tekstiviestihälytykset uudelleen laitteen asetuksista kirjautumalla Safera Cloud -palveluun:',
  m016_sms_payment_ok_p1:
    'Safera Cloud -palvelun tekstiviestihälytykset tähän numeroon on lopetettu.',
  m017_payment_info_updated_subject:
    'Maksutiedot päivitetty - Safera Cloud -palvelun tekstiviestihälytysten tilaus on aktivoitu',
  m017_payment_info_updated_p1:
    'Safera Cloud -palvelun tekstiviestihälytysten tilaus on aktivoitu. Saat maksusuorituksesta erillisen kuitin pian.',
  m018_replace_batteries_1_p3:
    'Kun paristojen varaustila on erittäin alhainen, laite menee virransäästötilaan, jolloin laite ei myöskään ole yhteydessä Safera Cloud -palveluun ja sen toiminnallisuus on rajoittunut:',
  m018_replace_batteries_1_bullets_2:
    'Laite ei lähetä vikailmoituksia eikä sen toimintakuntoa voi vahvistaa Safera Cloud -palvelun kautta.',
  m018_replace_batteries_1_bullets_3:
    'Laite ei lähetä tallentamiaan tietoja Safera Cloud -palveluun.',
  m019_replace_batteries_2_p2:
    'Huomaathan, että ennen kuin paristot on vaihdettu ja laite yhdistetty takaisin Wi-Fi -verkkoon ja Safera Cloud -palveluun:',
  m019_sms_replace_batteries_p1b:
    'eivät ole käytössä, koska laite ei saa yhteyttä Wi-Fi -verkkoon ja Safera Cloud -palveluun.',
  m020_wifi_disconnected_heading:
    'Laite ei saa yhteyttä Wi-Fi -verkkoon ja Safera Cloud -palveluun',
  m020_wifi_disconnected_p2:
    'Huomaathan, että ennen kuin laitteen yhteys Safera Cloud -pilvipalveluun on palautettu:',
  m021_wifi_ok_heading:
    'Laite on taas toimintakunnossa ja yhteydessä Safera Cloud -palveluun',
  m021_sms_wifi_ok_p1b:
    'ovat taas käytössä, ja laite on yhdistetty Wi-Fi -verkkoon ja Safera Cloud -palveluun.',
  m027_aq_alarm_p2:
    'Voit tarkastella laitteen tallentamaa ilmanlaatuhistoriaa Safera Cloud -palvelussa:',
  m027_aq_alarm_p3:
    'Ilmanlaadun hälytysrajoja voit hallita ottamalla laitteeseen yhteyden Safera App -mobiilisovelluksella.',
  i003_wifi_disconnected_p1:
    'Voit ottaa käyttöön etähälytyksen, jos laite ei pysty muodostamaan yhteyttä Wi-Fi -verkkoon ja Safera Cloud -palveluun.',
  i003_wifi_disconnected_p2:
    'Jos laite ei ole yhteydessä Wi-Fi -verkkoon ja Safera Cloud -palveluun:',
  device_status_ok_p1b:
    'on toimintakunnossa ja yhteydessä Wi-Fi -verkkoon ja Safera Cloud -palveluun normaalisti.',
  brand_name: 'Safera',
  site_name: 'Personal',
  full_brand_name: 'Safera Cloud Personal',
  brand_service_name: 'Safera Cloud Personal -pilvipalvelu',
  short_brand_name: 'Safera Cloud',
  by_app: 'Safera App -mobiilisovelluksella',
  apps_name: 'Safera-mobiilisovelluksesta',
  in_app: 'Safera-mobiilisovelluksessa',
  brand_company: 'Safera Oy',
  company_url: 'http://www.safera.com',
  wizard_cloud_title: 'Safera Cloud -palvelun käyttöönotto',
  wizard_cloud_log_in_heading:
    'Luo uusi tili tai kirjaudu sisään Safera Cloud -palveluun',
  wizard_cloud_connect_device_p1:
    'Anna laitteelle nimi, josta tunnistat sen Safera Cloud -pilvipalvelussa.',
  wizard_cloud_remote_alarms_p1:
    'Laite lähettää etähälytykset Safera Cloud -palvelun kautta. Vakioasetuksilla saat etähälytykset sähköpostilla lieden vaaratilanteista ja laitteen huoltotarpeesta.',
  wizard_cloud_connect_device_heading: 'Liitä laite Safera Cloud -tiliisi',
  wizard_cloud_ready_p2a:
    'Voit kirjautua palveluun joko Safera App -sovelluksen päävalikosta tai avaamalla palvelun internet-selaimella osoittessa:',
  wizard_cloud_ready_p2b: 'https://cloud.safera.com',
  terms_and_policy_url: 'https://safera.imagerelay.com/share/gdpr-eng',
  terms_url:
    'https://safera.imagerelay.com/share/warranty-and-license-terms?_ga=2.86347672.1200527814.1584089005-136302795.1582637603',
  wizard_cloud_connect_device_failed2_button:
    'Kirjaudu Safera Cloud -palveluun',
  wizard_cloud_connect_device_heading3:
    'Laite on nyt liitetty Safera Cloud -tiliisi',
  support_temp_error_url: 'https://safera.fi/guide/00',
  support_tof_error_url: 'https://safera.fi/guide/01',
  support_adc_error_url: 'https://safera.fi/guide/02',
  support_gas1_error_url: 'https://safera.fi/guide/03',
  support_gas2_error_url: 'https://safera.fi/guide/04',
  support_lpc_error_url: 'https://safera.fi/guide/05',
  support_orientation_error_url: 'https://safera.fi/guide/06',
  support_humidity_error_url: 'https://safera.fi/guide/07',
  support_invalid_sensor_orientation_url: 'https://safera.fi/guide/08',
  support_battery_low_error_url: 'https://safera.fi/guide/09',
  support_pcu_connection_error_url: 'https://safera.fi/guide/10',
  support_sensor_prosessor_error_url: 'https://safera.fi/guide/11',
  support_lens_dirty_error_url: 'https://safera.fi/guide/12',
  support_pcu_error_url: 'https://safera.fi/guide/13',
  support_pcu_voltage_error_url: 'https://safera.fi/guide/14',
  support_pcu_current_error_url: 'https://safera.fi/guide/15',
  support_pcu_lds_error_url: 'https://safera.fi/guide/16',
  support_pcu_prosessor_error_url: 'https://safera.fi/guide/17',
  support_pcu_twd_error_url: 'https://safera.fi/guide/18',
  support_pcu_power_supply_error_url: 'https://safera.fi/guide/19',
  support_pcu_relay_error_url: 'https://safera.fi/guide/20',
  support_pcu_overheating_error_url: 'https://safera.fi/guide/21',
  support_pcu_curmeas_diff_error_url: 'https://safera.fi/guide/22',
  support_pcu_curmeas_backup_error_url: 'https://safera.fi/guide/23',
  support_pcu_overcurrent_error_url: 'https://safera.fi/guide/24',
  support_pcu_z_jammed_error_url: 'https://safera.fi/guide/25',
  support_critical_error_pcu_shutdown_url: 'https://safera.fi/guide/26',
  support_offline_error_url: 'https://safera.fi/guide/27',
  support_acknowledging_an_alarm_url: 'https://safera.fi/guide/28',
  support_acknowledging_water_alarm_utl: 'https://safera.fi/guide/29',
  support_wifi_error_url: 'https://safera.fi/guide/44',
  support_home_page_url: 'https://support.safera.com/en/support/home',
  company_logo_url:
    'https://www.safera.com/wp-content/uploads/2020/10/safera-logo.png',
  company_linkedin_url: 'https://www.linkedin.com/company/safera-oy',
  company_facebook_url: 'https://www.facebook.com/stoveguard/',
  company_twitter_url: 'https://twitter.com/SAFERA_Oy',
  company_instagram_url: 'https://www.instagram.com/safera_oy/',
  company_support_email: 'support@safera.com',
  company_street_address: 'Safera Oy, Tekniikantie 4 B 02150 Espoo, Finland',
  smartthings_add_device_info_c: 'Valitse Safera'
};
