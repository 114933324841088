/**
 * Device list.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import Device from './Device/Device';
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import * as utils from 'utils';

export default function DeviceList({ setPage, user }) {
  const devices = useStoreState(state => state.devices);

  for (const dev of devices) {
    if (
      dev.smartthings != undefined &&
      dev.smartthings == utils.SMARTTHINGS_REGISTERED
    ) {
      user.isSmartThings = true;
    }
  }

  //console.log(devices);
  return (
    <>
      {devices.map(device => (
        <Device key={uuidv4()} device={device} setPage={setPage} user={user} />
      ))}
    </>
  );
}

DeviceList.propTypes = {
  setPage: PropTypes.func,
  devices: PropTypes.array,
  user: PropTypes.object
};
