import { PayPalButton } from 'react-paypal-button-v2';
import React from 'react';
import PropTypes from 'prop-types';
import * as utils from 'utils';

export function PayPalBtn(props) {
  const {
    amount,
    currency,
    createSubscription,
    onApprove,
    catchError,
    onError,
    onCancel
  } = props;

  return (
    <PayPalButton
      amount={amount}
      currency={currency}
      createSubscription={(data, details) => createSubscription(data, details)}
      onApprove={(data, details) => onApprove(data, details)}
      onError={err => onError(err)}
      catchError={err => catchError(err)}
      onCancel={err => onCancel(err)}
      options={{
        clientId: utils.PAYPAL_KEY,
        vault: true,
        intent: 'subscription'
      }}
      style={{
        shape: 'rect',
        color: 'blue',
        layout: 'horizontal',
        label: 'subscribe'
      }}
    />
  );
}
export default PayPalBtn;

PayPalBtn.propTypes = {
  amount: PropTypes.string,
  currency: PropTypes.string,
  createSubscription: PropTypes.func,
  catchError: PropTypes.func,
  onError: PropTypes.func,
  onApprove: PropTypes.func,
  onCancel: PropTypes.func
};
