import React from 'react';
import PropTypes from 'prop-types';
import Inbox from 'components/app/email/inbox/Inbox';
import EmailProvider from 'components/app/email/EmailProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import useTranslation from 'hooks/translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import * as utils from 'utils';
import { Route } from 'react-router-dom';
import EmailDetail from 'components/app/email/email-detail/EmailDetail';
import Compose from 'components/app/email/compose/Compose';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

export default function MessagePage({ setPage }) {
  const translation = useTranslation();

  const EmailRoutes = ({ match: { url } }) => (
    <EmailProvider>
      <Switch>
        <Route path={`${url}/email-detail`} exact component={EmailDetail} />
        <Route path={`${url}/inbox`} exact component={Inbox} />
        <Route path={`${url}/compose`} exact component={Compose} />
        {/*Redirect*/}
        <Redirect to="/errors/404" />
      </Switch>
    </EmailProvider>
  );
  EmailRoutes.propTypes = {
    match: PropTypes.object
  };

  return (
    <div className="device-settings-page h-100">
      <div className="settings-page-container container p-0 h-100 pb-md-3">
        <div className="container settings-container h-100">
          <div className="row hero-background-gradient h-100">
            <div>
              <div className="row sticky-top">
                <div className="col">
                  <div className="row justify-content-start flex-nowrap nav-bar-settings-page">
                    <div className="col-4 d-flex justify-content-start align-items-center settings-navbar-left-column overflow-hidden p-0">
                      <a
                        className="btn btn-link flex-shrink-0 text-white"
                        role="button"
                        onClick={() =>
                          setPage({
                            page: utils.ADVANCED_PAGES['DEVICE_LIST']
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="text-primary me-2"
                        />
                        {translation.back}
                      </a>
                    </div>
                    <div className="col-4 d-flex flex-grow-0 justify-content-center align-items-center p-0 ps-2">
                      <h4 className="text-nowrap text-start text-white m-0 p-0 lh-base">
                        Inbox
                      </h4>
                    </div>
                    <div className="col-4 d-flex flex-fill justify-content-end align-items-center"></div>
                  </div>
                </div>
              </div>

              <div className="row h-100 " style={{ overflow: 'auto' }}>
                <div className="col-auto d-inline-flex hero-settings-page w-100 p-0 py-3">
                  <div className="w-100">
                    <Router basename={process.env.PUBLIC_URL}>
                      <EmailProvider>
                        <Switch>
                          <Route path="/email" component={EmailRoutes} />
                          <Inbox />
                        </Switch>
                      </EmailProvider>
                    </Router>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MessagePage.propTypes = {
  setPage: PropTypes.func,
  pageSettings: PropTypes.object,
  db: PropTypes.object,
  user: PropTypes.object
};
