/**
 * Account settings.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import PayPalSubscription from './PayPalSubscription';
import useTranslation from 'hooks/translations';

export default function CloudPackage({ user }) {
  const translation = useTranslation();
  //console.log(user);
  return (
    <div className="container p-0 pb-3">
      <h6> {translation.service_plan}</h6>
      <div className="card shadow">
        <div className="card-body px-3 py-3 pb-3">
          <div className="d-flex mb-3 align-items-center w-100">
            <div className="flex-shrink-1 flex-grow-1 w-100">
              <div className="w-100">
                <div className="container p-0">
                  <div className="row flex-column flex-grow-0 flex-nowrap panel-item-heading-with-link mb-1">
                    <div className="col d-flex justify-content-center w-100 mt-2">
                      <h3 className="text-break mb-1">
                        {translation.full_brand_name}
                        <br />
                      </h3>
                    </div>
                    <div className="col-auto text-end w-100 mb-2">
                      <p className="d-flex justify-content-center mb-0">
                        {translation.free}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-center text-muted lh-sm fs--1">
                  {translation.free_plan_info_1}{' '}
                  {translation.brand_service_name}{' '}
                  {translation.free_plan_info_2}
                </p>
                <h6 className="mt-4">{translation.add_ons}</h6>
              </div>
              <hr className="bg-400 my-0" />
            </div>
          </div>
          <PayPalSubscription user={user} />
        </div>
      </div>
    </div>
  );
}

CloudPackage.propTypes = {
  user: PropTypes.object
};
