/**
 * Language English.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
//import ui_en from './ui_en';
import safera_en from './safera_en';
//import msg_en from './msg_en';
//import safera_msg_en from './safera_msg_en';

export default {
  ...safera_en,
  ...{
    m001_verification_button: 'Confirm your email address',
    m001_verification_modal_title: 'Email address verification',
    m001_verification_modal_content_p1a:
      'A verification message has now been sent to the e-mail address',
    m001_verification_modal_content_p1b: '-',
    m001_verification_modal_content_p2:
      'Follow the instructions in the email to verify the email address.',
    m002_name_change_content_p1b: '.',
    m003_number_change_heading: 'User account change',
    m004_new_device_subject_a: 'The device',
    m004_new_device_heading: 'A new device has been added to your user account',
    m004_new_device_content_bullet1:
      'Remote alarm messages so you can react quickly if needed.',
    m004_new_device_content_bullet2:
      'Notifications of possible device maintenance, so you can ensure that the device is always in working order.',
    m004_new_device_content_bullet3:
      "View of the history information sent to cloud by the device, so you can keep track of your device's alarm history, among other things.",
    m004_new_device_content_p2:
      "You can check or change your device's remote alarm and notification settings here:",
    m004_new_device_button: 'Open the device settings',
    m005_share_device_subject_a:
      'Confirm the transfer or sharing of the device ',
    m005_share_device_heading:
      'Confirm the transfer or sharing of the device to your user account',
    m005_share_device_p1b: 'wants to transfer or share the device ',
    m005_share_device_p1c: 'to your user account.',
    m006_add_device_reminder_heading:
      'Connect your Safera product to your Safera Cloud user account to enjoy the following benefits',
    m006_add_device_reminder_p1: 'It only takes a few minutes to add a device:',
    m006_add_device_reminder_button: 'Add a device to your user account',
    m007_thanks_for_order_subject:
      'Thank you for your subscription (free trial in progress) - SMS alarms',
    m007_thanks_for_order_heading:
      'Thanks for subscribing to the SMS service - a free trial is in progress',
    m007_thanks_for_order_p1a:
      'The free trial of SMS alarms is running and ending on ',
    m007_thanks_for_order_p1b: '.',
    m007_thanks_for_order_p2a:
      'After the trial period, the service will automatically continue as a subscription valid for the time being, and a monthly fee of ',
    m007_thanks_for_order_p2b:
      'will be charged for the service. You may terminate the service at any time after this time until the end of the current invoicing period. The monthly fee includes a maximum of 30 text messages per month without device restrictions.',
    m007_thanks_for_order_p3:
      "You can view and change your device's alarm settings here.",
    m007_thanks_for_order_service_heading: 'Subscribed service:',
    m007_thanks_for_order_service:
      'SMS alarms 1 month, subscription valid until further notice',
    m007_thanks_for_order_cost_heading: 'Monthly service fee:',
    m007_price: 'EUR 1.99/month',
    m007_thanks_for_order_number_heading: 'Order number:',
    m007_thanks_for_order_date_heading: 'Subscription date:',
    m007_thanks_for_order_how_to_cancel_heading:
      'How to cancel the subscription:',
    m007_thanks_for_order_terms_heading: 'Terms of service:',
    m007_thanks_for_order_support_heading: 'Product support:',
    m007_sms_thanks_for_order_p1b: 'enabled SMS alarms from ',
    m007_sms_thanks_for_order_p1c: '.',
    m007_sms_thanks_for_order_p2: 'Read more:',
    m008_trial_ending_subject:
      'Give feedback: Get a free extra month for the SMS alarms trial period',
    m008_trial_ending_heading:
      'The free trial is ending - Write a product review to get 1 extra month for free',
    m008_trial_ending_p1a:
      'The free trial period for SMS alarms will end in 7 days ',
    m008_trial_ending_p1b: '.',
    m008_trial_ending_p2: 'The following devices send remote alarms via SMS:',
    m008_trial_ending_device_list: 'to',
    m008_trial_ending_p3:
      'We hope you have been satisfied with the service and product. We appreciate any feedback you may have, so we hope you will share your experience with us and our other customers.',
    m008_trial_ending_p4a:
      'Write a product review and we will continue the free trial of SMS alarms until ',
    m008_trial_ending_p4b: ':',
    m008_trial_ending_button:
      'Write a product review - get 1 extra month for free',
    m009_trial_ending_subcription_continues_subject:
      'The free trial  for SMS alarms is ending - Your subscription will continue automatically',
    m009_trial_ending_subcription_continues_p3:
      'The free trial period for SMS alarms ends on [date].',
    m009_trial_ending_subcription_continues_p4a:
      'After the trial period, the subscription for the service will automatically continue as valid for the time being, and a monthly fee of ',
    m009_trial_ending_subcription_continues_p4b:
      'will be charged for the service you choose.',
    m009_sms_trial_ending_subscription_continues_p1a:
      'Safera Cloud - The free trial period for SMS alarms ends on ',
    m009_sms_trial_ending_subscription_continues_p1b:
      ', after which the subscription will automatically continue for the time being and a monthly fee of ',
    m009_sms_trial_ending_subscription_continues_p1c:
      'will be charged for the service.',
    m009_sms_trial_ending_subscription_continues_p2:
      'Manage your subscription:',
    m010_receipt_heading: 'Thank you for your payment',
    m010_receipt_p1: 'We have received your payment for:',
    m010_receipt_sum: 'Total:',
    m010_receipt_payment_date: 'Payment date:',
    m010_receipt_p2a:
      'SMS alarms will be available again until the end of the invoicing period ',
    m010_receipt_p2b:
      ', after which the next period will be billed automatically.',
    m010_receipt_payment_info_heading: 'Payment information:',
    m010_receipt_payment_info_content:
      'You can view your payment history or change your form of payment in the payment details section of your user account.',
    m011_sms_service_ending_subject: 'Subscription cancelled - SMS alarms',
    m011_sms_service_ending_heading: 'SMS alarms end on ',
    m011_sms_service_ending_p1a:
      'Subscription to SMS alarms has been cancelled. SMS alarms will no longer be available after the end of the current invoicing period ',
    m011_sms_service_ending_p1b: '.',
    m011_sms_service_ending_button: 'Subscribe to the SMS service for ',
    m011_sms_p1b: '.',
    m012_account_delete_heading:
      'A request to delete your Safera Cloud account is pending',
    m012_account_delete_p2:
      'You are welcome to return to Safera Cloud at any time by opening a new user account. Once the previous account has been deleted, you can also create a new one with the same email address you have used previously.',
    m013_personal_information_heading:
      'Your personal information stored in the Safera Cloud service is attached',
    m013_personal_information_p2:
      'The password you use to open the PDF file will be sent to you separately, either by email or SMS, to your mobile phone number if you have saved the number on our service.',
    m014_payment_failed_1_subject:
      'Update payment method - Your subscription for SMS alarms could not be charged',
    m014_payment_failed_1_heading: 'Payment failed',
    m014_payment_failed_1_p1b: 'SMS alarms EUR 1.99/month',
    m014_payment_failed_device_list: 'SMS alarms are ordered from devices:',
    m014_sms_failed_1_p2:
      'Log in and select the “payment details" of your user account:',
    m015_payment_failed_2_subject:
      'Update payment method - SMS alarms subscription expiring [date]',
    m015_payment_failed_2_heading:
      'Payment failed - Please update your payment information to resume service',
    m015_payment_failed_2_p2a:
      'If the payment method is not updated, SMS alarms will end on ',
    m015_payment_failed_2_p2b: 'devices:',
    m015_sms_payment_failed_2_p1b: 'unless the payment method is updated.',
    m015_sms_payment_failed_2_p2:
      'Log in and select the “payment details" of your user account:',
    m016_payment_ok_heading: 'Payment failed - SMS alarms service ended',
    m016_payment_ok_p1:
      'SMS alarms for the following devices have been discontinued because the SMS alarm service fee could not be charged:',
    m016_payment_ok_button: 'Subscribe to the SMS service for EUR 1.99/month',
    m017_payment_info_updated_heading: 'Payment information has been updated',
    m017_sms_payment_info_updated_p1:
      'The subscription for SMS alarms for Safera Cloud to this number has been renewed.',
    m018_replace_batteries_1_subject: 'Replace batteries - ',
    m018_replace_batteries_1_heading:
      'Replace batteries - The batteries will run out soon',
    m018_replace_batteries_1_p1b:
      'is low. Replace the batteries as soon as possible, see instructions here:',
    m018_replace_batteries_1_button: 'How to replace the batteries',
    m018_replace_batteries_1_p2:
      'If the batteries are completely empty, the device will prevent you from using the stove for safety reasons.',
    m018_replace_batteries_1_bullets_1:
      'The device does not send remote alarms.',
    messages_footer_v3:
      'If you do not want to receive these notifications, you can change the notification settings in the device settings.',
    m019_replace_batteries_2_heading:
      'Replace batteries - The device cannot connect to a Wi-Fi network',
    m019_replace_batteries_2_p1a: 'The battery level of the ',
    m019_replace_batteries_2_p1b:
      'is low or completely depleted, so the device cannot connect to the Wi-Fi network. Replace the batteries as soon as possible, see instructions here:',
    m019_sms_replace_batteries_p1a: 'SMS alarms from device ',
    m019_sms_replace_batteries_p2: 'See instructions:',
    m020_wifi_disconnected_subject: 'Fix Wi-Fi connection - ',
    m020_wifi_disconnected_p1a: 'Reconnect the device ',
    m020_wifi_disconnected_p1b:
      'to the Wi-Fi network as soon as possible, see instructions here:',
    m020_wifi_disconnected_button:
      'How to connect the device to a Wi-Fi network',
    m020_wifi_disconnected_p3: 'The device was last connected to Wi-Fi:',
    m020_wifi_disconnected_p4:
      'If you need further assistance, please contact our product support:',
    m021_wifi_ok_subject: 'Wi-Fi connection restored - ',
    m021_wifi_ok_p1:
      "If you're having recurring trouble with your Wi-Fi connection, please read the instructions here:",
    m021_wifi_ok_button: 'Wi-Fi connection troubleshooting',
    m021_sms: 'M019_SMS_replace_batteries_p2',
    m022_malfunction_subject: 'Fix malfunction - ',
    m022_malfunction_heading: 'Fix the malfunction',
    m022_malfunction_p1a: 'A malfunction has been detected in ',
    m022_malfunction_p1b: ':',
    m022_malfunction_button: 'See instructions for troubleshooting',
    m022_malfunction_p2:
      'If you need further assistance, please contact our product support and report this error code for a speedier service:',
    m022_sms_malfunction_p1b:
      'are disabled because the device has a malfunction.',
    m023_cooking_alarm_subject: 'Cooking alarm - ',
    m023_cooking_alarm_heading: 'Cooking alarm ',
    m023_cooking_alarm_p1b:
      'has detected a cooking hazard and switched off the stove’s electricity:',
    m023_cooking_alarm_p2:
      'To reconnect the electricity to the stove, press the OK button on the device.',
    m023_sms_cooking_alarm_p2:
      'The stove has been switched off. Press the OK button on the device to clear the alarm.',
    m024_cooking_alarm_cleared_subject: 'Cleared - Cooking alarm - ',
    m024_cooking_alarm_cleared_heading: 'The alarm is cleared',
    m024_cooking_alarm_cleared_p1a: 'The cooking alarm sent by ',
    m024_cooking_alarm_cleared_p1b: 'has been cleared:',
    m024_sms_cooking_alarm_cleared_p1: 'Cleared: Cooking alarm',
    m025_water_leak_alarm_subject: 'Water leak alarm - ',
    m025_water_leak_alarm_heading: 'Water leak alarm ',
    m025_water_leak_alarm_p1b: 'has detected a water leak:',
    m025_water_leak_alarm_p2:
      'Check the cause of the alarm and take the necessary follow-up action to avoid damage.',
    m025_water_leak_alarm_button: 'See alarm clearing instructions',
    m026_water_leak_cleared_subject: 'Cleared - Water leak alarm - ',
    m026_water_leak_cleared_p1a: 'The water leak alarm sent by device ',
    m026_water_leak_cleared_p1b: 'has been cleared:',
    m027_aq_alarm_subject: 'Air quality alarm limit exceeded - ',
    m027_aq_alarm_heading: 'Air quality alarm limit exceeded ',
    m027_aq_alarm_p1b:
      'has detected that the air quality alarm limit has been exceeded:',
    m027_aq_alarm_button: "View the device's air quality history",
    m027_aq_alarm_p4: 'What causes poor indoor air?',
    m027_aq_alarm_p5:
      'Find out the most common reasons for poor air quality in your home:',
    m027_aq_alarm_button2:
      'The most common causes of poor air quality in your home',
    m028_cooking_alarm_no_pcu_p1b: 'has detected a cooking hazard:',
    m028_cooking_alarm_no_pcu_p2:
      'Check what triggered the alarm and ensure that the hazardous situation is over. To clear the alarm, press the OK button on the device.',
    i001_cooking_remote_alarm_p1:
      'You can enable the remote alarm for cooking hazards.',
    i001_cooking_remote_alarm_p2:
      'The device will send a remote alarm when the device recognises a possible cooking hazard and sounds an alarm. The device will also send a notification when the user clears the alarm.',
    i002_battery_replacement:
      'You can enable remote notifications for low battery.',
    i004_air_quality:
      'You can enable remote notifications for the air quality of the room.',
    m029_air_quality_ok_again_subject:
      'Air quality returned to target level - ',
    m029_air_quality_ok_again_heading:
      'Air quality has been returned to target level',
    m029_air_quality_ok_again_p1b:
      'has detected that the air quality has returned to the target level and below the alarm limit:',
    m029_air_quality_ok_again_p2a: 'You can view the air quality history in ',
    m029_air_quality_ok_again_p2b: 'stored by your device:',
    m029_air_quality_ok_again_p3a:
      'You can control the air quality alarm limits by connecting to the device with the ',
    m029_air_quality_ok_again_p3b: ' mobile application.',
    m030_malfunction_fixed_subject: 'Malfunction fixed, device ok  - ',
    m030_malfunction_fixed_heading:
      'The device is operational again and the malfunction has been fixed',
    m030_malfunction_fixed_p1a: 'A malfunction has been fixed and the device ',
    m030_malfunction_fixed_p1b: 'is operational again.',
    m030_malfunction_multiple_errors: 'Multiple malfunctions',
    m030_sms_p1a: 'SMS alarms from ',
    m030_sms_p1b: 'are enabled again, and the device is operational again.',
    device_status_ok_heading: 'All good!',
    device_status_ok_p1a: 'The device',
    device_status_footer:
      'The device sends the data it has collected to the cloud service once per day at midnight. The device will send immediately all important remote alarms and notifications about maintenance needs.',
    saving_failed: 'Saving failed!',
    subscribe_to_sms_alarm_service: 'Subscribe to SMS alarm service',
    sms_alarms_service_payment_info_1:
      'SMS alarms is an additional service with a monthly fee. The service will automatically continue as a subscription valid for the time being, and a monthly fee of',
    sms_alarms_service_payment_info_2: 'will be charged for the service.',
    per_month: '/month',
    sms_service_terminate_info:
      'You can terminate the service at any time. The monthly fee includes a maximum of 30 text messages per month without device restrictions.',
    i_agree_to_the_terms_and_policy:
      'I agree to the Terms of Service and Privacy Policy',
    sms_service_try_30_days_for_free:
      'Subscribe to the SMS alarms service that you can try 30 days for free.',
    sms_service_try_30_days_for_free_info_1:
      'SMS alarms is an additional service which you may try 30 days for free. ',
    sms_service_try_30_days_for_free_info_2:
      'After the trial period, a monthly fee of ',
    sms_service_try_30_days_for_free_info_3:
      'will be charged for the service. You can terminate the service at any time. The monthly fee includes a maximum of 30 text messages per month without device restrictions.',
    sms_alarms_is_an_additional_service_1:
      'SMS alarms is an additional service with a monthly fee of ',
    sms_alarms_is_an_additional_service_2:
      'You may terminate the service at any time. Set up the alarms in device settings.',
    are_you_sure_cancel_sms_subscription:
      'Are you sure you want to cancel the SMS alarm subscription?',
    are_you_sure_cancel_sms_subscription_info:
      'If you cancel the subscription, the SMS alarms will no longer be available after the end of the current invoicing period',
    i_keep_my_subscription: 'Continue my subscription',
    cancel_my_subscription: 'Cancel my subscription',
    sms_alarms_are_now_enabled: 'SMS alarms are now enabled',
    following_devices_send_remote_alarms_via_sms:
      'The following devices send remote alarms via SMS',
    device_alarm_settings_info_1:
      "You can view and change your device's alarm settings by choosing the device card on the",
    device_alarm_settings_info_2: 'front page.',
    ok: 'OK',
    delete_user_account_be_sure:
      'Are you sure you want to delete your user account?',
    delete_user_account_info:
      'Your user account will be deleted within a week, after which you will no longer be able to log in to your account. All data related to the user account will be removed according to our Privacy Policy.',
    delete_user_account_info_2:
      'Once the user account has been deleted, it cannot be recovered.',
    delete_user_account_info_3_a: 'You are welcome to return to',
    delete_user_account_info_3_b:
      'at any time by opening a new user account. Once the previous account has been deleted, you can also create a new one with the same email address you have used previously.',
    cancel: 'Cancel',
    delete_user_account: 'Delete my user account',
    no_active_subscriptions: 'No active subscriptions',
    subscription_is_active: 'Subscription is active',
    add_on_sms_alarms: 'Add-on: SMS alarms',
    payment_method: 'Payment method',
    transactions: 'Transactions',
    sms_alarms_service_1_month: 'Add-on: SMS alarms service 1 month',
    signing_in_to_paypal: 'Edit by signing in to PayPal',
    print_receipt: 'Print receipt',
    email_receipt: 'Email receipt',
    privacy_policy: 'Privacy Policy',
    cookie_policy: 'Cookie Policy',
    necessary_cookies: 'Necessary cookies',
    necessary_cookies_info:
      'Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.',
    performance_cookies: 'Performance cookies',
    performance_cookies_info:
      'Performance cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc.',
    remote_alarm_settings: 'Remote alarm settings',
    history_view: 'History view',
    device_information: 'Device information',
    rename_the_device: 'Rename the device',
    enter_new_name: 'Enter new name',
    remove_the_device: 'Disconnect the device',
    recipient_of_remote_alarms: 'Recipient of remote alarms',
    device_settings: 'Device settings',
    my_account: 'My account',
    device_remote_alarm_settings: 'Remote alarm settings for the device',
    remote_alarm_cooking_hazard: 'Remote alarm for cooking hazard',
    email: 'Email',
    sms: 'SMS',
    sms_alarms_enabled_in_part_a: 'SMS alarms enabled in',
    sms_alarms_enabled_in_part_b: 'devices',
    additional_alarms: 'Additional alarms',
    alarm_air_quality_exceeds:
      'Remote alarm for air quality exceeds the alarm limits',
    alarm_air_quality_info_1:
      'The device constantly measures the air quality of the room (depending on the product: room temperature, humidity, PM2.5, TVOC and eCO2). You can set the air quality alarm limits in the Air Quality tab',
    alarm_air_quality_info_2: 'and enable the email alarm above.',
    remote_alarm_for_battery_replacement:
      'Remote alarm for battery replacement',
    remote_alarm_for_wifi_and_offline:
      'Remote alarm for Wi-Fi and Cloud disconnection',
    remote_alarm_for_device_errors:
      "Remote alarm for the device's maintenance need",
    more_information: 'More information',
    time_period: 'Time period',
    full_screen: 'Full screen',
    graphs: 'Graphs',
    air_quality: 'Air quality',
    cooking_event: 'Cooking event',
    alarm: 'Alarm',
    temperature: 'Temperature',
    humidity: 'Humidity',
    event_list: 'Event list',
    filter_events: 'Filter events',
    alarms: 'Alarms',
    error_messages: 'Error messages',
    other: 'Other',
    date: 'Date',
    event: 'Event',
    installation_settings: 'Installation settings',
    stove_type: 'Stove type',
    stove_width: 'Stove width',
    mounting_position: 'Mounting position',
    pcu: 'Power Controlling Unit (PCU)',
    installation_height: 'Installation height',
    stove_wiring: 'Stove wiring',
    installation_settings_info_1:
      'You can manage the device’s installation settings by choosing “Installation Wizard” in the ',
    installation_settings_info_2: 'main menu.',
    information: 'Information',
    device: 'Device',
    device_id: 'Device ID',
    fw_version: 'FW version',
    hw_version: 'HW version',
    mechanical_variant: 'Mechanical variant',
    hw_id_minor: 'HW ID Minor',
    mechanics_version: 'Mechanics version',
    device_status: 'Device status',
    notification: 'Notification:',
    welcome: 'Welcome!',
    log_in_or_sign_up: 'Log in or Sign up',
    continue_with_google: 'Continue with Google',
    continue_with_email: 'Continue with email',
    continue_with_facebook: 'Continue with Facebook',
    agree_terms_and_policy:
      'By using the service, you agree to our Terms of Service and Privacy Policy.',
    confirm_the_removal_of_the_device: 'Confirm the removal of the device',
    remove_device_be_sure: 'Are you sure you want to remove the device:',
    remove_device_info_1:
      'If you remove the device, you cannot view it’s history log and the device will not send any remote alarms. The removal cannot be undone.',
    remove_device_info_2:
      'After removal, you may connect the device to your user account as a new device using the',
    cancel_device_remove: 'Do not remove the device',
    remove_device: 'Remove the device',
    device_removed: 'Device removed',
    sms_alarms_enabled_info_1:
      'Choose this if you want to enable SMS alarms only to the device',
    sms_alarms_enabled_info_2:
      'You can also manage the remote alarm settings for each device separately later if needed.',
    cancel_payment_1: 'Are you sure you want to unsubscribe from SMS alerts?',
    cancel_payment_2:
      'If you unsubscribe, SMS alerts will no longer be available after the current billing period.',
    cancel_payment_3: 'Continue your order',
    cancel_payment_4: 'Unsubscribe',
    phone_number_missing: 'Phone number missing!',
    order_id: 'Order ID',
    order_date: 'Order date',
    order_type: 'Order type',
    order_status: 'Order status',
    order_plan_id: 'Order plan ID',
    subscriber: 'Subscriber',
    amount: 'Amount',
    status: 'Status',
    wizard_cloud_welcome_text:
      'You are registering your device in Safera Cloud. You can now go to the login page.',
    wizard_cloud_log_in_step: 'Step 1/3',
    wizard_cloud_logged_in_heading: 'You are logged in as',
    wizard_cloud_next_button: 'Next',
    wizard_cloud_previous_button: 'Return to the previous step',
    wizard_cloud_logged_log_out_button: 'Use another account',
    wizard_cloud_new_account_heading: 'Your new user account',
    wizard_cloud_connect_device_step: 'Step 2/3',
    wizard_cloud_connect_device_input_label: 'Device name',
    wizard_cloud_connect_device_input_placeholder: 'Name the device',
    wizard_cloud_remote_alarms_step: 'Step 3/3',
    wizard_cloud_remote_alarms_heading:
      'Set up the remote alarms and you are ready',
    wizard_cloud_remote_alarms_p2:
      'You may also enable SMS alarms for cooking hazards.',
    wizard_cloud_remote_alarms_button:
      "Open the device's remote alarm settings",
    wizard_cloud_device_settings_button: 'Finished',
    wizard_cloud_ready_heading: 'Ready!',
    wizard_cloud_ready_p1: 'Remote alarms are now enabled for the device',
    wizard_cloud_connect_device_failed_heading: 'Connecting the device failed',
    wizard_cloud_connect_device_failed1_p1:
      'Devices can only be connected to one user account at a time. This device is already connected to another account. Please see instructions here:',
    wizard_cloud_connect_device_failed_button: 'Go back',
    wizard_cloud_connect_device_failed2_p1:
      'The device has been connected to your user account earlier. Please log in to Safera Cloud to see the device status and settings.',
    wizard_cloud_connect_device_failed3_p1:
      'The device cannot be connected. Please see the instructions here:',
    wizard_cloud_connect_device_failed4_p1:
      'Connecting the device failed. Please try again.',
    wizard_cloud_connect_device_failed4_button: 'Try again',
    wizard_cloud_connect_device_failed5_p1:
      'Connecting the device failed, because the device is not connected to the internet. Please check the device’s Wi-Fi settings of the device. Please see the instructions here:',
    wizard_cloud_connect_device_heading2:
      'Connecting the device to your account...',
    list_previous: 'Previous',
    list_rows: 'Show:',
    list_to: 'to',
    list_of: 'of',
    accept: 'Accept',
    skip: 'Skip',
    change: 'Change',
    activate_subscription: 'Activate subscription',
    in_progress: 'In progress',
    add_phonenumber: 'Add phone number',
    add_name: 'Add name',
    save_name: 'Save name',
    events: 'Events',
    n_a: 'N/A',
    stove_alarm: 'Stove Guard Alarm',
    stove_alarm_pcu: 'Stove Guard Alarm, power disconnected',
    cooking_action_start: 'Cooking action start.',
    cooking_action_stop: 'Cooking action stop.',
    ok_button: 'Ok button is pressed.',
    button_1: 'Button 1 has been pressed.',
    button_2: 'Button 2 has been pressed.',
    factory_reset: 'Factory reset done.',
    cloud_connection: 'Cloud connection test done.',
    stove_unused: 'Stove unused for too long.',
    cooked_unusual_time: 'Cooked unusual time',
    low_battery: 'Low battery level.',
    critical_error: 'Critical error',
    water_leakage: 'Water Leakage Sensor detects moisture.',
    water_alarm_over: 'The leak alarm is over.',
    fire_extinguishing: 'CTU has been used for fire extinguishing.',
    factory_connection_test: 'Factory connection test.',
    manual_connection_test: 'Manual connection test via Safera App',
    air_quality_cleared: 'The air quality has returned to acceptable level',
    power_shut_down: 'Power shut down due to critical (MIU) error.',
    critical_error_cleared: 'The error situation is over.',
    low_temperature: 'Low air temperature',
    high_temperature: 'High air temperature',
    low_humidity: 'Low humidity',
    high_humidity: 'High humidity',
    pm25_high: 'High pm2.5',
    air_quality_alarm: 'Air quality alarm.',
    high_eco2: 'High eCO2',
    high_tvoc: 'High tVOC',
    high_aqi: 'High combined Air Quality Index',
    day_log_received: 'Day log received',
    stove_alarm_cleared: 'Stove alarm cleared',
    device_reboot: 'Device rebooted.',
    stove: 'Stove',
    oven: 'Oven',
    ceramic: 'Ceramic',
    induction: 'Induction',
    iron: 'Iron',
    iron_coil: 'Iron coil',
    gas: 'Gas',
    unknown: 'Unknown',
    not_installed: 'Not installed',
    installed: 'Installed',
    wall: 'Wall',
    hood: 'Hood',
    yesterday: 'Yesterday',
    last_7_days: 'Last 7 Days',
    last_30_days: 'Last 30 Days',
    this_month: 'This Month',
    previous_month: 'Previous month',
    apply: 'Apply',
    custom_range: 'Custom Range',
    miu_error_exc: 'Temperature Sensor Error',
    miu_error_tof: 'Distance Sensor Error',
    miu_error_adc: 'Analog Sensor Error',
    miu_error_801: 'Gas Sensor 1 Error',
    miu_error_zmod: 'Gas Sensor 2 Error',
    miu_error_lpc: 'Particle Sensor Error',
    miu_error_mma: 'Orientation Sensor Error',
    miu_error_hum: 'Humidity Sensor Error',
    miu_error_tilt: 'Invalid Sensor Unit Orientation',
    miu_error_bat_low: 'Sensor Unit Battery Very Low',
    miu_error_pcu_radio: 'Power Control Unit Radio Communication Error',
    miu_error_mcu: 'Sensor Unit Main Processor Error',
    miu_error_lens_dirty: 'Sensor Lenses Dirty',
    pe_voltmeas: 'AC voltage metering fault',
    pe_curmeas: 'Current metering fault',
    pe_watermeas: 'LDS metering fault',
    pe_mcu: 'MCU/electronics problem',
    pe_tempwd: 'Temperature watchdog chip fault',
    pe_psu: 'PCU power supply failure (too low voltage or too much ripple)',
    pe_relay:
      'PCU 3.5.16-3.5.17 will report this on after ~10 relay operations',
    pe_overheat:
      'Temperature watchdog is triggered and the PCU has overheated, stove disconnected',
    pe_curmeas_diff:
      'Main and backup current calculation methods give different results',
    pe_curmeas_backup: 'Backup current calculation method in use',
    pe_overcur: 'Overcurrent disconnect triggered',
    pe_mma_z_jammed:
      'MMA acceleration sensor Z axis jammed, trying to cope with the safety backup.',
    contact_information: 'Contact information',
    plans_and_subscriptions: 'Plans and subscriptions',
    billing: 'Billing',
    privacy: 'Privacy',
    sign_out: 'Sign out',
    back: 'Back',
    user_account: 'User account',
    name: 'Name',
    mobile_phone: 'Mobile phone',
    email_address: 'Email address',
    password: 'Password',
    language: 'Language',
    edit: 'Edit',
    enter_users_name: 'Enter user’s name',
    eg_first_name_last_name: 'E.g. First name Last name',
    save: 'Save',
    enter_users_phone_number: 'Enter user’s phone number',
    mobile_format: 'Format +358 00 000 0000',
    please_enter_the_mobile_number_in_format:
      'Please enter the mobile number in format:',
    enter_your_mobile_number: 'Enter your mobile number',
    mobile_number: 'Mobile number',
    choose_language: 'Choose language',
    change_your_account_email: 'Change your account email',
    new_email_address: 'New email address',
    please_use_only_letters_and_numbers: 'Please use only letters and numbers.',
    characters_that_are_not_allowed_are_removed:
      'Characters that are not allowed are removed.',
    service_plan: 'Service plan',
    free: 'Free',
    free_plan_info_1: 'The free plan',
    free_plan_info_2:
      "includes remote alarms by email and device history view, so you can keep track of your device's alarm history, among other things.",
    add_ons: 'Add-ons',
    sms_alarms: 'SMS alarms',
    disabled: 'Disabled',
    enabled: 'Enabled',
    try_30_days_for_free_1: 'Try 30 days',
    try_30_days_for_free_2: 'for free',
    sms_service_info_1:
      'SMS alarms is an additional service which you may try 30 days for free. After the trial period, a monthly fee of',
    sms_service_info_2: 'will be charged for the service. ',
    sms_service_info_3:
      'You may terminate the service at any time. The monthly fee includes a maximum of 30 text messages per month without device restrictions.',
    start_30_day_free_trial: 'Start 30-day free trial',
    cancel_free_trial: 'Cancel free trial',
    free_trial_ends_in_1: 'Free trial ends in',
    free_trial_ends_in_2: 'days',
    cancel_the_subscription: 'Cancel the subscription',
    sms_alarms_info_1:
      'SMS alarms is an additional service with a monthly fee of',
    terms_of_service: 'Terms of Service',
    open: 'Open',
    changes_saved: 'Changes saved',
    power_battery_chart_title_a: 'Power and battery',
    power_battery_chart_title_b: 'Power',
    power_battery_chart_title_c: 'Battery',
    power_chart_total: 'Total:',
    cancel_smartthing_link_remove: 'Do not unlink SmartThings',
    remove_smartthings_link: 'Unlink SmartThings',
    smartthings_add_device_info_a:
      'After linking, you must add the device to the SmartThings App.',
    smartthings_add_device_info_b: 'Add a new device from the SmartThings App.',
    smartthings_add_device_info_d:
      'Now you can add the device with the SmartThings App.',
    smartthings_add_device_button: 'Create a link to the SmartThings',
    smartthings_remove_device_info:
      'After unlinking, you must also remove the device from the SmartThings App.',
    smartthings_add_dropdown: 'Add device link to SmartThings',
    device_name: 'Device name',
    group_name: 'Group name',
    message_share_details:
      'After accepting the invitation, you can see the device information and device data.',
    message_share_done_details: 'You have already accepted the invitation.',
    message_share_group_details:
      "After accepting the invitation, you get read access to the group's device information and device data.",
    message_transfer_ownership_details:
      'After accepting the invitation, you become the new owner of the device.',
    message_transfer_ownership_done_details:
      'You have already accepted the ownership.',
    message_transfer_group_ownership_details:
      'After accepting the invitation, you become the owner of all the devices in the group.',
    message_share_header: 'wants to share the device with you.',
    message_share_group_header: 'wants to share the group with you.',
    message_ownership_header: 'wants to give you ownership of the device.',
    message_group_ownership_header_a:
      'wants to give you ownership of the group. ',
    message_group_ownership_header_b:
      'All devices in the group will be transferred to your ownership.',
    message_share_title: 'Device share',
    message_group_title: 'Group share',
    message_ownership_title: 'Transfer of ownership',
    email_verification_button: 'Send verification email',
    email_verification_message_a:
      'Your email address has not been verified. Please verify your email address first.',
    email_verification_message_b1:
      'You have now been sent a verification email to ',
    email_verification_message_b2:
      'Check your email. When the verification is complete, press the "Verification done" button.',
    email_verification_done_button: 'Verification done'
  }
};
