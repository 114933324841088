/**
 * Device list header.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from '../../hooks/translations';

export default function DeviceListHeader({ opacity }) {
  const translation = useTranslation();

  return (
    <div className={opacity ? 'deviceTitlefadeOut' : 'deviceTitlefadeIn'}>
      <h1 className="text-nowrap text-white">{translation.brand_name} Cloud</h1>
      <h4 className="text-nowrap text-primary">{translation.site_name}</h4>
    </div>
  );
}

DeviceListHeader.propTypes = {
  opacity: PropTypes.bool
};
