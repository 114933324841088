/**
 * Device type image.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../../utils';

export default function DeviceTypeImage(hwVer, mechVariant, hwId) {
  if (hwVer == undefined || hwVer == 0) {
    hwVer = getHWVersion(hwId);
  }
  //console.log(hwVer, mechVariant);
  if (
    hwVer == utils.SENSE_HW_MAJOR['CR'] ||
    hwVer == utils.SENSE_HW_MAJOR['DCVK'] ||
    hwVer == utils.SENSE_HW_MAJOR['DCVK_HUB']
  ) {
    return 'integral';
  } else if (hwVer == utils.SENSE_HW_MAJOR['SENSE']) {
    if (mechVariant == utils.SENSE_MECHANICAL_VARIANT['BLACK_BLACK']) {
      return 'pro';
    } else {
      return 'wifi';
    }
  } else {
    return 'wifi';
  }

  function getHWVersion(hwId) {
    var hex = hwId !== undefined ? hwId.toString(16) : 0;
    hex = '0'.repeat(8 - hex.length) + hex;
    var hwIdBytes = [];

    for (var c = 0; c < hex.length; c += 2) {
      hwIdBytes.push(parseInt(hex.substring(c, c + 2), 16));
    }

    return hwIdBytes[1] !== undefined ? hwIdBytes[1] : 0;
  }
  // var deviceType = '';
  // // 1=Sense BT, 67=Sense Wi-Fi, 127=Sense Pro, 255=Sense CR Pro
  // switch (capability) {
  //   case utils.SENSE_HW_CAPABILITIES['BT']:
  //     deviceType = 'wifi';

  //     break;
  //   case utils.SENSE_HW_CAPABILITIES['WIFI']:
  //     deviceType = 'wifi';

  //     break;
  //   case utils.SENSE_HW_CAPABILITIES['PRO']:
  //     deviceType = 'pro';

  //     break;
  //   case utils.SENSE_HW_CAPABILITIES['CR_PRO']:
  //     deviceType = 'integral';
  //     break;
  //   default:
  //     deviceType = 'wifi';
  // }
  // return deviceType;
}
