/**
 * Read paypal subscriptions.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import * as utils from 'utils';

export async function ReadPayPalSubscriptions(db, uid) {
  try {
    // console.log(db);
    // console.log(uid);
    var subsData = [];
    const usrPayPalRef = db.collection(
      utils.USERS_KEY + '/' + uid + '/' + utils.SMS_SUBSCRIPTION_KEY
    );
    const usrPayPalDoc = await usrPayPalRef.get();
    for (const doc of usrPayPalDoc.docs) {
      var subscriptionData = doc.data();
      subscriptionData['order_id'] = doc.id;
      subscriptionData['history'] = [];
      const usrPayPalPaymentRef = db
        .collection(
          utils.USERS_KEY + '/' + uid + '/' + utils.SMS_SUBSCRIPTION_HISTORY_KEY
        )
        .where('order_id', '==', doc.id);
      const usrPayPalPaymentDoc = await usrPayPalPaymentRef.get();
      for (const paymentDoc of usrPayPalPaymentDoc.docs) {
        subscriptionData['history'].push(paymentDoc.data());
      }
      subsData.push(subscriptionData);
    }
    subsData.sort((a, b) => (a.create_time > b.create_time ? 1 : -1));

    return subsData;
  } catch (error) {
    console.error(error);
    return [];
  }
}
