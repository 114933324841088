/**
 * device registration.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';

export default async function DeviceRegistration(db, deviceInfo, user) {
  try {
    console.log(deviceInfo);
    var validDeviceObj = {
      valid: false,
      deviceExists: false,
      registrationAvailable: false,
      deviceAvailable: false,
      registrationDone: false
    };
    const eventRef = db
      .collection(
        utils.DEVICE_KEY + '/' + deviceInfo.deviceId + '/' + utils.EVENT_KEY
      )
      .limit(1);
    const eventDoc = await eventRef.get();
    //console.log(eventDoc);
    if (eventDoc.docs.length > 0) {
      validDeviceObj.deviceExists = true;
      validDeviceObj = await isAlreadyRegistered(validDeviceObj);
    } else {
      const dayRef = db.collection(
        utils.DEVICE_KEY + '/' + deviceInfo.deviceId + '/' + utils.DAYLOG_KEY
      );
      const dayDoc = await dayRef.get();
      if (dayDoc.docs.length > 0) {
        validDeviceObj.deviceExists = true;
        validDeviceObj = await isAlreadyRegistered(validDeviceObj);
      }
    }

    var retVal = false;
    if (
      validDeviceObj.deviceExists &&
      validDeviceObj.registrationAvailable &&
      validDeviceObj.deviceAvailable
    ) {
      retVal = await registerDevice();
    }
    if (retVal) {
      validDeviceObj.registrationDone = true;
      return validDeviceObj;
    } else {
      return validDeviceObj;
    }
  } catch (error) {
    console.error(error);
    return validDeviceObj;
  }

  async function isAlreadyRegistered(validDeviceObj) {
    try {
      const userDevRef = db
        .collection(utils.USERS_KEY + '/' + user.uid + '/' + utils.DEVICE_KEY)
        .where('device_id', '==', deviceInfo.deviceId);
      const userDevDoc = await userDevRef.get();
      var isUserAlreadyRegisteredDevice = false;
      for (const doc of userDevDoc.docs) {
        if (doc.data().isRegistered) {
          console.log('User device already registered.');
          isUserAlreadyRegisteredDevice = true;
        }
      }
      if (!isUserAlreadyRegisteredDevice) {
        validDeviceObj.registrationAvailable = true;
        const devRef = db.collection(
          utils.DEVICE_KEY + '/' + deviceInfo.deviceId + '/' + utils.USERS_KEY
        );
        const devDoc = await devRef.get();
        var isDeviceAlreadyInUse = false;
        for (const doc of devDoc.docs) {
          if (doc.data().isRegistered) {
            if (doc.id != user.uid) {
              console.log('Device already in use');
              isDeviceAlreadyInUse = true;
            }
          }
        }
      }
      if (!isDeviceAlreadyInUse) {
        validDeviceObj.valid = true;
        validDeviceObj.deviceAvailable = true;
        return validDeviceObj;
      } else {
        return validDeviceObj;
      }
    } catch (error) {
      console.error(error);
      return validDeviceObj;
    }
  }

  async function registerDevice() {
    try {
      const usrDevRef = db
        .collection(utils.USERS_KEY + '/' + user.uid + '/' + utils.DEVICE_KEY)
        .doc(deviceInfo.deviceId);
      await usrDevRef.set({
        device_id: deviceInfo.deviceId,
        isGuest: false,
        isRegistered: true,
        name: deviceInfo.deviceName,
        registered_timestamp: new Date().getTime(),
        isAlarm_notify_enabled: false,
        isAlarm_sms_notify_enabled: false,
        isBattery_notify_enabled: false,
        isBattery_sms_notify_enabled: false,
        isError_notify_enabled: false,
        isError_sms_notify_enabled: false,
        isOffline_notify_enabled: false,
        isOffline_sms_notify_enabled: false,
        isPoor_air_quality_notify_enabled: false,
        isPoor_air_quality_sms_notify_enabled: false
      });

      const devUsrRef = db
        .collection(
          utils.DEVICE_KEY + '/' + deviceInfo.deviceId + '/' + utils.USERS_KEY
        )
        .doc(user.uid);
      await devUsrRef.set({
        username: user.displayName,
        email: user.email,
        phone_number: user.phoneNumber,
        isBanned: false,
        isRegistered: true,
        registered_timestamp: new Date().getTime(),
        isAdmin: false,
        isOwner: true
      });

      const devRef = db.collection(utils.DEVICE_KEY).doc(deviceInfo.deviceId);
      console.log('Major: ', deviceInfo.config.hw_major);
      await devRef.set(
        {
          serial_number:
            deviceInfo.config?.serial != undefined
              ? deviceInfo.config.serial
              : '',
          bootloader:
            deviceInfo.config?.bootloader != undefined
              ? deviceInfo.config.bootloader
              : '',
          analytics_id:
            deviceInfo.config?.analytics_id != undefined
              ? deviceInfo.config.analytics_id
              : '',
          hw_version:
            deviceInfo.config?.hw_major != undefined
              ? parseInt(deviceInfo.config.hw_major)
              : 0
        },
        { merge: true }
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
