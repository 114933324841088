import React, { useEffect } from 'react';
import Login from './components/Login';
//import ReactGA from 'react-ga4';

function App() {
  //const TRACKING_ID = 'UA-156704274-3'; // test
  useEffect(() => {
    console.log('Tracking...');

    // ReactGA.send({
    //   hitType: 'pageview',
    //   page: window.location.pathname
    // });
  }, []);
  return <Login />;
}

export default App;
