import * as utils from '../../../utils';

export default async function ReadOwner(db, deviceId) {
  try {
    const usrRef = db
      .collection(utils.DEVICE_KEY + '/' + deviceId + '/' + utils.USERS_KEY)
      .where('isOwner', '==', true)
      .where('isRegistered', '==', true);
    const ownerDoc = await usrRef.get();
    var owner = '';
    for (const doc of ownerDoc.docs) {
      if (!doc.data().isBanned) {
        owner = {
          id: doc.id,
          email: doc.data().email,
          registeredTime: doc.data().registered_timestamp
        };
      }
    }
    return owner;
  } catch (err) {
    console.error(err);
    return;
  }
}
