/**
 * Select device to SmartThings
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as utils from 'utils';
import { Form } from 'react-bootstrap';
import useTranslation from 'hooks/translations';

export default function SelectDevice({
  db,
  user,
  urlResult,
  auth,
  firebaseSignout
}) {
  const translation = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [deviceArr, setDevice] = useState([]);

  var deviceObj = {};
  var state = '';
  var callbackUrl = '';
  if (urlResult.has('state')) {
    state = urlResult.get('state');
  }
  if (urlResult.has('callback')) {
    callbackUrl = urlResult.get('callback');
  }
  console;
  useEffect(() => {
    authenticated(user);
  }, []);

  function signout() {
    firebaseSignout(auth);
  }

  async function callback() {
    try {
      if (user.uid == '') {
        return Promise.resolve(false);
      }
      setDisabled(true);
      console.log(deviceObj);
      for (let deviceId in deviceObj) {
        if (deviceObj[deviceId].checked) {
          console.log(deviceObj[deviceId].docId);
          const usrDevRef = db
            .collection(
              utils.USERS_KEY + '/' + user.uid + '/' + utils.DEVICE_KEY
            )
            .doc(deviceObj[deviceId].docId);

          await usrDevRef.set(
            {
              smartthings: 'activated'
            },
            { merge: true }
          );
          const devRef = db.collection(utils.DEVICE_KEY).doc(deviceId);
          await devRef.set(
            {
              smartthings: true
            },
            { merge: true }
          );
        }
      }
      console.log('Updated');
      const usrRef = db.collection(utils.USERS_KEY).doc(user.uid);

      await usrRef.set(
        {
          smartthings_state: state
        },
        { merge: true }
      );
      window.location.assign(
        callbackUrl + '?code=' + user.uid + '&state=' + state
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function authenticated(user) {
    const usrDevRef = db
      .collection(utils.USERS_KEY + '/' + user.uid + '/' + utils.DEVICE_KEY)
      .where('isRegistered', '==', true)
      .where('isGuest', '==', false);
    const usrDevDoc = await usrDevRef.get();
    var checkArr = [];
    for (const doc of usrDevDoc.docs) {
      // if (
      //   doc.data().smartthings == undefined ||
      //   doc.data().smartthings != 'registered'
      // ) {
      checkArr.push({
        deviceId: doc.data().device_id,
        name: doc.data().name,
        docId: doc.id
      });
      // }
    }
    if (checkArr.length <= 0) {
      //cbContent = 'No devices found.';
      setDisabled(true);
    }
    setDevice(checkArr);
  }

  function updateCheck(e, deviceId, docId) {
    deviceObj[deviceId] = { checked: e.target.checked, docId: docId };
    console.log(deviceObj);
  }

  return (
    <>
      <div className="d-flex flex-column device-settings-page">
        <div className="container d-flex flex-fill justify-content-center align-items-center align-items-sm-center log-in-container">
          <div className="row d-flex justify-content-center log-in-panel bg-100 rounded-3 shadow-lg">
            <div className="col-auto d-flex justify-content-center p-3 pt-5 pt-sm-4 pb-0 pb-sm-4 w-100 w-sm-auto">
              <div className="row d-flex flex-column align-items-center log-in-welcome-panel h-25 m-0 h-md-100 w-100">
                <div className="col-6 d-flex flex-column flex-fill justify-content-between align-items-center w-100">
                  <div className="row w-100 mb-3">
                    <div className="col p-0">
                      <div className="d-flex justify-content-center justify-content-sm-start mb-2">
                        <img
                          className="log-in-logo"
                          src="img/Safera-logo-cloud.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-inline-flex flex-column flex-fill m-0 w-auto">
                    <div className="col d-flex align-items-end w-auto p-0">
                      <h2 className="text-nowrap text-center text-sm-start w-auto mb-1">
                        {translation.short_brand_name}
                      </h2>
                    </div>
                  </div>
                  <div className="row d-flex flex-fill w-100 mb-sm-4 mb-0">
                    <div className="col w-100 p-0">
                      <h5 className="text-center text-sm-start text-primary w-100">
                        {translation.welcome}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center log-in-continue-with-panel p-3">
              <div className="row w-100">
                <div className="col w-100">
                  <hr className="horizontal-divider w-100" />
                </div>
              </div>
              <div className="row d-flex w-100 my-3">
                <div className="col d-flex flex-column justify-content-end align-items-start">
                  <h3 className="text-center w-100">
                    Select the devices you want to connect to the SmartThings.
                  </h3>
                </div>
              </div>
              <div className="row bg-white h-100">
                <div className="col-12 mt-5">
                  <div className="col d-flex justify-content-center">
                    <div className="">
                      <Form>
                        {deviceArr.map(deviceObj => (
                          <div
                            key={`default-${deviceObj.deviceId}`}
                            className="mb-3"
                          >
                            <Form.Check
                              type="checkbox"
                              onChange={e =>
                                updateCheck(
                                  e,
                                  deviceObj.deviceId,
                                  deviceObj.docId
                                )
                              }
                              id={`default-${deviceObj.deviceId}`}
                              label={deviceObj.name}
                            />
                          </div>
                        ))}
                      </Form>
                      <br />
                      <div id="debug"></div>
                      <div
                        className="text-center"
                        id="deviceRegistrationModalFooter"
                      >
                        <button
                          type="button"
                          id="doneButton"
                          onClick={callback}
                          className="btn btn-success mb-3"
                          disabled={disabled}
                        >
                          Done
                        </button>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col d-flex justify-content-center mt-2">
              <div>
                <p>
                  {user.email} <br></br>
                  <a onClick={signout} href="#">
                    Change account
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SelectDevice.propTypes = {
  user: PropTypes.object,
  db: PropTypes.object,
  urlResult: PropTypes.object,
  auth: PropTypes.object,
  firebaseSignout: PropTypes.func
};
