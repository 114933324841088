/**
 * Language Finnish.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import safera_fi from './safera_fi';
//import ui_fi from './ui_fi';
// import msg_fi from './msg_fi';
// import safera_msg_fi from './safera_msg_fi';

export default {
  ...safera_fi,
  ...{
    m001_verification_button: 'Vahvista sähköpostiosoitteesi',
    m001_verification_modal_title: 'Sähköpostin vahvistaminen',
    m001_verification_modal_content_p1a: 'Sähköpostiosoitteeseen',
    m001_verification_modal_content_p1b: 'on nyt lähetetty varmennusviesti.',
    m001_verification_modal_content_p2:
      'Seuraa sähköpostiin saapuneita ohjeita varmentaaksesi sähköpostiosoite.',
    m002_name_change_content_p1b: '.',
    m003_number_change_heading: 'Käyttäjätilin muutos',
    m004_new_device_subject_a: 'Laite',
    m004_new_device_heading: 'Uusi laite on lisätty käyttäjätiliisi',
    m004_new_device_content_bullet1:
      'Etähälytykset vaaratilanteista, jotta voit reagoida niihin nopeasti.',
    m004_new_device_content_bullet2:
      'Ilmoitukset laitteen mahdollisesta huoltotarpeesta, joten voit varmistaa että laite on aina toimintakunnossa.',
    m004_new_device_content_bullet3:
      'Laitteen tallentamien historiatietojen tarkastelu, joten voit seurata muun muassa laitteen hälytyshistoriaa.',
    m004_new_device_content_p2:
      'Voit tarkistaa tai muuttaa laitteen etähälytys- ja ilmoitussasetuksia täällä:',
    m004_new_device_button: 'Avaa laitteen asetukset',
    m005_share_device_subject_a: 'Vahvista laitteen ',
    m005_share_device_heading:
      'Vahvista laitteen siirtäminen tai jakaminen käyttäjätilillesi',
    m005_share_device_p1b: 'haluaa siirtää tai jakaa laitteen ',
    m005_share_device_p1c: 'käyttäjätilillesi.',
    m006_add_device_reminder_heading:
      'Liitä Safera-tuote Safera Cloud -käyttäjätiliisi, niin saat seuraavat edut käyttöösi',
    m006_add_device_reminder_p1:
      'Laitteen lisääminen vie vain muutaman minuutin:',
    m006_add_device_reminder_button: 'Lisää laite käyttäjätiliisi',
    m007_thanks_for_order_subject:
      'Kiitos tilauksesta (ilmainen kokeilujakso on käynnissä) - Tekstiviestihälytykset',
    m007_thanks_for_order_heading:
      'Kiitos tekstiviestipalvelun tilauksesta - ilmainen kokeilujakso on käynnissä',
    m007_thanks_for_order_p1a:
      'Tekstiviestihälytysten ilmainen kokeilujakso on käynnissä ja päättyy ',
    m007_thanks_for_order_p1b: '.',
    m007_thanks_for_order_p2a:
      'Kokeilujakson jälkeen palvelu jatkuu automaattisesti toistaiseksi voimassaolevana tilauksena, ja palvelusta veloitetaan kuukausimaksu ',
    m007_thanks_for_order_p2b:
      '. Voit lopettaa palvelun milloin tahansa, jonka jälkeen palvelu on käytössäsi kuluvan laskutuskauden loppuun. Kuukausimaksu sisältää enintään 30 tekstiviestiä kuukaudessa ilman laiterajoituksia.',
    m007_thanks_for_order_p3:
      'Voit tarkastella ja muuttaa laitteen hälytysasetuksia täällä.',
    m007_thanks_for_order_service_heading: 'Tilattu palvelu:',
    m007_thanks_for_order_service:
      'Tekstiviestihälytykset 1 kk, toistaiseksi voimassa oleva tilaus',
    m007_thanks_for_order_cost_heading: 'Palvelun kuukausimaksu:',
    m007_price: '1,99 €/kk',
    m007_thanks_for_order_number_heading: 'Tilausnumero:',
    m007_thanks_for_order_date_heading: 'Tilauksen päivämäärä:',
    m007_thanks_for_order_how_to_cancel_heading: 'Tilauksen lopettaminen:',
    m007_thanks_for_order_terms_heading: 'Palvelun käyttöehdot:',
    m007_thanks_for_order_support_heading: 'Tuotetuki:',
    m007_sms_thanks_for_order_p1b:
      'otti käyttöön tekstiviestihälytykset lieden vaaratilanteista tähän numeroon laitteesta ',
    m007_sms_thanks_for_order_p1c: '.',
    m007_sms_thanks_for_order_p2: 'Lue lisää:',
    m008_trial_ending_subject:
      'Anna palautetta: saat ilmaisen lisäkuukauden tekstiviestihälytysten kokeilujaksoon',
    m008_trial_ending_heading:
      'Tekstiviestihälytysten ilmainen kokeilujakso on päättymässä - Kirjoita tuotearvostelu, niin saat 1 kk lisäaikaa',
    m008_trial_ending_p1a:
      'Tekstiviestihälytysten ilmainen kokeilujakso päättyy 7 päivän päästä ',
    m008_trial_ending_p1b: '.',
    m008_trial_ending_p2:
      'Seuraavat laitteet lähettävät etähälytykset tekstiviestillä:',
    m008_trial_ending_device_list: 'numeroon',
    m008_trial_ending_p3:
      'Toivottavasti olet ollut palveluun ja tuotteeseen tyytyväinen. Olemme kiitollisia kaikesta palautteesta, joten toivomme että voisit jakaa kokemuksesi meille ja muille asiakkaillemme.',
    m008_trial_ending_p4a:
      'Kirjoita tuotearvostelu, niin jatkamme ilmaista tekstiviestihälytysten kokeilujaksoa ',
    m008_trial_ending_p4b: 'asti:',
    m008_trial_ending_button:
      'Kirjoita tuotearvostelu - saat 1kk ilmaista lisäaikaa',
    m009_trial_ending_subcription_continues_subject:
      'Tekstiviestihälytysten ilmainen kokeilujakso on päättymässä - Tilaus jatkuu automaattisesti',
    m009_trial_ending_subcription_continues_p3:
      'Tekstiviestihälytysten ilmainen kokeilujakso päättyy [pvm].',
    m009_trial_ending_subcription_continues_p4a:
      'Kokeilujakson jälkeen palvelun tilaus jatkuu automaattisesti toistaiseksi voimassaolevana ja palvelusta veloitetaan kuukausimaksu',
    m009_trial_ending_subcription_continues_p4b: 'valitsemaltasi maksutavalta.',
    m009_sms_trial_ending_subscription_continues_p1a:
      'Safera Cloud - Tekstiviestihälytysten ilmainen kokeilujakso päättyy ',
    m009_sms_trial_ending_subscription_continues_p1b:
      ', jonka jälkeen tilaus jatkuu automaattisesti toistaiseksi voimassaolevana ja palvelusta veloitetaan kuukausimaksu',
    m009_sms_trial_ending_subscription_continues_p1c: '.',
    m009_sms_trial_ending_subscription_continues_p2: 'Hallitse tilaustasi:',
    m010_receipt_heading: 'Kiitos maksustasi',
    m010_receipt_p1: 'Olemme vastaanottaneet maksusuorituksesi palvelusta:',
    m010_receipt_sum: 'Summa:',
    m010_receipt_payment_date: 'Maksupäivä:',
    m010_receipt_p2a:
      'Tekstiviestihälytykset ovat taas käytössäsi laskutuskauden loppuun ',
    m010_receipt_p2b:
      ', jonka jälkeen seuraava kausi laskutetaan automaattisesti.',
    m010_receipt_payment_info_heading: 'Maksutiedot',
    m010_receipt_payment_info_content:
      'Voit tarkastella maksuhistoriaasi tai muuttaa maksutapaa käyttäjätilisi kohdassa Maksutiedot.',
    m011_sms_service_ending_subject:
      'Tilaus lopetettu - Tekstiviestihälytykset päättyvät',
    m011_sms_service_ending_heading: 'Tekstiviestihälytykset päättyvät ',
    m011_sms_service_ending_p1a:
      'Tekstiviestihälytysten tilaus on lopetettu. Tekstiviestihälytykset eivät ole enää käytössä nykyisen laskutusjakson päättymisen jälkeen ',
    m011_sms_service_ending_p1b: '.',
    m011_sms_service_ending_button: 'Tilaa tekstiviestipalvelu hintaan ',
    m011_sms_p1b: '.',
    m012_account_delete_heading:
      'Pyyntö poistaa Safera Cloud -käyttäjätilisi on käsittelyssä',
    m012_account_delete_p2:
      'Olet milloin tahansa tervetullut takaisin Safera Cloud -palvelun käyttäjäksi avaamalla uuden käyttäjätilin. Kun edellisen tilin poisto on suoritettu loppuun, voit luoda uuden tilin samalla sähköpostiosoitteellasi.',
    m013_personal_information_heading:
      'Liitteenä Safera Cloud -palveluun tallennetut henkilötietosi',
    m013_personal_information_p2:
      'Salasana, jolla saat PDF-tiedoston auki, lähetetään sinulle erikseen joko sähköpostilla tai tekstiviestillä matkapuhelinnumeroon, jos olet tallentanut numeron palveluumme.',
    m014_payment_failed_1_subject:
      'Päivitä maksutapa - Tekstiviestihälytysten tilausta ei voitu veloittaa',
    m014_payment_failed_1_heading: 'Maksusuoritus ei onnistunut',
    m014_payment_failed_1_p1b: 'Tekstiviestihälytykset 1,99 €/kk',
    m014_payment_failed_device_list:
      'Tekstiviestihälytykset on tilattu laitteista:',
    m014_sms_failed_1_p2:
      'Kirjaudu sisään ja valitse käyttäjätilin "Maksutiedot":',
    m015_payment_failed_2_subject:
      'Päivitä maksutapa - Tekstiviestihälytysten tilaus on päättymässä [pvm]',
    m015_payment_failed_2_heading:
      'Maksusuoritus ei onnistunut - Päivitä maksutiedot jatkaaksesi palvelua',
    m015_payment_failed_2_p2a:
      'Ellei maksutapaa päivitetä, tekstiviestihälytykset päättyvät ',
    m015_payment_failed_2_p2b: 'laitteista: ',
    m015_sms_payment_failed_2_p1b: 'ellei maksutapaa päivitetä.',
    m015_sms_payment_failed_2_p2:
      'Kirjaudu sisään ja valitse käyttäjätilistä "Maksutiedot":',
    m016_payment_ok_heading:
      'Maksusuoritus ei onnistunut - Tekstiviestihälytysten tilaus on lopetettu',
    m016_payment_ok_p1:
      'Tekstiviestihälytykset seuraavista laitteista on lopetettu, koska tekstiviestihälytysten palvelumaksua ei voitu veloittaa:',
    m016_payment_ok_button: 'Tilaa tekstiviestipalvelu hintaan 1,99 €/kk',
    m017_payment_info_updated_heading: 'Maksutiedot on päivitetty',
    m017_sms_payment_info_updated_p1:
      'Safera Cloud -palvelun tekstiviestihälytysten tilausta tähän numeroon on jatkettu.',
    m018_replace_batteries_1_subject: 'Vaihda paristot - ',
    m018_replace_batteries_1_heading:
      'Vaihda paristot - Paristot loppuvat pian',
    m018_replace_batteries_1_p1b:
      'paristojen varaustila on alhainen. Vaihda paristot mahdollisimman pian.',
    m018_replace_batteries_1_button: 'Katso ohje: paristojen vaihtaminen',
    m018_replace_batteries_1_p2:
      'Jos paristot tyhjenevät kokonaan, laite estää lieden käytön turvallisuussyistä.',
    m018_replace_batteries_1_bullets_1: 'Laite ei lähetä etähälytyksiä.',
    messages_footer_v3:
      'Jos et halua näitä ilmoituksia, voit muuttaa ilmoitusasetuksia laitteen asetuksista.',
    m019_replace_batteries_2_heading:
      'Vaihda paristot - Laite ei saa yhteyttä Wi-Fi -verkkoon',
    m019_replace_batteries_2_p1a: 'Laitteen ',
    m019_replace_batteries_2_p1b:
      'paristojen varaustila on alhainen tai paristot ovat loppuneet, joten laite ei saa yhteyttä Wi-Fi -verkkoon. Vaihda paristot mahdollisimman pian.',
    m019_sms_replace_batteries_p1a: 'Tekstiviestihälytykset laitteesta ',
    m019_sms_replace_batteries_p2: 'Katso ohjeet:',
    m020_wifi_disconnected_subject: 'Korjaa Wi-Fi -yhteys - ',
    m020_wifi_disconnected_p1a: 'Yhdistä laite ',
    m020_wifi_disconnected_p1b:
      'uudelleen Wi-Fi -verkkoon mahdollisimman pian.',
    m020_wifi_disconnected_button: 'Katso ohje: Yhdistä laite Wi-Fi -verkkoon',
    m020_wifi_disconnected_p3: 'Laite on viimeksi ollut Wi-Fi -yhteydessä:',
    m020_wifi_disconnected_p4:
      'Jos tarvitset lisäapua, ota yhteys tuotetukeemme:',
    m021_wifi_ok_subject: 'Wi-Fi -yhteys palautettu - ',
    m021_wifi_ok_p1:
      'Jos häiriöt Wi-Fi -yhteydessä ovat toistuvia, lue ohjeet täältä:',
    m021_wifi_ok_button: 'Katso ohje: Wi-Fi -yhteyden vianmääritys',
    m021_sms: 'M019_SMS_replace_batteries_p2',
    m022_malfunction_subject: 'Korjaa toimintahäiriö - ',
    m022_malfunction_heading: 'Korjaa toimintahäiriö',
    m022_malfunction_p1a: 'Laitteessa ',
    m022_malfunction_p1b: 'on havaittu toimintahäiriö:',
    m022_malfunction_button: 'Katso ohje: vianmääritys',
    m022_malfunction_p2:
      'Jos tarvitset lisäapua, ota yhteys tuotetukeemme ja ilmoita meille tämä vikakoodi nopeuttaaksesi palvelua:',
    m022_sms_malfunction_p1b:
      'eivät ole käytössä, koska laitteessa on havaittu toimintahäiriö.',
    m023_cooking_alarm_subject: 'Hälytys lieden vaaratilanteesta - ',
    m023_cooking_alarm_heading: 'Hälytys lieden vaaratilanteesta',
    m023_cooking_alarm_p1b:
      'on havainnut lieden vaaratilanteen ja katkaissut liedestä sähköt:',
    m023_cooking_alarm_p2:
      'Voit palauttaa sähköt lieteen painamalla laitteen OK-painiketta.',
    m023_sms_cooking_alarm_p2:
      'Liedestä on katkaistu sähköt. Kuittaa hälytys painamalla laitteen OK-painiketta.',
    m024_cooking_alarm_cleared_subject:
      'Kuitattu - Hälytys lieden vaaratilanteesta - ',
    m024_cooking_alarm_cleared_heading: 'Hälytys on kuitattu',
    m024_cooking_alarm_cleared_p1a: 'Laitteen ',
    m024_cooking_alarm_cleared_p1b:
      'lähettämä hälytys lieden vaaratilanteesta on kuitattu:',
    m024_sms_cooking_alarm_cleared_p1:
      'Kuitattu: Hälytys lieden vaaratilanteesta',
    m025_water_leak_alarm_subject: 'Hälytys vesivuodosta - ',
    m025_water_leak_alarm_heading: 'Hälytys vesivuodosta',
    m025_water_leak_alarm_p1b: 'on havainnut vesivuodon:',
    m025_water_leak_alarm_p2:
      'Tarkista hälytyksen syy ja tee tarvittavat jatkotoimenpiteet vahinkojen välttämiseksi.',
    m025_water_leak_alarm_button: 'Katso ohje: hälytyksen kuittaaminen',
    m026_water_leak_cleared_subject: 'Kuitattu - Hälytys vesivuodosta - ',
    m026_water_leak_cleared_p1a: 'Laitteen ',
    m026_water_leak_cleared_p1b: 'lähettämä hälytys vesivuodosta on kuitattu:',
    m027_aq_alarm_subject: 'Ilmanlaadun hälytysraja ylittynyt - ',
    m027_aq_alarm_heading: 'Ilmanlaadun hälytysraja ylittynyt',
    m027_aq_alarm_p1b: 'on havainnut ilmanlaadun hälytysrajan ylittyneen:',
    m027_aq_alarm_button: 'Tarkastele laitteen ilmanlaatuhistoriaa',
    m027_aq_alarm_p4: 'Mistä huono sisäilma johtuu?',
    m027_aq_alarm_p5:
      'Tutustu yleisimpiin syihin kodin huonon ilmanlaadun taustalla:',
    m027_aq_alarm_button2: 'Yleisimmät syyt kodin huonoon ilmanlaatuun',
    m028_cooking_alarm_no_pcu_p1b: 'on havainnut lieden vaaratilanteen:',
    m028_cooking_alarm_no_pcu_p2:
      'Tarkista, mistä vaaratilanne johtuu ja tee tarvittavat toimenpiteet vaaratilanteen pysäyttämiseksi. Voit kuitata hälytyksen painamalla laitteen OK-painiketta.',
    i001_cooking_remote_alarm_p1:
      'Voit ottaa käyttöön etähälytyksen lieden vaaratilanteesta.',
    i001_cooking_remote_alarm_p2:
      'Laite lähettää etähälytyksen, kun laite havaitsee lieden vaaratilanteen ja hälyttää äänimerkillä. Lisäksi laite lähettää viestin, kun käyttäjä on kuitannut hälytyksen.',
    i002_battery_replacement:
      'Voit ottaa käyttöön etähälytyksen paristojen alhaisesta varaustilasta.',
    i004_air_quality:
      'Voit ottaa käyttöön etähälytykset huoneen ilmanlaadulle.',
    m029_air_quality_ok_again_subject: 'Ilmanlaatu palautunut tavoitetasoon - ',
    m029_air_quality_ok_again_heading:
      'Ilmanlaatu on palautunut takaisin tavoitetasoon',
    m029_air_quality_ok_again_p1b:
      'on havainnut, että ilmanlaatu on palautunut hälytysrajan alle ja takaisin tavoitetasoon:',
    m029_air_quality_ok_again_p2a:
      'Voit tarkastella laitteen tallentamaa ilmanlaatuhistoriaa ',
    m029_air_quality_ok_again_p2b: ' -palvelussa:',
    m029_air_quality_ok_again_p3a:
      'Ilmanlaadun hälytysrajoja voit hallita ottamalla laitteeseen yhteyden ',
    m029_air_quality_ok_again_p3b: ' -mobiilisovelluksella.',
    m030_malfunction_fixed_subject:
      'Toimintahäiriö korjattu, laite toimintakunnossa - ',
    m030_malfunction_fixed_heading:
      'Laite on taas toimintakunnossa, ja toimintahäiriö on korjattu',
    m030_malfunction_fixed_p1a: 'Laitteessa ',
    m030_malfunction_fixed_p1b:
      'havaittu toimintahäiriö on korjattu, ja laite on taas toimintakunnossa.',
    m030_malfunction_multiple_errors: 'Useita toimintahäiriöitä',
    m030_sms_p1a: 'Tekstiviestihälytykset laitteesta ',
    m030_sms_p1b: 'ovat taas käytössä, ja laite on taas toimintakunnossa.',
    device_status_ok_heading: 'Laite on toimintakunnossa',
    device_status_ok_p1a: 'Laite',
    device_status_footer:
      'Laite lähettää päivän aikana tallentamansa tiedot pilvipalveluun vuorokauden vaihtuessa. Tärkeät etähälytykset ja viestit huoltotarpeesta laite lähettää kuitenkin heti.',
    saving_failed: 'Tallennus epäonnistui!',
    subscribe_to_sms_alarm_service: 'Tilaa tekstiviestihälytykset',
    sms_alarms_service_payment_info_1:
      'Tekstiviestihälytykset on maksullinen lisäpalvelu. Palvelu jatkuu automaattisesti toistaiseksi voimassaolevana tilauksena, ja palvelusta veloitetaan kuukausimaksu',
    sms_alarms_service_payment_info_2: '',
    per_month: '/kk',
    sms_service_terminate_info:
      'Voit lopettaa palvelun milloin tahansa. Kuukausimaksu sisältää enintään 30 tekstiviestiä kuukaudessa ilman laiterajoituksia.',
    i_agree_to_the_terms_and_policy:
      'Hyväksyn palvelun toimitus- ja käyttöehdot',
    sms_service_try_30_days_for_free:
      'Tilaa tekstiviestihälytykset - kokeile 30 pv ilmaiseksi',
    sms_service_try_30_days_for_free_info_1:
      'Tekstiviestihälytykset on maksullinen lisäpalvelu, jota voit kokeilla ilmaiseksi 30 päivän ajan. ',
    sms_service_try_30_days_for_free_info_2:
      'Kokeilujakson jälkeen palvelu jatkuu automaattisesti toistaiseksi voimassaolevana tilauksena, ja palvelusta veloitetaan kuukausimaksu',
    sms_service_try_30_days_for_free_info_3:
      'Voit lopettaa palvelun milloin tahansa.',
    sms_alarms_is_an_additional_service_1:
      'Tekstiviestihälytykset on maksullinen lisäpalvelu, josta veloitetaan kuukausimaksua ',
    sms_alarms_is_an_additional_service_2:
      'Voit lopettaa lisäpalvelun milloin tahansa. Valitse haluamasi hälytykset laitteiden etähälytysasetuksista.',
    are_you_sure_cancel_sms_subscription:
      'Oletko varma, että haluat lopettaa tekstiviestihälytysten tilauksen?',
    are_you_sure_cancel_sms_subscription_info:
      'Jos lopetat tilauksen, tekstiviestihälytykset eivät ole enää käytössä nykyisen laskutusjakson päättymisen jälkeen',
    i_keep_my_subscription: 'Jatkan tilaustani',
    cancel_my_subscription: 'Lopetan tilaukseni',
    sms_alarms_are_now_enabled: 'Tekstiviestihälytykset on nyt käytössä',
    following_devices_send_remote_alarms_via_sms:
      'Tiliisi liitetyt laitteet lähettävät etähälytykset lieden vaaratilanteista tekstiviestillä numeroon:',
    device_alarm_settings_info_1:
      'Voit hallita tekstiviestihälytyksiä laitekohtaisesti avaamalla laitekortin etusivulla.',
    device_alarm_settings_info_2: '',
    ok: 'OK',
    delete_user_account_be_sure:
      'Oletko varma, että haluat poistaa käyttäjätilisi?',
    delete_user_account_info:
      'Käyttäjätilisi poistetaan viikon sisällä pyynnön lähettämisestä. Poistamisen jälkeen et voi enää kirjautua tilillesi, kaikki tilisi palvelut poistuvat käytöstä ja tiliisi liittyvät tiedot poistetaan tietosuojalausekkeemme[linkki] mukaisesti.',
    delete_user_account_info_2: 'Poistettua käyttäjätiliä ei voi palauttaa',
    delete_user_account_info_3_a:
      'Voit luoda uuden käyttäjätilin samalla sähköpostiosoitteella sen jälkeen, kun olet saanut meiltä vahvistusviestin tilin poistamisesta.',
    delete_user_account_info_3_b: '',
    cancel: 'Peruuta',
    delete_user_account: 'Poista käyttäjätilini',
    no_active_subscriptions: 'Ei aktiivista tilausta',
    subscription_is_active: 'Tilaus voimassa',
    add_on_sms_alarms: 'Lisäpalvelu tekstiviestihälytykset: SMS',
    payment_method: 'Maksutavat',
    transactions: 'Maksutapahtumat',
    sms_alarms_service_1_month: 'Lisäpalvelu: Tekstiviestihälytykset 1 kk',
    signing_in_to_paypal: 'Muokkaa kirjautumalla Paypaliin',
    print_receipt: 'Tulosta kuitti',
    email_receipt: 'Lähetä kuitti sähköpostiin',
    privacy_policy: 'Tietosuojalauseke',
    cookie_policy: 'Evästekäytäntö',
    necessary_cookies: 'Välttämättömät evästeet',
    necessary_cookies_info:
      'Välttämättömät evästeet tarvitaan sivuston toimintojen toteuttamiseksi. Ne eivät tallenna henkilötietoja.',
    performance_cookies: 'Suorituskykyevästeet',
    performance_cookies_info:
      'Suorituskykyevästeillä kerätään tietoa sivuston käytöstä, kuten vierailijen määrästä ja kestosta, ja niitä käytetään sivuston ja sen sisältöjen kehittämiseen.',
    remote_alarm_settings: 'Etähälytysten asetukset',
    history_view: 'Historianäkymä',
    device_information: 'Laitteen tiedot',
    rename_the_device: 'Nimeä laite uudelleen',
    enter_new_name: 'Anna uusi nimi',
    remove_the_device: 'Poista laite omasta tilistä',
    recipient_of_remote_alarms: 'Laitteen etähälytysten vastaanottaja',
    device_settings: 'Laitteen asetukset',
    my_account: 'Omat yhteystiedot',
    device_remote_alarm_settings: 'Laitteen etähälytysasetukset',
    remote_alarm_cooking_hazard: 'Etähälytys lieden vaaratilanteesta',
    email: 'Sähköposti',
    sms: 'Tekstiviesti',
    sms_alarms_enabled_in_part_a: 'Hälytykset käytössä:',
    sms_alarms_enabled_in_part_b: 'laitteessa',
    additional_alarms: 'Lisähälytykset',
    alarm_air_quality_exceeds:
      'Etähälytys, jos huoneen ilmanlaatu ylittää hälytysraja',
    alarm_air_quality_info_1:
      'Laite mittaa jatkuvasti huoneen ilmanlaatua (tuotemallista riippuen: huoneen lämpötila, ilmankosteus, PM2.5, TVOC ja eCO2). Voit asettaa ilmanlaadun hälytykselle ala- ja ylärajat',
    alarm_air_quality_info_2:
      "välilehdellä 'Ilmanlaatu'. Etähälytykset sähköpostilla voit ottaa käyttöön yllä.",
    remote_alarm_for_battery_replacement:
      'Etähälytys paristojen vaihtotarpeesta',
    remote_alarm_for_wifi_and_offline:
      'Etähälytys, jos liesivahti ei saa yhteyttä Wi-Fi -verkkoon ja Cloud-palveluun',
    remote_alarm_for_device_errors: 'Etähälytys laitteen huoltotarpeesta',
    more_information: 'Lisätietoa',
    time_period: 'Ajanjakso',
    full_screen: 'Laajenna',
    graphs: 'Kaavionäkymä',
    air_quality: 'Ilmanlaatu',
    cooking_event: 'Lieden käyttötapahtuma',
    alarm: 'Hälytys',
    temperature: 'Lämpötila',
    humidity: 'Ilmankosteus',
    event_list: 'Tapahtumaluettelo',
    filter_events: 'Suodata tapahtumia',
    alarms: 'Hälytykset',
    error_messages: 'Vikailmoitukset',
    other: 'Muut',
    date: 'Päivämäärä',
    event: 'Tapahtuma',
    installation_settings: 'Asennuksen asetukset',
    stove_type: 'Liesityyppi',
    stove_width: 'Lieden leveys',
    mounting_position: 'Asennustapa',
    pcu: 'Virranhallintayksikkö (PCU)',
    installation_height: 'Asennuskorkeus',
    stove_wiring: 'Lieden kytkentä',
    installation_settings_info_1: 'Laitteen asennuksen asetuksia voit muuttaa',
    installation_settings_info_2:
      'valitsemalla valikosta "Installation Wizard".',
    information: 'Tiedot',
    device: 'Laite',
    device_id: 'Laitteen tunniste (ID)',
    fw_version: 'Ohjelmistoversio (FW)',
    hw_version: 'Laiteversio (HW)',
    mechanical_variant: 'Mekaaninen variaatio',
    hw_id_minor: 'Laitteen alatunniste (HW ID)',
    mechanics_version: 'Mekaaninen versio',
    device_status: 'Laitteen tila',
    notification: 'Ilmoitus:',
    welcome: 'Tervetuloa!',
    log_in_or_sign_up: 'Kirjaudu sisään tai luo uusi tili',
    continue_with_google: 'Käytä Google-tiliäsi',
    continue_with_email: 'Käytä sähköpostiosoitettasi',
    continue_with_facebook: 'Käytä Facebook-tiliäsi',
    agree_terms_and_policy:
      'Käyttämällä palvelua, hyväksyt palvelun käyttöehdot ja tietosuojalausekkeemme.',
    confirm_the_removal_of_the_device: 'Vahvista laitteen poistaminen',
    remove_device_be_sure: 'Oletko varma, että haluat poistaa laitteen:',
    remove_device_info_1:
      'Jos poistat laitteen, et voi enää tarkastella sen tallentamaa historiaa eikä laite lähetä etähälytyksiä. Poistetun laitteen tietoja ei voi palauttaa.',
    remove_device_info_2:
      'Poistamisen jälkeen, voit liittää laitteen uutena laitteena käyttäjätiliisi',
    cancel_device_remove: 'Peruuta laitteen poistaminen',
    remove_device: 'Poista laite',
    device_removed: 'Laite poistettu',
    sms_alarms_enabled_info_1:
      'Valitse tämä, jos haluat ottaa tekstiviestihälytykset nyt käyttöön vain laitteesta',
    sms_alarms_enabled_info_2:
      'Voit hallita etähälytyksiä laitekohtaisesti myös myöhemmin laitteiden asetuksista.',
    cancel_payment_1:
      'Oletko varma, että haluat lopettaa tekstiviestihälytysten tilauksen?',
    cancel_payment_2:
      'Jos lopetat tilauksen, tekstiviestihälytykset eivät ole enää käytössä nykyisen laskutusjakson päättymisen jälkeen.',
    cancel_payment_3: 'Jatka tilausta',
    cancel_payment_4: 'Lopeta tilaus',
    phone_number_missing: 'Puhelinnumero puuttuu!',
    order_id: 'Tilausnumero',
    order_date: 'Tilauspäivä',
    order_type: 'Tilaustapa',
    order_status: 'Tilauksen tila',
    order_plan_id: 'Tilaussuunnitelman tunnus',
    subscriber: 'Tilaaja',
    amount: 'Summa',
    status: 'Status',
    wizard_cloud_welcome_text:
      'Olet rekisteröimässä laitettasi Safera Cloudiin. Voit nyt siirtyä kirjautumis sivulle.',
    wizard_cloud_log_in_step: 'Vaihe 1/3',
    wizard_cloud_logged_in_heading: 'Olet kirjautuneena sisään tilille',
    wizard_cloud_next_button: 'Seuraava',
    wizard_cloud_previous_button: 'Palaa edelliseen vaiheeseen',
    wizard_cloud_logged_log_out_button: 'Vaihda käyttäjätiliä',
    wizard_cloud_new_account_heading: 'Uusi käyttäjätilisi',
    wizard_cloud_connect_device_step: 'Vaihe 2/3',
    wizard_cloud_connect_device_input_label: 'Laitteen nimi',
    wizard_cloud_connect_device_input_placeholder: 'Anna laitteelle nimi',
    wizard_cloud_remote_alarms_step: 'Vaihe 3/3',
    wizard_cloud_remote_alarms_heading:
      'Aseta vielä laitteen etähälytykset, niin olet valmis',
    wizard_cloud_remote_alarms_p2:
      'Voit lisäksi ottaa käyttöön tekstiviestihälytykset lieden vaaratilanteista.',
    wizard_cloud_remote_alarms_button: 'Avaa laitteen etähälytysasetukset',
    wizard_cloud_device_settings_button: 'Valmis',
    wizard_cloud_ready_heading: 'Valmis!',
    wizard_cloud_ready_p1: 'Etähälytykset on nyt asetettu laitteelle ',
    wizard_cloud_connect_device_failed_heading:
      'Laitteen liittäminen epäonnistui',
    wizard_cloud_connect_device_failed1_p1:
      'Laite voi olla liitettynä vain yhteen käyttäjätiliin kerrallaan. Tämä laite on jo liitetty toiseen käyttäjätiliin. Katso ohjeet täältä:',
    wizard_cloud_connect_device_failed_button: 'Palaa takaisin',
    wizard_cloud_connect_device_failed2_p1:
      'Laite on jo liitetty käyttäjätiliisi aiemmin. Kirjaudu sisään Safera Cloud -palveluun tarkastellaksesi laitteen tilaa ja asetuksia.',
    wizard_cloud_connect_device_failed3_p1:
      'Laitetta ei voi liittää palveluun. Katso ohjeet täältä:',
    wizard_cloud_connect_device_failed4_p1:
      'Laitteen liittäminen epäonnistui. Ole hyvä, ja yritä uudelleen.',
    wizard_cloud_connect_device_failed4_button: 'Yritä uudelleen',
    wizard_cloud_connect_device_failed5_p1:
      'Laitteen liittäminen epäonnistui, koska laite ei ole yhteydessä internetiin. Tarkista laitteen Wi-Fi -asetukset. Katso ohjeet täältä:',
    wizard_cloud_connect_device_heading2: 'Laitetta liitetään tiliisi...',
    list_previous: 'Edellinen',
    list_rows: 'Näytä:',
    list_to: '-',
    list_of: '/',
    accept: 'hyväksyn',
    skip: 'Ohita',
    change: 'Vaihda',
    activate_subscription: 'Aktivoi tilaus',
    in_progress: 'Odottaa vahvistusta',
    add_phonenumber: 'Lisää puhelinnumero',
    add_name: 'Lisää nimi',
    save_name: 'Tallenna nimi',
    events: 'Tapahtumat',
    n_a: 'Ei saatavilla',
    stove_alarm: 'Liesihälytys!',
    stove_alarm_pcu: 'Liesihälytys, lieden sähkö katkaistu',
    cooking_action_start: 'Ruoanlaitto alkanut',
    cooking_action_stop: 'Ruoanlaitto loppunut',
    ok_button: 'OK-nappia painettu',
    button_1: 'Painiketta 1 on painettu',
    button_2: 'Painiketta 2 on painettu',
    factory_reset: 'Tehdasasetukset palautettu',
    cloud_connection: 'Cloud-yhteystesti tehty',
    stove_unused: 'Liesi liian kauan käyttämättä',
    cooked_unusual_time: 'Kokattu epätavalliseen aikaan',
    low_battery: 'Paristo vähissä',
    critical_error: 'Kriittinen virhe',
    water_leakage: 'Vesivuotoanturi on havainnut kosteutta',
    water_alarm_over: 'Vesivuotohälytys on ohi.',
    fire_extinguishing: 'Tulipalon nestesammutus suoritettu',
    factory_connection_test: 'Tehdasyhteystesti',
    manual_connection_test: 'Yhteystesti Safera Appista',
    air_quality_cleared: 'Ilmanlaatu on palannut hyväksyttävälle tasolle',
    power_shut_down: 'Lieden sähkö katkaistu kriittisen virheen vuoksi',
    critical_error_cleared: 'Virheen aiheuttama ongelma on nyt korjaantunut',
    low_temperature: 'Matala ilman lämpötila',
    high_temperature: 'Korkea ilman lämpötila',
    low_humidity: 'Matala ilman kosteus',
    high_humidity: 'Korkea ilman kosteus',
    pm25_high: 'Korkea PM2.5',
    air_quality_alarm: 'Ilmanlaatuhälytys',
    high_eco2: 'Korkea eCO2',
    high_tvoc: 'Korkea tVOC',
    high_aqi: 'Korkea yhdistetty ilmanlaatuindeksi',
    day_log_received: 'Päiväloki vastaanotettu',
    stove_alarm_cleared: 'Liesihälytys on ohi',
    device_reboot: 'Laite käynnistettiin uudelleen.',
    stove: 'Liesi',
    oven: 'Uuni',
    ceramic: 'Keraaminen',
    induction: 'Induktio',
    iron: 'Rauta',
    iron_coil: 'Rautakäämi',
    gas: 'Kaasu',
    unknown: 'Tuntematon',
    not_installed: 'Ei asennettu',
    installed: 'Asennettu',
    wall: 'Seinä',
    hood: 'Kupu',
    yesterday: 'Eilen',
    last_7_days: 'Viimeiset 7 päivää',
    last_30_days: 'Viimeiset 30 päivää',
    this_month: 'Tässä kuussa',
    previous_month: 'Edellinen kuukausi',
    apply: 'Käytä',
    custom_range: 'Määritä aika',
    miu_error_exc: 'Lämpötila-anturin virhe',
    miu_error_tof: 'Etäisyystunnistimen virhe',
    miu_error_adc: 'Analogisen anturin virhe',
    miu_error_801: 'Kaasuanturin 1 virhe',
    miu_error_zmod: 'Kaasuanturin 2 virhe',
    miu_error_lpc: 'Hiukkasanturin virhe',
    miu_error_mma: 'Suunta-anturin virhe',
    miu_error_hum: 'Kosteusanturin virhe',
    miu_error_tilt: 'Virheellinen anturiyksikön suunta',
    miu_error_bat_low: 'Anturiyksikön akku erittäin alhainen',
    miu_error_pcu_radio: 'Virranhallintayksikön radioviestintävirhe',
    miu_error_mcu: 'Anturiyksikön pääprosessorivirhe',
    miu_error_lens_dirty: 'Anturin linssit likaiset',
    pe_voltmeas: 'AC jännitteen mittausvik',
    pe_curmeas: 'Virran mittausvika',
    pe_watermeas: 'LDS-mittausvika',
    pe_mcu: 'MCU/elektroniikkaongelma',
    pe_tempwd: 'Vika lämpötilavahtisirussa',
    pe_psu:
      'PCU-virtalähteen vika (liian alhainen jännite tai liian paljon aaltoilua)',
    pe_relay: 'PCU 3.5.16-3.5.17 raportoi tämän ~10 reletoiminnan jälkeen',
    pe_overheat:
      'Lämpötilavahti on lauennut ja PCU on ylikuumentunut, liesi kytketty pois päältä',
    pe_curmeas_diff:
      'Pää- ja varavirran laskentamenetelmät antavat erilaisia tuloksia',
    pe_curmeas_backup: 'Varavirran laskentamenetelmä käytössä',
    pe_overcur: 'Ylivirtakatkos laukaistiin',
    pe_mma_z_jammed:
      'MMA-kiihtyvyysanturin Z-akseli jumissa, yrittää selviytyä turvavarmistuksesta.',
    contact_information: 'Yhteystiedot',
    plans_and_subscriptions: 'Palvelut ja tilaukset',
    billing: 'Maksutiedot',
    privacy: 'Tietosuoja',
    sign_out: 'Kirjaudu ulos',
    back: 'Takaisin',
    user_account: 'Käyttäjätili',
    name: 'Nimi',
    mobile_phone: 'Puhelinnumero',
    email_address: 'Sähköposti',
    password: 'Salasana',
    language: 'Kieli',
    edit: 'Muokkaa',
    enter_users_name: 'Syötä käyttäjän nimi',
    eg_first_name_last_name: 'Esim. Etunimi Sukunimi',
    save: 'Tallenna',
    enter_users_phone_number: 'Syötä käyttäjän puhelinnumero',
    mobile_format: 'Muodossa +358 00 000 0000',
    please_enter_the_mobile_number_in_format:
      'Ole hyvä ja kirjoita puhelinnumero muodossa:',
    enter_your_mobile_number: 'Tallenna matkapuhelimesi numero',
    mobile_number: 'Matkapuhelimen numero',
    choose_language: 'Valitse kieli',
    change_your_account_email: 'Vaihda käyttäjätilisi sähköpostiosoite',
    new_email_address: 'Uusi sähköposti',
    please_use_only_letters_and_numbers:
      'Ole hyvä ja kirjoita nimeen vain kirjaimia ja numeroita.',
    characters_that_are_not_allowed_are_removed:
      'Merkit jotka eivät olleet sallittuja on nyt poistettu.',
    service_plan: 'Palvelupaketti',
    free: 'Ilmainen',
    free_plan_info_1: 'Ilmainen',
    free_plan_info_2:
      'sisältää etähälytykset sähköpostilla ja laitteiden historianäkymän, jossa voi tarkastella laitteiden tallentamia tietoja jälkeenpäin.',
    add_ons: 'Lisäpalvelut',
    sms_alarms: 'Etähälytykset tekstiviestillä',
    disabled: 'Ei käytössä',
    enabled: 'Käytössä',
    try_30_days_for_free_1: 'Kokeile 30 pv',
    try_30_days_for_free_2: 'ilmaiseksi',
    sms_service_info_1:
      'Tekstiviestihälytykset on lisäpalvelu, jota voit kokeilla ilmaiseksi 30 päivän ajan. Kokeilujakson jälkeen palvelusta veloitetaan kuukausimaksua',
    sms_service_info_2: '',
    sms_service_info_3:
      'Voit lopettaa palvelun käytön milloin tahansa. Palvelu sisältää enintään 30 tekstiviestiä kuukaudessa ilman laiterajoituksia.',
    start_30_day_free_trial: 'Aloita ilmainen kokeilujakso',
    cancel_free_trial: 'Lopeta kokeilujakso',
    free_trial_ends_in_1: 'Ilmaista kokeilujaksoa jäljellä:',
    free_trial_ends_in_2: 'pv',
    cancel_the_subscription: 'Lopeta tekstiviestipalvelun tilaus',
    sms_alarms_info_1:
      'Tekstiviestihälytykset on lisäpalvelu, josta veloitetaan kuukausimaksua',
    terms_of_service: 'Palvelun käyttöehdot',
    open: 'Avaa',
    changes_saved: 'Muutokset tallennettu',
    power_battery_chart_title_a: 'Tehonkulutus ja paristo',
    power_battery_chart_title_b: 'Tehonkulutus',
    power_battery_chart_title_c: 'Paristo',
    power_chart_total: 'Kokonaiskulutus:',
    cancel_smartthing_link_remove:
      'Peruuta SmartThings linkityksen poistaminen',
    remove_smartthings_link: 'Poista SmartThings linkitys',
    smartthings_add_device_info_a:
      'Lisää laite SmartThings Appsiin linkityksen jälkeen',
    smartthings_add_device_info_b: 'Lisää laite SmartThings Appsista.',
    smartthings_add_device_info_d:
      'Voit nyt lisätä laitteen SmartThings Appsiin.',
    smartthings_add_device_button: 'Luo linkki SmartThingsiin.',
    smartthings_remove_device_info:
      'Linkityksen poiston jälkeen laite pitää poistaa myös SmartThings appsista.',
    smartthings_add_dropdown: 'Linkitä laite SmartThingsiin',
    device_name: 'Laitteen nimi',
    group_name: 'Ryhmän nimi',
    message_share_details:
      'Hyväksyttyäsi kutsun pääset näkemään laitteen tiedot ja laitteen datan.',
    message_share_done_details: 'Olet jo hyväksynyt kutsun.',
    message_share_group_details:
      'Hyväksyttyäsi kutsun saat lukuoikeuden ryhmän laitteiden tietoihin ja laitteiden dataan.',
    message_transfer_ownership_details:
      'Hyväksyttyäsi kutsun, sinusta tulee laitteen uusi omistaja.',
    message_transfer_ownership_done_details: 'Olet jo hyväksynyt omistajuuden.',
    message_transfer_group_ownership_details:
      'Hyväksyttyäsi kutsun sinusta tulee kaikkien ryhmässä olevien laitteiden omistaja.',
    message_share_header: 'haluaa jakaa laitteen sinulle.',
    message_share_group_header: 'haluaa jakaa ryhmän sinulle.',
    message_ownership_header: 'haluaa antaa sinulle laitteen omistajuuden.',
    message_group_ownership_header_a:
      'haluaa antaa sinulle ryhmän omistajuuden. ',
    message_group_ownership_header_b:
      'Kaikki ryhmän laitteet siirtyvät sinun omistajuuteen.',
    message_share_title: 'Laitteen jako',
    message_group_title: 'Ryhmän jako',
    message_ownership_title: 'Omistajuuden siirto',
    email_verification_button: 'Lähetä vahvistusviesti',
    email_verification_message_a:
      'Sähköpostiosoitettasi ei ole vahvistettu. Vahvista ensin sähköpostiosoitteesi.',
    email_verification_message_b1:
      'Sinulle on lähetetty vahvistusviesti osoitteeseen ',
    email_verification_message_b2:
      'Tarkista sähköpostisi. Kun olet vahvistanut sähköpostiosoitteesi, niin paina nappia "Vahvistaminen valmis".',
    email_verification_done_button: 'Vahvistaminen valmis'
  }
};
