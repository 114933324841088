/**
 * Toast.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export default function ToastMessage({ setShow, success, show, message }) {
  var textColor = 'text-danger';
  var textIcon = faExclamationTriangle;
  if (success) {
    textColor = 'text-success';
    textIcon = faCheck;
  }
  return (
    <Toast
      onClose={() => setShow(false)}
      delay={3000}
      style={{ zIndex: '2' }}
      autohide
      show={show}
      className="bg-dark position-fixed bottom-0 p-3 mb-4 shadow-lg text-light start-50 translate-middle-x"
    >
      <Toast.Header className="text-break bg-dark text-light fs-0">
        <strong className="me-auto">{message}</strong>
        <FontAwesomeIcon icon={textIcon} className={textColor} />
      </Toast.Header>
    </Toast>
  );
}

ToastMessage.propTypes = {
  setShow: PropTypes.func,
  success: PropTypes.bool,
  show: PropTypes.bool,
  message: PropTypes.string
};
