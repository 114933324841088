/**
 * Link SmartThings.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import * as utils from 'utils';

export default async function LinkSmartThings(db, eventData) {
  try {
    const device = eventData.device;
    const uid = eventData.uid;
    //console.log(db, device, uid);
    if (device == undefined || uid == undefined) {
      console.error('Error');
      return;
    }
    const devStRef = db.collection(utils.DEVICE_KEY).doc(device.device_id);
    await devStRef.set(
      {
        smartthings: true
      },
      { merge: true }
    );

    const usrStRef = db
      .collection(utils.USERS_KEY + '/' + uid + '/' + utils.DEVICE_KEY)
      .doc(device.docId);
    await usrStRef.set(
      {
        smartthings: utils.SMARTTHINGS_ACTIVATED
      },
      { merge: true }
    );

    return;
  } catch (error) {
    console.error(error);
    return;
  }
}
