import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useHistory } from 'react-router-dom';

const ItemButton = ({ tootltip, icon, className, onClick }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="overlay-trigger-example">{tootltip}</Tooltip>}
    >
      <div className="d-inline-block">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon={icon}
          className={className}
          onClick={onClick}
        />
      </div>
    </OverlayTrigger>
  );
};

const EmailDetailHeader = () => {
  //const navigate = useNavigate();
  const history = useHistory();

  return (
    <Card className="mb-3">
      <Card.Body as={Flex} justifyContent="between">
        <div>
          <ItemButton
            tootltip="Back to inbox"
            icon="arrow-left"
            onClick={() => {
              history.push('/email/inbox');
            }}
          />
          {/* <span className="mx-1 mx-sm-2 text-300">|</span>

          <ItemButton
            tootltip="Delete"
            icon="trash-alt"
            className="ms-1 ms-sm-2"
          />
          <ItemButton
            tootltip="Mark as unread"
            icon="envelope"
            className="ms-1 ms-sm-2"
          /> */}
        </div>
        {/* <Flex>
          <div className="d-none d-md-block">
            <small> 2 of 354</small>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="chevron-left"
              className="ms-2"
            />
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="chevron-right"
              className="ms-2"
            />
          </div>
        </Flex> */}
      </Card.Body>
    </Card>
  );
};

ItemButton.propTypes = {
  tootltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default EmailDetailHeader;
