/**
 * Set PayPal status to approval pending.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../utils';

export default async function SetPayPalInProgress(db, uid, subscriptionID) {
  try {
    const setDevRef = db
      .collection(
        utils.USERS_KEY + '/' + uid + '/' + utils.SMS_SUBSCRIPTION_KEY
      )
      .doc(subscriptionID);
    await setDevRef.set(
      {
        in_progress: true
      },
      { merge: true }
    );

    return;
  } catch (error) {
    return;
  }
}
