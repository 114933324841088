/**
 * Read device event data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import * as utils from '../../utils';

export default async function ReadEvents(db, dataObj) {
  try {
    var eventArr = [];
    if (
      dataObj === undefined ||
      dataObj.device === undefined ||
      dataObj.device.data === undefined ||
      dataObj.device.data.owner === undefined
    ) {
      return [];
    }

    if (dataObj.device.data.owner.registeredTime > dataObj.endTime * 1000) {
      return [];
    }
    if (dataObj.device.data.owner.registeredTime > dataObj.startTime * 1000) {
      dataObj.startTime = dataObj.device.data.owner.registeredTime / 1000;
    }
    //console.log(dataObj);
    const eventDataRef = db
      .collection(
        utils.DEVICE_KEY +
          '/' +
          dataObj.device.device_id +
          '/' +
          utils.EVENT_KEY
      )
      .where('timestamp', '>=', dataObj.startTime)
      .where('timestamp', '<=', dataObj.endTime)
      .orderBy('timestamp', 'desc');
    const eventrDoc = await eventDataRef.get();
    for (const doc of eventrDoc.docs) {
      eventArr.push(doc.data());
    }
    return eventArr;
  } catch (error) {
    console.error(error);
    return [];
  }
}
