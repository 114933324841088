/**
 * Policy Settings.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React from 'react';
import useTranslation from 'hooks/translations';

export default function Policy() {
  const translation = useTranslation();
  return (
    <div className="container p-0 pb-3">
      <div className="card shadow">
        <div className="card-body px-3 py-3 pb-3">
          <div className="d-flex mb-3 align-items-center w-100">
            <div className="flex-shrink-1 flex-grow-1 w-100">
              <div className="w-100">
                <div className="container p-0">
                  <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link mb-3">
                    <div className="col d-flex flex-grow-1">
                      <span className="text-break">
                        {translation.privacy_policy}
                      </span>
                    </div>
                    <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                      <a
                        className="text-break"
                        href="https://safera.imagerelay.com/share/gdpr-eng"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translation.open}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="bg-400 mb-0 mt-2" />
            </div>
          </div>
          <div className="d-flex mb-3 align-items-center w-100">
            <div className="flex-shrink-1 flex-grow-1 w-100">
              <div className="w-100">
                <div className="container p-0">
                  <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link mb-3">
                    <div className="col d-flex flex-grow-1">
                      <span className="text-break">
                        {translation.cookie_policy}
                      </span>
                    </div>
                    <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                      <a
                        className="text-break"
                        href="https://safera.imagerelay.com/share/cookies"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translation.open}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="bg-400 mb-0 mt-2" />
            </div>
          </div>
          <div className="d-flex mb-3 align-items-center w-100">
            <div className="flex-shrink-1 flex-grow-1 w-100">
              <div className="w-100">
                <div className="container p-0">
                  <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                    <div className="col">
                      <div className="form-check d-flex align-items-start form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled="checked disabled"
                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="formCheck-1"
                        >
                          {translation.necessary_cookies}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row toggle-switch-info-text">
                    <div className="col">
                      <div>
                        <p className="fs--1 mb-0 text-muted">
                          {translation.necessary_cookies_info}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="bg-400 mb-0" />
            </div>
          </div>
          <div className="d-flex mb-3 align-items-center w-100">
            <div className="flex-shrink-1 flex-grow-1 w-100">
              <div className="w-100">
                <div className="container p-0">
                  <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                    <div className="col">
                      <div className="form-check d-flex align-items-start form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck-5"
                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="formCheck-5"
                        >
                          {translation.performance_cookies}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row toggle-switch-info-text">
                    <div className="col">
                      <div>
                        <p className="fs--1 mb-0 text-muted">
                          {translation.performance_cookies_info}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
