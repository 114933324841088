/**
 * Back to home card.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as utils from 'utils';
import { useStoreState } from 'easy-peasy';

export default function MessageCard({ setPage }) {
  //console.log(pageSettings);
  const messages = useStoreState(state => state.messages);
  var unreaded = 0;

  var messgageCount = 0;
  for (const msg of messages) {
    messgageCount++;
    if (!msg.read) {
      unreaded++;
    }
  }

  function goToMessagePage() {
    setPage({
      page: utils.ADVANCED_PAGES['MESSAGE']
    });
  }

  return (
    <>
      <div className="d-flex justify-content-center col">
        <div className="card device shadow-lg" onClick={goToMessagePage}>
          <div
            className={`card-body px-3 py-0 rounded bg-info`}
            style={{
              backgroundImage: 'linear-gradient(#02629e, #0283d6)'
            }}
          >
            <div>
              <div className="bg-info">
                <div
                  className="row row-cols-3 py-3 px-2 rounded-top"
                  style={{
                    backgroundImage: 'linear-gradient(#00426C, #02629e)'
                  }}
                >
                  <div
                    className="col-1 pb-1 d-flex justify-content-center align-items-center"
                    onClick={goToMessagePage}
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-dark fa-lg"
                    />
                  </div>
                  <div className="d-flex flex-grow-1 align-items-center p-0 ps-2">
                    <h5 className="device-cards-name  m-0">
                      There are messages for you.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="m-3">
                <b>New message: {unreaded}</b>
                <p>Messages: {messgageCount}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

MessageCard.propTypes = {
  setPage: PropTypes.func,
  pageSettings: PropTypes.object,
  db: PropTypes.object,
  user: PropTypes.object
};
