import useTranslation from 'hooks/translations';
import React from 'react';
import { Button } from 'react-bootstrap';

export default function WizardWelcome() {
  const translation = useTranslation();
  const urlResult = new URLSearchParams(window.location.search);

  return (
    <div className="d-flex flex-column m-2">
      <div className="container d-flex flex-fill justify-content-center align-items-center  log-in-container my-3">
        <b>{translation.welcome}</b>
      </div>
      <div className="mx-3">{translation.wizard_cloud_welcome_text}</div>
      <Button
        color="primary"
        className="m-3"
        onClick={() =>
          (window.location.href =
            'https://cloud.safera.com/index.html?' +
            urlResult.toString() +
            '&redirected=true')
        }
      >
        {translation.wizard_cloud_next_button}
      </Button>
    </div>
  );
}
