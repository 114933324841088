/**
 * PayPal subscription button.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as utils from '../../../utils';
import moment from 'moment';
import PaymentModal from './PaymentModal';
import useTranslation from 'hooks/translations';
import { doc, onSnapshot } from 'firebase/firestore';
import { useStoreActions, useStoreState } from 'easy-peasy';
import PhonenumberValidator from 'components/PhonenumberValidator';

export default function PayPalSubscriptionButton({
  paypalSubs,
  user,
  setNewState
}) {
  const translation = useTranslation();
  const [trialModal, setTrialModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const db = useStoreState(state => state.db);
  const updatePayPalSubs = useStoreActions(actions => actions.updatePayPalSubs);

  var activeSubscription;
  var isApprovalPending = false;
  var isSubscriptionActive = false;
  var button = (
    <button
      className="btn btn-success btn-sm rounded-pill"
      type="button"
      onClick={showTrialModal}
    >
      {translation.start_30_day_free_trial}
    </button>
  );

  try {
    //console.log('SUBS:', paypalSubs);
    for (const sub of paypalSubs) {
      //console.log(sub);
      if (sub.status == undefined) {
        if (!isSubscriptionActive) {
          isApprovalPending = true;
        }
      } else if (sub.status == utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
        console.log('ACTIVE!');
        isSubscriptionActive = true;
        activeSubscription = sub;
        isApprovalPending =
          sub.in_progress != undefined ? sub.in_progress : false;
        const now = new Date().getTime();
        const subscriptionCreated = new Date(sub.create_time).getTime();
        var start = moment(now);
        var end = moment(subscriptionCreated);
        const diffDays = start.diff(end, 'days');
        if (diffDays > 30) {
          button = getCancelOrderButton();
        } else {
          button = (
            <button
              className="btn btn-outline-primary btn-sm rounded-pill shadow"
              type="button"
              onClick={showCancelModal}
            >
              {translation.cancel_free_trial}
            </button>
          );
        }
      } else if (sub.status == utils.PAYPAL_SUBSCRIPTION_SUSPENDED) {
        if (!isSubscriptionActive) {
          isApprovalPending =
            sub.in_progress != undefined ? sub.in_progress : false;
          setActiveOrderButton();
          activeSubscription = sub;
        }
      } else if (sub.status == utils.PAYPAL_SUBSCRIPTION_APPROVAL_PENDING) {
        button = getInProgressButton();
        if (!isSubscriptionActive) {
          isApprovalPending = true;
          activeSubscription = sub;
        }
      } else {
        if (!isSubscriptionActive) {
          setActiveOrderButton();
        }
      }
    }
    if (!isSubscriptionActive) {
      if (user.isSmsTrialUsed) {
        setActiveOrderButton();
      }
    }
    if (isApprovalPending) {
      button = getInProgressButton();
    }
  } catch (error) {
    console.error(error);
  }

  function modalClosed() {
    setTrialModal(false);
    setCancelModal(false);
  }

  function showTrialModal() {
    setTrialModal(true);
  }

  function showCancelModal() {
    setCancelModal(true);
  }

  function setInProgress() {
    setOrderButton(getInProgressButton());
  }

  function PayPalStatusHandler(order_id) {
    try {
      onSnapshot(
        doc(
          db,
          utils.USERS_KEY + '/' + user.uid + '/' + utils.SMS_SUBSCRIPTION_KEY,
          order_id
        ),
        doc => {
          //console.log('Current data: ', doc.data());
          if (doc.data() == undefined || doc.data().status == undefined) {
            return;
          }
          var oldStatus = '';
          var newSub = doc.data();
          newSub['order_id'] = doc.id;
          if (activeSubscription != undefined) {
            oldStatus = activeSubscription.status;
          }
          if (doc.data().status == utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
            console.log('Active BUTTON');
            setOrderButton(getCancelOrderButton());
          } else if (
            doc.data().status == utils.PAYPAL_SUBSCRIPTION_SUSPENDED ||
            doc.data().status == utils.PAYPAL_SUBSCRIPTION_CANCELLED
          ) {
            setOrderButton(getActiveOrderButton());
          } else {
            console.log('In Progress BUTTON');
            setInProgress();
          }
          if (doc.data().status != oldStatus) {
            console.log('PayPal status changed:', doc.data().status);
            setNewState(newSub);
            updatePayPalSubs(newSub);
            setActiveSub(newSub);
          }
        },
        error => {
          console.error(error);
        }
      );
    } catch (err) {
      console.error(err);

      return;
    }
  }

  function getInProgressButton() {
    return (
      <button
        className="btn btn-primary btn-sm rounded-pill"
        type="button"
        onClick={showTrialModal}
        disabled
      >
        {translation.in_progress}
      </button>
    );
  }

  function setActiveOrderButton() {
    button = getActiveOrderButton();
  }

  function getActiveOrderButton() {
    return (
      <button
        className="btn btn-primary btn-sm rounded-pill"
        type="button"
        onClick={showTrialModal}
      >
        {translation.subscribe_to_sms_alarm_service}
      </button>
    );
  }

  function getCancelOrderButton() {
    return (
      <button
        className="btn btn-outline-primary btn-sm rounded-pill shadow"
        type="button"
        onClick={showCancelModal}
      >
        {translation.cancel_the_subscription}
      </button>
    );
  }
  if (!isSubscriptionActive) {
    if (!PhonenumberValidator(user.phoneNumber) || user.phoneNumber == '') {
      button = getPhoneNumberMissingButton();
    }
  }

  function getPhoneNumberMissingButton() {
    return (
      <button
        className="btn btn-warning btn-sm rounded-pill"
        type="button"
        onClick={modalClosed}
        disabled
      >
        {translation.phone_number_missing}
      </button>
    );
  }

  const [activeSub, setActiveSub] = useState(activeSubscription);
  const [orderButton, setOrderButton] = useState(button);

  useEffect(() => {
    if (activeSub !== undefined && activeSub.order_id !== undefined) {
      PayPalStatusHandler(activeSub.order_id);
    }
  }, []);

  useEffect(() => {
    setOrderButton(button);
  }, [paypalSubs]);

  return (
    <>
      {orderButton}
      <PaymentModal
        user={user}
        activeSub={activeSub}
        trialModal={trialModal}
        cancelModal={cancelModal}
        setInProgress={setInProgress}
        modalClosed={modalClosed}
        PayPalStatusHandler={PayPalStatusHandler}
      />
    </>
  );
}

PayPalSubscriptionButton.propTypes = {
  user: PropTypes.object,
  paypalSubs: PropTypes.array,
  setNewState: PropTypes.func,
  db: PropTypes.object
};
