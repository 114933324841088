/**
 * Notifications additional service.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import useTranslation from 'hooks/translations';
import 'moment/locale/fr';
import 'moment/locale/fi';

export default function DataPeriod({ setDataPeriod, readEventData, language }) {
  const translation = useTranslation();

  const [state, setState] = useState({
    start: moment().locale(language).subtract(29, 'days'),
    end: moment().locale(language)
  });

  const { start, end } = state;
  const handleCallback = (start, end) => {
    start.locale(language);
    end.locale(language);
    setState({ start, end });
    //console.log(start.unix(), end.unix());
    setDataPeriod({ start, end });
    readEventData({ start, end });
  };
  const label =
    start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  return (
    <>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12 col-sm-auto d-flex justify-content-center px-3 w-100">
          <DateRangePicker
            onCallback={handleCallback}
            initialSettings={{
              locale: {
                direction: 'ltr',
                format: moment.localeData().longDateFormat('L'),
                separator: ' - ',
                applyLabel: translation.apply,
                cancelLabel: translation.cancel,
                weekLabel: 'W',
                customRangeLabel: translation.custom_range,
                daysOfWeek: moment.weekdaysMin(),
                monthNames: moment.monthsShort(),
                firstDay: moment.localeData().firstDayOfWeek()
              },
              startDate: start.toDate(),
              endDate: end.toDate(),
              ranges: {
                [translation.yesterday]: [
                  moment().subtract(1, 'days').toDate(),
                  moment().subtract(1, 'days').toDate()
                ],
                [translation.last_7_days]: [
                  moment().subtract(6, 'days').toDate(),
                  moment().toDate()
                ],
                [translation.last_30_days]: [
                  moment().subtract(29, 'days').toDate(),
                  moment().toDate()
                ],
                [translation.this_month]: [
                  moment().startOf('month').toDate(),
                  moment().endOf('month').toDate()
                ],
                [translation.previous_month]: [
                  moment().subtract(1, 'month').startOf('month').toDate(),
                  moment().subtract(1, 'month').endOf('month').toDate()
                ]
              }
            }}
          >
            <div id="reportrange" className="date-range-picker">
              <FontAwesomeIcon icon={faCalendar} className="text-primary" />
              &nbsp;
              <span>{label}</span>{' '}
              <FontAwesomeIcon icon={faCaretDown} className="text-primary" />
            </div>
          </DateRangePicker>
        </div>
      </div>
    </>
  );
}

DataPeriod.propTypes = {
  setDataPeriod: PropTypes.func,
  readEventData: PropTypes.func,
  language: PropTypes.string
};
