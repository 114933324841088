/**
 * User Settings.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import useTranslation from 'hooks/translations';

export default function UserSettings() {
  const translation = useTranslation();

  return (
    <div className="container p-0 pb-3">
      <div className="card shadow">
        <div className="card-body px-3 py-3 pb-3">
          <div className="d-flex align-items-center w-100">
            <div className="flex-shrink-1 flex-grow-1 w-100">
              <div className="w-100">
                <div className="container p-0">
                  <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                    <div className="col d-flex flex-grow-1">
                      <span className="text-break">
                        {translation.terms_of_service}
                      </span>
                    </div>
                    <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                      <a
                        className="text-break"
                        href="https://safera.imagerelay.com/share/warranty-and-license-terms?_ga=2.86347672.1200527814.1584089005-136302795.1582637603"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translation.open}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
