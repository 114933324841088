/**
 * PayPal subscription.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';
import * as utils from '../../../utils';
import moment from 'moment';
import PayPalSubscriptionButton from './PayPalSubscriptionButton';
import PayPalSubscriptionNote from './PayPalSubscriptionNote';
import useTranslation from 'hooks/translations';

export default function PayPalSubscription({ user }) {
  const translation = useTranslation();
  const paypalSubs = useStoreState(state => state.paypalSubscriptions);

  console.log(paypalSubs);
  var isSubscriptionActive = false;
  var isFreeTryPeriodAvailable = true;
  var activeSub;

  var subscriptionInfo = getSubscriptionInitInfo();
  var paymentStatus = (
    <span className="badge rounded-pill bg-secondary text-uppercase text-end">
      {translation.disabled}
    </span>
  );

  var paymentValueActive = 'line-through';
  var subscriptionNote = '';
  try {
    for (const sub of paypalSubs) {
      handleSubscription(sub);
    }
  } catch (error) {
    console.error(error);
  }

  function getSubscriptionInitInfo() {
    return (
      <p className="text-muted lh-sm fs--1">
        {translation.sms_alarms_is_an_additional_service_1}{' '}
        {utils.SMS_SUBSCRIPTION_PRICE}
        {translation.per_month}.{' '}
        {translation.sms_alarms_is_an_additional_service_2}
      </p>
    );
  }

  function handleSubscription(sub) {
    isFreeTryPeriodAvailable = false;
    if (sub.status == utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
      isSubscriptionActive = true;
      activeSub = sub;
      paymentStatus = (
        <span className="badge rounded-pill bg-success text-uppercase text-end">
          {translation.enabled}
        </span>
      );
      const now = new Date().getTime();
      const subscriptionCreated = new Date(sub.create_time).getTime();
      var start = moment(now);
      var end = moment(subscriptionCreated);
      const diffDays = start.diff(end, 'days');
      if (diffDays > 30) {
        paymentValueActive = '';
      }
    } else {
      paymentStatus = (
        <span className="badge rounded-pill bg-secondary text-uppercase text-end">
          {translation.disabled}
        </span>
      );
    }
  }

  subscriptionNote = <PayPalSubscriptionNote sub={activeSub} />;
  if (!isSubscriptionActive) {
    if (isFreeTryPeriodAvailable) {
      subscriptionNote = (
        <div>
          <PayPalSubscriptionNote sub={activeSub} />
          {subscriptionInfo}
        </div>
      );
      subscriptionInfo = '';
    } else {
      subscriptionNote = subscriptionInfo;
      subscriptionInfo = '';
    }
  }

  const [subNote, setSubNote] = useState(subscriptionNote);
  const [subInfo, setSubInfo] = useState(subscriptionInfo);
  const [priceActive, setPriceActive] = useState(paymentValueActive);
  const [statusTxt, setStatusTxt] = useState(paymentStatus);

  function setNewState(sub) {
    setSubNote(<PayPalSubscriptionNote sub={sub} />);
    subscriptionInfo = getSubscriptionInitInfo();
    handleSubscription(sub);
    setSubInfo(subscriptionInfo);
    setPriceActive(paymentValueActive);
    setStatusTxt(paymentStatus);
  }

  return (
    <div className="flex-shrink-1 flex-grow-1 w-100">
      <div className="w-100">
        <div className="container p-0">
          <div className="row flex-grow-0 flex-nowrap panel-item-small-heading-with-link mb-1">
            <div className="col d-flex flex-grow-1">
              <h4 className="text-break fs--0">
                <strong>{translation.sms_alarms}</strong>
                <br />
              </h4>
            </div>
            <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
              {statusTxt}
            </div>
          </div>
          <div className="row flex-grow-0 flex-nowrap panel-item-small-heading-with-link mb-2">
            <div className="col d-flex flex-grow-1">{subNote}</div>
            <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
              <p className="text-break mb-0">
                <span
                  style={{
                    textDecoration: `${priceActive}`
                  }}
                >
                  {utils.SMS_SUBSCRIPTION_PRICE}
                  {translation.per_month}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>{subInfo}</div>
      <PayPalSubscriptionButton
        paypalSubs={paypalSubs}
        user={user}
        setNewState={setNewState}
      />
    </div>
  );
}

PayPalSubscription.propTypes = {
  user: PropTypes.object
};
