/**
 * SMS text.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React from 'react';
import useTranslation from 'hooks/translations';

export default function SmsText() {
  const translation = useTranslation();
  return <>{translation.sms}</>;
}
