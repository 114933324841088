/**
 * Event Code.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../utils';
import ErrorCode from './ErrorCode';

export default function EventCode(event, translation) {
  //const translation = useTranslation();
  var eventContentObj = {};
  switch (event.code) {
    case utils.WE_STOVE_ALARM:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr = translation.stove_alarm;
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_STOVE_ALARM_PCU_DISCONNECTED:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr = translation.stove_alarm_pcu;
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_COOKING_ACTION_START:
      eventContentObj.iconStr = 'fa fa-user';
      eventContentObj.titleStr = translation.cooking_action_start;
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_COOKING_ACTION_STOP:
      eventContentObj.iconStr = 'fa fa-user-times';
      eventContentObj.titleStr = translation.cooking_action_stop;
      eventContentObj.iconColorClass = 'icon bg3';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_AIR_QUALITY_ALARM:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr = 'Air quality alarm';
      eventContentObj.contentText =
        '<p>The air quality exceeds the limit values.</p><p>Param: ' +
        event.param +
        '</p>';
      eventContentObj.iconColorClass = 'icon bg3';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_OK_BUTTON_PRESS:
      eventContentObj.iconStr = 'fa fa-hand-pointer-o';
      eventContentObj.titleStr = translation.ok_button;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_1_BUTTON_PRESS:
      eventContentObj.iconStr = 'fa fa-hand-pointer-o';
      eventContentObj.titleStr = translation.button_1;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_2_BUTTON_PRESS:
      eventContentObj.iconStr = 'fa fa-hand-pointer-o';
      eventContentObj.titleStr = translation.button_2;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_FACTORY_RESET_DONE:
      eventContentObj.iconStr = 'fa fa-power-off';
      eventContentObj.titleStr = translation.factory_reset;
      eventContentObj.contentText = '<p>Param: ' + event.param + '</p>';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_CONNECTION_TEST:
      eventContentObj.iconStr = 'fa fa-bullhorn';
      eventContentObj.titleStr = translation.cloud_connection;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_STOVE_NOT_USED:
      eventContentObj.iconStr = 'fa fa-hourglass-end';
      eventContentObj.titleStr = translation.stove_unused;
      eventContentObj.iconColorClass = 'icon bg3';
      eventContentObj.textColor = 'text-warning';
      break;
    case utils.WE_STOVE_USAGE_STRANGE:
      eventContentObj.iconStr = 'fa fa-hourglass-half';
      eventContentObj.titleStr = translation.cooked_unusual_time;
      eventContentObj.iconColorClass = 'icon bg3';
      eventContentObj.textColor = 'text-warning';
      break;
    case utils.WE_BATTERY_LOW:
      eventContentObj.iconStr = 'fa fa-battery-quarter';
      eventContentObj.titleStr = translation.low_battery;
      eventContentObj.iconColorClass = 'icon bg3';
      eventContentObj.textColor = 'text-warning';
      break;
    case utils.WE_CRITICAL_ERROR:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr =
        '<b>' + translation.critical_error + '</b><br>';
      ErrorCode(event.param, translation).forEach(
        key =>
          (eventContentObj.titleStr = eventContentObj.titleStr + key + '<br>')
      );
      eventContentObj.iconColorClass = 'icon bg3';
      eventContentObj.textColor = 'text-warning';
      break;
    case utils.WE_PCU_WATER_LEAKAGE_ALARM:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr = translation.water_leakage;
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_PCU_WATER_LEAKAGE_ALARM_CLEARED:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr = translation.water_alarm_over;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_CTU_FIRE_EXTINGUISH_ALARM:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr = translation.fire_extinguishing;
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_FACTORY_CONNECTION_TEST:
      eventContentObj.iconStr = 'fa fa-thumbs-up';
      eventContentObj.titleStr = translation.factory_connection_test;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_CONNECTION_TEST_MANUAL:
      eventContentObj.iconStr = 'fa fa-thumbs-up';
      eventContentObj.titleStr = translation.manual_connection_test;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;

    case utils.WE_AIR_QUALITY_ALARM_CLEARED:
      eventContentObj.iconStr = 'fa fa-bullhorn';
      eventContentObj.titleStr = translation.air_quality_cleared;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_CRITICAL_ERROR_PCU_SHUTDOWN:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr =
        '<b>' + translation.power_shut_down + '</b><br>';
      ErrorCode(event.param, translation).forEach(
        key =>
          (eventContentObj.titleStr = eventContentObj.titleStr + key + '<br>')
      );
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-warning';
      break;
    case utils.WE_CRITICAL_ERROR_CLEARED:
      eventContentObj.iconStr = 'fa fa-bullhorn';
      eventContentObj.titleStr = translation.critical_error_cleared;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_AIR_QUALITY_ALARM_TEMP_LOW:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      //console.log(event.param);
      var lowTemperature = event.param / 100 - 50;
      eventContentObj.titleStr =
        translation.low_temperature + ' (' + lowTemperature.toFixed(2) + '°C).';
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_AIR_QUALITY_ALARM_TEMP_HIGH:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      var temperature = event.param / 100 - 50;
      eventContentObj.titleStr =
        translation.high_temperature + ' (' + temperature.toFixed(2) + '°C).';
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_AIR_QUALITY_ALARM_HUM_LOW:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr =
        translation.low_humidity + ' (' + event.param + '%).';
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_AIR_QUALITY_ALARM_HUM_HIGH:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr =
        translation.high_humidity + ' (' + event.param + '%).';
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_AIR_QUALITY_ALARM_PM25_HIGH:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr =
        translation.air_quality_alarm +
        ' ' +
        translation.pm25_high +
        ' (' +
        event.param +
        'µg/m' +
        String.fromCharCode(179) +
        ').';
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_AIR_QUALITY_ALARM_ECO2_HIGH:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr =
        translation.air_quality_alarm +
        ' ' +
        translation.high_eco2 +
        ' (' +
        event.param +
        'ppm).';
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_AIR_QUALITY_ALARM_TVOC_HIGH:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr =
        translation.air_quality_alarm +
        ' ' +
        translation.high_tvoc +
        ' (' +
        event.param +
        'µg/m' +
        String.fromCharCode(179) +
        ').';
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_AIR_QUALITY_ALARM_AQI_HIGH:
      eventContentObj.iconStr = 'fa fa-exclamation-triangle';
      eventContentObj.titleStr =
        translation.air_quality_alarm +
        ' ' +
        translation.high_aqi +
        ' (' +
        event.param +
        ').';
      eventContentObj.iconColorClass = 'icon bg5';
      eventContentObj.textColor = 'text-danger';
      break;
    case utils.WE_DAYLOG_READ:
      eventContentObj.iconStr = 'fa fa-envelope';
      eventContentObj.titleStr = translation.day_log_received;
      eventContentObj.iconColorClass = 'icon bg1';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_STOVE_ALARM_CLEARED:
      eventContentObj.iconStr = 'fa fa-bullhorn';
      eventContentObj.titleStr = translation.stove_alarm_cleared;
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    case utils.WE_DEVICE_REBOOT:
      var hex = event.param.toString(16);
      hex = '0'.repeat(8 - hex.length) + hex;

      var hwIdBytes = [];

      for (var c = 0; c < hex.length; c += 2) {
        hwIdBytes.push(parseInt(hex.substr(c, 2), 16));
      }

      eventContentObj.iconStr = 'fa fa-bullhorn';
      eventContentObj.titleStr =
        translation.device_reboot +
          "<p class='text-muted'>FW: " +
          hwIdBytes[3] !==
        undefined
          ? hwIdBytes[3]
          : 0 + '.' + hwIdBytes[2] !== undefined
          ? hwIdBytes[2]
          : 0 + ' HW: ' + hwIdBytes[1] !== undefined
          ? hwIdBytes[1]
          : 0 + '.' + hwIdBytes[0] !== undefined
          ? hwIdBytes[0]
          : 0 + '</p>';
      eventContentObj.iconColorClass = 'icon bg4';
      eventContentObj.textColor = 'text-success';
      break;
    default:
      eventContentObj.iconStr = 'fa fa-envelope';
      eventContentObj.titleStr =
        'Code: ' + event.code + ' Param: ' + event.param;
      eventContentObj.textColor = 'text-warning';
  }

  return eventContentObj;
}
