/**
 * Device dropdown.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faBullhorn,
  faChartBar,
  faTools,
  faExclamationCircle,
  faExclamationTriangle,
  faArrowsAlt
} from '@fortawesome/free-solid-svg-icons';
import * as utils from '../../../utils';
import useTranslation from '../../../hooks/translations';
import writeModel from '../../../components/DataBase/DataBaseWriteModel';
import ToastMessage from '../ToastMessage';
import TextSanitizing from '../../../components/TextSanitizing';
import PropTypes from 'prop-types';
//import ReactGA from 'react-ga4';
import RemoveDevice from 'components/DataBase/Device/RemoveDevice';
import TagManager from 'react-gtm-module';
import MoveDeviceModal from './MoveDeviceModal';
import MoveDevice from 'components/DataBase/Device/MoveDevice';

export default function DeviceDropdown({ device, setPage, user }) {
  //console.log(device);
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const [toastContent, setToastContent] = useState({
    message: translation.changes_saved,
    success: true
  });
  const [showRenameModal, setShowRenameModal] = useState(false);
  const handleRenameClose = () => setShowRenameModal(false);
  const handleRenameShow = () => setShowRenameModal(true);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const handleRemoveClose = () => setShowRemoveModal(false);
  const handleRemoveShow = () => setShowRemoveModal(true);
  const [showSmartThingsModal, setShowSmartThingsModal] = useState(false);
  const handleSmartThingsClose = () => setShowSmartThingsModal(false);
  const handleSmartThingsShow = () => setShowSmartThingsModal(true);

  const [showMoveGroupModal, setShowMoveGroupModal] = useState(false);
  const handleMoveGroupModalClose = () => setShowMoveGroupModal(false);
  const handleMoveGroupModalShow = () => setShowMoveGroupModal(true);

  const { setDevice, dbUpdate, updateRemovedDevice } = useStoreActions(
    actions => ({
      setDevice: actions.setDevice,
      dbUpdate: actions.dbUpdate,
      updateRemovedDevice: actions.updateRemovedDevice
    })
  );
  const db = useStoreState(state => state.db);
  const newDeviceNameRef = useRef();
  const unlinkSmartThingsAction = useStoreActions(
    actions => actions.unlinkSmartThings
  );
  const linkSmartThingsAction = useStoreActions(
    actions => actions.linkSmartThings
  );
  const analyticId =
    user.analytics_id != undefined ? user.analytics_id : user.uid;
  var currentDevice = device;
  var smartThingsItemInit = '';
  var smartThingsFooterInit = '';

  if (device.data != undefined && user.isSmartThings) {
    if (device.data.smartthings) {
      smartThingsItemInit = (
        <>
          <div className="dropdown-divider"></div>
          <a
            className="dropdown-item"
            onClick={() => showModalSmartThings(device)}
          >
            {translation.remove_smartthings_link}
          </a>
        </>
      );
      smartThingsFooterInit = getSmartThingsRemoveDeviceModal();
    } else {
      if (!device.isGuest) {
        smartThingsItemInit = (
          <>
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item"
              onClick={() => showModalSmartThings(device)}
            >
              {translation.smartthings_add_dropdown}
            </a>
          </>
        );
        smartThingsFooterInit = getSmartThingsAddNewDeviceModal();
      }
    }
  }
  const [smartThingsFooter, setSmartThingsFooter] = useState(
    smartThingsFooterInit
  );
  const [smartThingsItem, setSmartThingsItem] = useState(smartThingsItemInit);

  function getSmartThingsRemoveDeviceModal() {
    return (
      <div className="col">
        <p>{translation.smartthings_remove_device_info}</p>
        <button
          className="btn btn-primary w-100 shadow mb-3"
          type="button"
          onClick={handleSmartThingsClose}
        >
          {translation.cancel_smartthing_link_remove}
        </button>
        <div>
          <button
            className="btn btn-primary link-danger w-100 shadow btn-falcon-default"
            type="button"
            onClick={() => removeSmartThingsLink(currentDevice)}
          >
            {translation.remove_smartthings_link}
          </button>
        </div>
      </div>
    );
  }

  function getSmartThingsAddNewDeviceModal() {
    return (
      <div className="col">
        <p>{translation.smartthings_add_device_info_a}</p>
        <p>
          &bull; {translation.smartthings_add_device_info_b}
          <br />
          &bull; {translation.smartthings_add_device_info_c}
          <br />
        </p>
        <button
          className="btn btn-primary w-100 shadow mb-3 link-muted btn-falcon-default"
          type="button"
          onClick={handleSmartThingsClose}
        >
          {translation.cancel}
        </button>
        <div>
          <button
            className="btn btn-primary w-100 shadow "
            type="button"
            onClick={() => addToSmartThings(currentDevice)}
          >
            {translation.smartthings_add_device_button}
          </button>
        </div>
      </div>
    );
  }

  function showModalContent(selectedDevice) {
    console.log(selectedDevice);
    currentDevice = selectedDevice;
    handleRenameShow();
  }

  function showModalSmartThings(selectedDevice) {
    //console.log(selectedDevice);
    currentDevice = selectedDevice;
    handleSmartThingsShow();
  }

  async function handleRemoveDevice(selectedDevice) {
    TagManager.dataLayer({
      dataLayer: {
        userId: analyticId,
        userProject: 'Personal',
        page: 'Remove device'
      },
      dataLayerName: 'SaferaDataLayer'
    });

    const saved = await RemoveDevice(db, selectedDevice, user.uid);
    handleRemoveClose();
    if (saved) {
      setShow(saved);
      // TODO: Fix this ->
      await new Promise(resolve => setTimeout(resolve, 2000)); // 2 sec
      updateRemovedDevice(selectedDevice);
    }
  }

  function showRemoveModalContent(selectedDevice) {
    console.log(selectedDevice);
    currentDevice = selectedDevice;
    handleRemoveShow();
  }

  function showToast(isSaved) {
    //console.log(isSaved);
    if (!isSaved) {
      setToastContent({
        message: translation.saving_failed,
        success: false
      });
    }
    setShow(true);
  }

  function removeSmartThingsLink(deviceObj) {
    console.log('SmartThings', deviceObj);
    const evenData = { device: deviceObj, uid: user.uid, lang: user.language };
    unlinkSmartThingsAction(evenData);
    setSmartThingsItem(
      <>
        <div className="dropdown-divider"></div>
        <a
          className="dropdown-item"
          onClick={() => showModalSmartThings(device)}
        >
          {translation.smartthings_add_dropdown}
        </a>
      </>
    );
    handleSmartThingsClose();
    setSmartThingsFooter(getSmartThingsAddNewDeviceModal());
  }

  function addToSmartThings(deviceObj) {
    console.log('SmartThings', deviceObj);
    const evenData = { device: deviceObj, uid: user.uid, lang: user.language };
    linkSmartThingsAction(evenData);
    setSmartThingsItem(
      <>
        <div className="dropdown-divider"></div>
        <a
          className="dropdown-item"
          onClick={() => showModalSmartThings(device)}
        >
          {translation.remove_smartthings_link}
        </a>
      </>
    );
    setSmartThingsFooter(
      <div className="col">
        <p>{translation.smartthings_add_device_info_d}</p>
        <p>
          &bull; {translation.smartthings_add_device_info_b}
          <br />
          &bull; {translation.smartthings_add_device_info_c}
          <br />
        </p>
        <div>
          <button
            className="btn btn-primary w-100 shadow "
            type="button"
            onClick={smartThingsLinkAdded}
          >
            {translation.ok}
          </button>
        </div>
      </div>
    );
    //handleSmartThingsClose();
  }

  function smartThingsLinkAdded() {
    handleSmartThingsClose();
    setSmartThingsFooter(getSmartThingsRemoveDeviceModal());
  }

  async function changeDeviceName(deviceObj) {
    try {
      if (deviceObj == undefined) {
        return;
      }
      TagManager.dataLayer({
        dataLayer: {
          userId: analyticId,
          userProject: 'Personal',
          page: 'Rename device'
        },
        dataLayerName: 'SaferaDataLayer'
      });

      handleRenameClose();
      const newDevName = TextSanitizing(newDeviceNameRef.current.value, false);
      deviceObj.name = newDevName;
      console.log(deviceObj.docId);
      setDevice(deviceObj);
      writeModel.path =
        utils.USERS_KEY + '/' + user.uid + '/' + utils.DEVICE_KEY;
      writeModel.doc = deviceObj.docId;
      writeModel.data = {
        name: newDevName
      };
      writeModel.callback = showToast;
      dbUpdate(writeModel);
    } catch (error) {
      console.error(error);
    }
  }

  var infoItem = '';

  async function moveDevice(groupId) {
    if (groupId != undefined) {
      console.log(groupId);
      const retVal = await MoveDevice(db, groupId, device, user.uid);
      if (retVal) {
        if (device.isGuest) {
          device.groupId = groupId;
        } else {
          device.data.groupId = groupId;
        }
        setDevice(device);
      } else {
        console.error('ERROR: DB error!');
      }
    }
  }

  // TODO: Status info to dropdown

  //var eventList = StatusPopover(device);
  //   if (eventList.length > 0) {
  //     infoItem = (
  //       <>
  //         <a className="dropdown-item text-danger" href="#">
  //           <div className="d-inline-flex dropdown-menu-icon align-middle">
  //             <FontAwesomeIcon
  //               icon={faExclamationTriangle}
  //               className="text-danger icon-tiny-button mb-1"
  //             />
  //           </div>
  //           Toimintahäiriö - Aloita vianetsintä
  //         </a>
  //         <div className="dropdown-divider"></div>
  //       </>
  //     );
  //   }

  return (
    <>
      <div className="dropdown d-inline-flex justify-content-end align-items-center justify-content-sm-center align-items-sm-center">
        <button
          className="btn bg-white d-inline-flex justify-content-center align-items-center flex-nowrap m-auto btn-falcon-default btn-circle-with-icon shadow"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          type="button"
        >
          <FontAwesomeIcon
            icon={faEllipsisH}
            className="text-primary icon-tiny-button"
          />
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          {infoItem}
          <a
            className="dropdown-item d-flex align-items-center"
            onClick={() =>
              setPage({
                device: device,
                page: utils.PERSONAL_PAGES['DEVICE_SETTINGS'],
                subPage:
                  utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE[
                    'NOTIFICATION_SETTINGS'
                  ]
              })
            }
          >
            <div className="d-inline-flex dropdown-menu-icon">
              <FontAwesomeIcon
                icon={faBullhorn}
                className="icon-tiny-button text-primary"
              />
            </div>
            {translation.remote_alarm_settings}
          </a>
          <a
            className="dropdown-item d-flex align-items-center"
            onClick={() =>
              setPage({
                device: device,
                page: utils.PERSONAL_PAGES['DEVICE_SETTINGS'],
                subPage: utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['HISTORY']
              })
            }
          >
            <div className="d-inline-flex dropdown-menu-icon">
              <FontAwesomeIcon
                icon={faChartBar}
                className="icon-tiny-button text-primary"
              />
            </div>
            {translation.history_view}
          </a>
          <a
            className="dropdown-item d-flex align-items-center"
            onClick={() =>
              setPage({
                device: device,
                page: utils.PERSONAL_PAGES['DEVICE_SETTINGS'],
                subPage: utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['DEVICE_INFO']
              })
            }
          >
            <div className="d-inline-flex dropdown-menu-icon">
              <FontAwesomeIcon
                icon={faTools}
                className="icon-tiny-button text-primary"
              />
            </div>
            {translation.device_information}
          </a>
          <a
            className="dropdown-item d-flex align-items-center"
            onClick={handleMoveGroupModalShow}
          >
            <div className="d-inline-flex dropdown-menu-icon">
              <FontAwesomeIcon
                icon={faArrowsAlt}
                className="icon-tiny-button text-primary"
              />
            </div>
            Move
          </a>
          {smartThingsItem}
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" onClick={() => showModalContent(device)}>
            {translation.rename_the_device}
          </a>
          <a
            className="dropdown-item link-danger"
            onClick={() => showRemoveModalContent(device)}
          >
            {translation.remove_the_device}
          </a>
        </div>
      </div>
      <Modal
        role="dialog"
        tabIndex="-1"
        id="modal-rename-device"
        show={showSmartThingsModal}
        onHide={handleSmartThingsClose}
      >
        <Modal.Header className="pe-4 border-0">
          <h4 className="modal-title pe-5">{currentDevice.name}</h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleSmartThingsClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <img src="img/SmartThingsLogo_72.png" alt="SmartThings"></img>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 pb-4">
          {smartThingsFooter}
        </Modal.Footer>
      </Modal>

      <Modal
        role="dialog"
        tabIndex="-1"
        id="modal-rename-device"
        show={showRenameModal}
        onHide={handleRenameClose}
      >
        <Modal.Header className="pe-4 border-0">
          <h4 className="modal-title pe-5">{currentDevice.name}</h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleRenameClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label">{translation.enter_new_name}</label>
          <input
            type="text"
            className="form-control"
            placeholder={currentDevice.name}
            ref={newDeviceNameRef}
          />
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 pb-4">
          <div className="w-100">
            <button
              className="btn btn-primary w-100 shadow"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => changeDeviceName(currentDevice)}
            >
              {translation.save}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        role="dialog"
        tabIndex="-1"
        id="modal-remove-device"
        show={showRemoveModal}
        onHide={handleRemoveClose}
      >
        <div className="modal-header pe-4 border-0">
          <button
            type="button"
            className="btn-close"
            onClick={handleRemoveClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <h2 className="text-center">
            {translation.confirm_the_removal_of_the_device}
          </h2>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-center text-danger w-100 fs-4 mt-2"
          />
          <hr />
          <p className="text-start text-black">
            {translation.remove_device_be_sure}
          </p>
          <p className="fw-bold text-start text-black">{currentDevice.name}</p>

          <p className="text-start text-black">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-warning me-2"
            />
            {translation.remove_device_info_1}
          </p>
          <p className="text-start text-muted fs--1">
            {translation.remove_device_info_2} {translation.by_app}
          </p>
          <hr />
          <div className="col">
            <button
              className="btn btn-primary w-100 shadow mb-3"
              type="button"
              onClick={handleRemoveClose}
            >
              {translation.cancel_device_remove}
            </button>
            <div>
              <button
                className="btn btn-primary link-danger w-100 shadow btn-falcon-default"
                type="button"
                onClick={() => handleRemoveDevice(currentDevice)}
              >
                {translation.remove_device}
              </button>
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-center border-0 pt-0 pb-4">
          <div id="paypal-button-container-3"></div>
        </div>
      </Modal>
      <MoveDeviceModal
        showModal={showMoveGroupModal}
        handleClose={handleMoveGroupModalClose}
        device={device}
        moveDevice={moveDevice}
        user={user}
      />
      <ToastMessage
        setShow={setShow}
        success={toastContent.success}
        show={show}
        message={toastContent.message}
      />
    </>
  );
}

DeviceDropdown.propTypes = {
  device: PropTypes.object,
  setPage: PropTypes.func,
  user: PropTypes.object
};
