/**
 * Read device daylog data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import ReadLatestDayLogDoc from 'components/DataBase/Device/ReadLatestDayLogDoc';
import GetDayObject from './GetDayObject';

export default async function ReadLatestDaylog(db, deviceId, uid) {
  const dayLogDoc = await ReadLatestDayLogDoc(db, deviceId, uid);
  const daylog = GetDayObject(dayLogDoc);
  return daylog;
}
