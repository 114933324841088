import * as utils from '../../utils';

export async function EventListener(actions, db, deviceId) {
  try {
    //const updateEvent = useStoreActions((actions) => actions.updateEvent);

    db.collection(utils.DEVICE_KEY + '/' + deviceId + '/' + utils.EVENT_KEY)
      .where('timestamp', '>=', new Date().getTime() / 1000)
      .orderBy('timestamp', 'desc')
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          //console.log(change.type);
          if (change.type === 'removed') {
            console.log('removed');
          } else if (change.type === 'added') {
            var message = change.doc.data();
            actions.updateEvent({ deviceId: deviceId, data: message });
          }
        });
      });
  } catch (err) {
    console.error(err);
    return;
  }
}
