/**
 * URL param type selector.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import SelectDevice from './SmartThings/SelectDevice';
import PropTypes from 'prop-types';

export default function TypeHandler({
  db,
  user,
  urlResult,
  auth,
  firebaseSignout
}) {
  var typeContent = <div></div>;
  var type;
  if (urlResult.has('safera_type')) {
    type = urlResult.get('safera_type');
  }
  console.log(type);
  switch (type) {
    case 'smartthings_auth':
      console.log('SmartThings');
      typeContent = (
        <SelectDevice
          db={db}
          user={user}
          urlResult={urlResult}
          auth={auth}
          firebaseSignout={firebaseSignout}
        />
      );
      break;

    default:
      typeContent = <div></div>;
      break;
  }
  return typeContent;
}

TypeHandler.propTypes = {
  user: PropTypes.object,
  db: PropTypes.object,
  urlResult: PropTypes.object
};
