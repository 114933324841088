/**
 * User model.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
export default function user() {
  return {
    admin: false,
    displayName: '',
    email: '',
    emailVerified: false,
    photoURL: '',
    uid: '',
    phoneNumber: '',
    providerData: '',
    country: '',
    city: '',
    company: '',
    deviceID: '',
    created_timestamp: '',
    policyAccepted: false,
    policyAcceptedTimestamp: '',
    isWeeklyReportEnabled: true,
    isMonthlyReportEnabled: true,
    isNewsLetterEnabled: true,
    isOwnerPayEnabled: false,
    cloudPackage: 0, // personal
    smsRemaining: 0,
    defaultView: 4, // 4  : Status view
    language: 'en',
    apiReference: '',
    isHomeConnect: undefined,
    isSmartThings: false
  };
}
