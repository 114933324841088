import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  sendEmailVerification
} from 'firebase/auth';
import 'firebase/compat/firestore';
import FrontPage from './FrontPage';
import { StoreProvider, createStore } from 'easy-peasy';
import model from './model';
import * as utils from '../utils';
import User from '../components/User/user';
import FirebaseConfig from '../data/FirebaseConfig';
import LoginPage from './LoginPage';
import WizardLogIn from './DeviceRegistartionWizard/WizardLogIn';
import WizardWelcome from './DeviceRegistartionWizard/WizardWelcome';
//import WizardHandler from './DeviceRegistartionWizard/WizardHandler';

export default class Login extends Component {
  constructor(props) {
    console.log('Init');

    super(props);
    this.urlResult = new URLSearchParams(window.location.search);
    //console.log(this.urlResult.toString());
    this.pageIndex = 0;
    this.firebaseConfig = FirebaseConfig();
    this.app = initializeApp(this.firebaseConfig);

    this.setState({
      content: ''
    });
    this.stateChangeListener();
    this.store = createStore(model);
  }

  state = {
    loggedIn: false,
    content: <></>,
    pageIndex: 0
  };

  firebaseSignout(auth) {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log('Sign-out');
      })
      .catch(error => {
        // An error happened.
        console.error(error);
      });
  }

  stateChangeListener() {
    console.log('stateChangeListener');
    this.auth = getAuth(this.app);
    //console.log(this.auth);
    firebase.initializeApp(this.firebaseConfig);
    const db = firebase.firestore();

    onAuthStateChanged(this.auth, auth_user => {
      if (auth_user != null) {
        //console.log(auth_user);
        // if (!auth_user.emailVerified) {
        this.startRendering(auth_user, db);
        // } else {
        //   this.setState(() => ({
        //     pageIndex: 0,
        //     loggedIn: true,
        //     content: (
        //       <VerifyEmail
        //         auth_user={auth_user}
        //         firebaseAuth={firebase.auth()}
        //       />
        //     )
        //   }));
        // }
      } else {
        console.log('Not the user');

        var logInContent = this.getDefaultPage();

        this.setState(() => ({
          pageIndex: 0,
          loggedIn: true,
          content: logInContent
        }));
        //this.handlePageChange({ user: user, pageIndex: 0 });
      }
    });
  }
  // routeChange() {
  //   window.location.assign(
  //     'https://cloud.safera.com/index.html?' +
  //       this.urlResult.toString() +
  //       '&redirected=true',
  //     '_blank'
  //   );
  // }

  getDefaultPage() {
    var uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      signInSuccessUrl: '/index.html',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            prompt: 'select_account'
          }
        },
        firebase.auth.FacebookAuthProvider.PROVIDER_ID
      ]
    };

    var urlType;
    if (this.urlResult.has('redirected')) {
      urlType = 'deviceRegistration';
    } else if (this.urlResult.has('id')) {
      urlType = 'deviceRegistrationRedirect';
    }
    if (this.urlResult.has('safera_type')) {
      urlType = this.urlResult.get('safera_type');
      console.log('Type:', urlType);
    }

    var logInContent;

    switch (urlType) {
      case 'smartthings_auth':
        uiConfig.signInSuccessUrl =
          '/index.html?safera_type=' +
          urlType +
          '&callback=' +
          this.urlResult.get('id') +
          '&state=' +
          this.urlResult.get('state');
        logInContent = (
          <WizardLogIn
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
            type={'smartthings'}
          />
        );
        break;
      case 'deviceRegistrationRedirect':
        logInContent = <WizardWelcome />;
        // var link =
        //   'window.open(https://cloud.safera.com/index.html?' +
        //   this.urlResult.toString() +
        //   '&redirected=true, _blank)';
        // logInContent = <button onClick={link}>Click</button>;
        break;
      case 'deviceRegistration':
        //

        // logInContent = (
        //   <Router>
        //     <Switch>
        //       <Route path="/index.html">
        //         <WizardLogIn
        //           uiConfig={uiConfig}
        //           firebaseAuth={firebase.auth()}
        //           type={'deviceregistration'}
        //         />
        //       </Route>
        //     </Switch>
        //   </Router>
        // );

        logInContent = (
          <WizardLogIn
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
            type={'deviceregistration'}
          />
        );
        break;
      default:
        logInContent = (
          <LoginPage
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
            type={'default'}
          />
        );
        break;
    }
    return logInContent;
  }

  async startRendering(auth_user, db) {
    const user = await this.setNewUser(auth_user, db);
    if (user !== undefined) {
      this.setState(() => ({
        pageIndex: 1,
        loggedIn: true,
        content: (
          <StoreProvider store={this.store}>
            <FrontPage
              firebaseSignout={this.firebaseSignout}
              auth={this.auth}
              db={db}
              user={user}
              auth_user={auth_user}
              sendEmailVerification={sendEmailVerification}
            />
          </StoreProvider>
        )
      }));
    }
  }

  async setNewUser(auth_user, db) {
    try {
      //console.log(auth_user);
      const userRef = db.collection(utils.USERS_KEY).doc(auth_user.uid);
      const doc = await userRef.get();
      var newUser = { ...User };
      //console.log(newUser);
      if (doc.exists) {
        newUser.email = doc.data().email;
        newUser.displayName = doc.data().username;
        newUser.photoURL = doc.data().profile_picture;
        newUser.phoneNumber = doc.data().phone_number;
        newUser.country = doc.data().country;
        newUser.city = doc.data().city;
        newUser.company = doc.data().company;
        newUser.deviceID = doc.data().deviceID;
        newUser.created_timestamp = doc.data().created_timestamp;
        newUser.uid = auth_user.uid;
        newUser.emailVerified = auth_user.emailVerified;
        newUser.policyAccepted = doc.data().policy_accepted;
        newUser.policyAcceptedTimestamp = doc.data().policy_accepted_timestamp;
        newUser.admin =
          doc.data().admin !== undefined ? doc.data().admin : false;
        newUser.defaultView =
          doc.data().default_view !== undefined
            ? doc.data().default_view
            : utils.VIEW_TYPE['status'];
        newUser.isWeeklyReportEnabled =
          doc.data().isWeeklyReportEnabled !== undefined
            ? doc.data().isWeeklyReportEnabled
            : true;
        newUser.isMonthlyReportEnabled =
          doc.data().isMonthlyReportEnabled !== undefined
            ? doc.data().isMonthlyReportEnabled
            : true;
        newUser.isNewsLetterEnabled =
          doc.data().isNewsLetterEnabled !== undefined
            ? doc.data().isNewsLetterEnabled
            : true;
        newUser.isOwnerPayEnabled =
          doc.data().isOwnerPayEnabled !== undefined
            ? doc.data().isOwnerPayEnabled
            : false;
        newUser.isHomeConnect = doc.data().hc_active;

        newUser.language =
          doc.data().language !== undefined ? doc.data().language : 'en';
        newUser.cloudPackage =
          doc.data().cloud_package !== undefined ? doc.data().cloud_package : 0;
        newUser.apiReference =
          doc.data().api_ref !== undefined ? doc.data().api_ref : '';

        let userSmsRef = db
          .collection(
            utils.USERS_KEY + '/' + auth_user.uid + '/' + utils.SMS_KEY
          )
          .doc(utils.SMS_POOL_KEY);
        let smsDoc = await userSmsRef.get();
        if (smsDoc.exists) {
          newUser.smsRemaining =
            smsDoc.data().remaining !== undefined ? smsDoc.data().remaining : 0;
        } else {
          newUser.smsRemaining = 0;
        }
        if (this.urlResult.has('analytics_id')) {
          newUser.analytics_id = this.urlResult.get('analytics_id');
        }
      } else {
        console.log('New User');

        newUser.uid = auth_user.uid;
        newUser.displayName = auth_user.displayName;
        newUser.email = auth_user.email;
        newUser.photoURL =
          auth_user.photoURL != undefined
            ? auth_user.photoURL
            : '/advanced/assets/images/icon/user_avatar.png';
        newUser.phoneNumber =
          auth_user.phoneNumber != undefined ? auth_user.phoneNumber : '';
        newUser.isWeeklyReportEnabled = false;
        newUser.isMonthlyReportEnabled = false;
        newUser.isNewsLetterEnabled = false;
        newUser.defaultView = 4;
        newUser.cloudPackage = utils.CLOUD_PACKAGE['personal'];
        newUser.policyAccepted = false;
        newUser.policyAcceptedTimestamp = 0;
        if (this.urlResult.has('analytics_id')) {
          newUser.analytics_id = this.urlResult.get('analytics_id');
        }

        await this.saveNewUser(newUser, db);
      }

      //this.setUser(newUser);
      return Promise.resolve(newUser);
    } catch (error) {
      console.error(error);
      return Promise.resolve();
    }
  }

  async saveNewUser(usr, db) {
    try {
      if (usr.policyAccepted) {
        usr.policyAcceptedTimestamp = Date.now();
      }
      const newUsrRef = db.collection(utils.USERS_KEY).doc(usr.uid);
      var data = {
        username: usr.displayName !== undefined ? usr.displayName : '',
        email: usr.email,
        profile_picture: usr.photoURL,
        phone_number: usr.phoneNumber,
        created_timestamp: Date.now(),
        isWeeklyReportEnabled: usr.isWeeklyReportEnabled,
        isMonthlyReportEnabled: usr.isMonthlyReportEnabled,
        isNewsLetterEnabled: usr.isNewsLetterEnabled,
        default_view: usr.defaultView,
        cloud_package: usr.cloudPackage,
        policy_accepted: usr.policyAccepted,
        policy_accepted_timestamp: usr.policyAcceptedTimestamp
      };
      if (usr.analytics_id != undefined) {
        data.analytics_id = usr.analytics_id;
      }
      await newUsrRef.set(data, { merge: true });

      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  }

  render() {
    return this.state.content;
  }
}

Login.propTypes = {
  setNewUser: PropTypes.func,
  prevState: PropTypes.node,
  updateUser: PropTypes.func,
  user: PropTypes.object
};
