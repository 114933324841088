/**
 * Event table.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import AdvanceTable from '../../../../components/common/advance-table/AdvanceTable';
//import AdvanceTablePagination from '../../../../components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../../../components/common/advance-table/AdvanceTableWrapper';
import LongInternationalTimeFormat from '../../../../components/LongInternationalTimeFormat';
import EventCode from '../../../../components/EventCode';
import useTranslation from 'hooks/translations';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

export default function EventTable({ eventData, language }) {
  //console.log(eventData);

  const translation = useTranslation();
  const data = [];

  for (const event of eventData) {
    //console.log(event);
    data.push({
      timestamp: LongInternationalTimeFormat(event.timestamp * 1000, language),
      description: EventCode(event, translation).titleStr
    });
  }

  const columns = [
    {
      accessor: 'timestamp',
      Header: translation.date
    },
    {
      accessor: 'description',
      Header: translation.event
    }
  ];

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      sortable
      pagination
      perPage={10}
    >
      <AdvanceTable
        table
        headerClassName="bg-white text-black text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          bordered: false,
          striped: true,
          className: 'fs--1 mb-0 overflow-hidden'
        }}
      />
      <div className="mt-3">
        {/* <AdvanceTablePagination table /> */}
        <AdvanceTableFooter
          rowCount={data.length}
          table
          rowInfo
          navButtons
          rowsPerPageSelection
        />
      </div>
    </AdvanceTableWrapper>
  );
}

EventTable.propTypes = {
  eventData: PropTypes.array,
  language: PropTypes.string
};
