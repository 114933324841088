/**
 * Status icon.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, OverlayTrigger, Breadcrumb } from 'react-bootstrap';
import StatusPopover from './StatusPopover';
import { v4 as uuidv4 } from 'uuid';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import LongInternationalTimeFormat from '../../../components/LongInternationalTimeFormat';
import { useStoreState } from 'easy-peasy';
import useTranslation from 'hooks/translations';

export default function StatusIcon({ device, user }) {
  const translation = useTranslation();
  if (user == undefined) {
    user = useStoreState(state => state.user);
  }
  var lastActivityTime = '';

  if (device.data !== undefined) {
    if (device.data.last_activity_timestamp !== undefined) {
      lastActivityTime = LongInternationalTimeFormat(
        device.data.last_activity_timestamp,
        user.language
      );
    }
  }

  const popContent = {
    key: uuidv4(),
    header: translation.device_status_ok_heading,
    statusIcon: faCheckCircle,
    statusColor: 'lh-base fa-lg text-success',
    statusIconColor: 'text-success me-2',
    popBody: (
      <div>
        <p>
          {translation.device_status_ok_p1a} '{device.name}'{' '}
          {translation.device_status_ok_p1b}
        </p>
        <p>
          <strong>{translation.m020_wifi_disconnected_p3}</strong>
        </p>
        <p>{lastActivityTime}</p>
      </div>
    ),
    popFooter: (
      <div>
        <div>
          <hr />
        </div>
        <p className="text-muted fs--1">{translation.device_status_footer}</p>
      </div>
    )
  };

  var eventList = StatusPopover(device, user);
  const [activePage, setActivePage] = useState(0);
  const [pop, setPop] = useState(
    eventList[0] !== undefined ? eventList[0] : popContent
  );

  useEffect(() => {
    setPop(eventList[0] !== undefined ? eventList[0] : popContent);
  }, [device]);

  function handlePage(page) {
    setActivePage(page);
    if (eventList.length > 0) {
      setPop(eventList[page]);
    } else {
      setPop(popContent);
    }
  }

  const popover = (
    <Popover id="popover-status" style={{ margin: 0 }}>
      <Popover.Header as="h3">{translation.device_status}</Popover.Header>
      <Popover.Body>
        <>
          <Breadcrumb>
            <p>{translation.notification} &ensp;</p>
            {eventList.map((element, index) => (
              <Breadcrumb.Item
                key={element.key}
                onClick={() => handlePage(index)}
                active={activePage === index ? true : false}
              >
                {index + 1}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <div>
            <h4>
              <FontAwesomeIcon
                icon={pop.statusIcon}
                className={pop.statusIconColor}
              />
              {pop.header}
            </h4>
          </div>
          {pop.popBody}
          {pop.popFooter}
        </>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="col-1 d-flex justify-content-center align-items-center p-0">
        <a
          className="d-flex align-items-center"
          data-bs-toggle="popover"
          title="Laitteen tila"
          data-bs-content='<div><h2><i className="fas fa-check-circle text-success me-2"></i>Laite on toimintakunnossa</h2></div> <div> <p>Liesivahti &apos;Vanhempien Koti&apos; on toimintakunnossa ja yhteydessä Wi-Fi -verkkoon ja Safera Cloud -palveluun normaalisti.</p> <p><strong>Laite viimeksi ollut yhteydessä:</strong></p> <p>Pe 18.3.2021 klo 00.00</p> </div> <div><hr></div> <div> <p className="text-muted fs--1"> Laite lähettää tiedot pilvipalveluun kerran vuorokaudessa säästääkseen paristoja. Tärkeät etähälytykset ja viestit huoltotarpeista laite lähettää kuitenkin heti tarpeen mukaan. </p> </div>'
          data-bs-placement="bottom"
          data-bs-html="true"
        >
          <i className="fas fa-check-circle text-success lh-base fa-lg"></i>
        </a>
      </div>

      <OverlayTrigger
        trigger="click"
        rootClose
        placement="right"
        overlay={popover}
      >
        <a style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={pop.statusIcon} className={pop.statusColor} />
        </a>
      </OverlayTrigger>
    </>
  );
}

StatusIcon.propTypes = { device: PropTypes.object, user: PropTypes.object };
