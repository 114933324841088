/**
 * Prosess sensor data to Chart mode.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import * as utils from '../../../utils';

export default function Collect30minSensorData(deviceDocData) {
  var sensorDataArray = [];
  //console.log(deviceDocData);
  var ambientMax = 50;
  for (var key in deviceDocData.sensor_data) {
    var sensorObj = {};

    sensorObj[utils.SENSOR_TIMESTAMP_NAME] =
      (deviceDocData.sensor_data[key][utils.SENSOR_TIMESTAMP_NAME] -
        deviceDocData.timezone_sec) *
      1000;
    var num = parseFloat(
      deviceDocData.sensor_data[key][utils.TEMP_AMBIENT_30MIN_NAME],
      10
    );
    if (
      deviceDocData.sensor_data[key][utils.TEMP_AMBIENT_30MIN_NAME] == 0 &&
      deviceDocData.sensor_data[key][utils.RH_30MIN_NAME] == 0 &&
      deviceDocData.sensor_data[key][utils.ECO2_30MIN_NAME] == 0 &&
      deviceDocData.sensor_data[key][utils.TVOC_30MIN_NAME] == 0 &&
      deviceDocData.sensor_data[key][utils.PM25_30MIN_NAME] == 0
    ) {
      sensorObj[utils.TEMP_AMBIENT_NAME] = 0;
    } else {
      sensorObj[utils.TEMP_AMBIENT_NAME] = parseFloat(
        (num / 100 - 50).toFixed(2)
      );
    }

    sensorObj[utils.RH_NAME] =
      deviceDocData.sensor_data[key][utils.RH_30MIN_NAME];
    sensorObj[utils.ECO2_NAME] =
      deviceDocData.sensor_data[key][utils.ECO2_30MIN_NAME];
    sensorObj[utils.TVOC_NAME] =
      deviceDocData.sensor_data[key][utils.TVOC_30MIN_NAME] * 100;
    sensorObj[utils.PM25_NAME] =
      deviceDocData.sensor_data[key][utils.PM25_30MIN_NAME];
    sensorObj[utils.AQI_FINAL_NAME] =
      deviceDocData.sensor_data[key][utils.AQI_FINAL_NAME] * 2;

    sensorObj[utils.AMBIENT_ALARM_NAME] = 0;
    if (
      deviceDocData.sensor_data[key][utils.IS_ALARM_NAME] == 'true' ||
      deviceDocData.sensor_data[key][utils.IS_ALARM_NAME] == '1' ||
      deviceDocData.sensor_data[key][utils.IS_POWEROFF_NAME] == 'true' ||
      deviceDocData.sensor_data[key][utils.IS_POWEROFF_NAME] == '1'
    ) {
      sensorObj[utils.IS_ALARM_NAME] = utils.ALARM_ON_VALUE;
      sensorObj[utils.AMBIENT_ALARM_NAME] = ambientMax;
    } else {
      sensorObj[utils.IS_ALARM_NAME] = 0;
    }

    // if (
    //   deviceDocData.sensor_data[key][utils.IS_POWEROFF_NAME] == 'true' ||
    //   deviceDocData.sensor_data[key][utils.IS_POWEROFF_NAME] == '1'
    // ) {
    //   sensorObj[utils.IS_POWEROFF_NAME] = utils.ALARM_ON_VALUE + 10;
    //   sensorObj[utils.AMBIENT_ALARM_NAME] = ambientMax;
    // } else {
    //   sensorObj[utils.IS_POWEROFF_NAME] = 0;
    // }

    if (
      deviceDocData.sensor_data[key][utils.IS_COOKING_NAME] == 'true' ||
      deviceDocData.sensor_data[key][utils.IS_COOKING_NAME] == '1'
    ) {
      sensorObj[utils.IS_COOKING_NAME] = utils.ALARM_ON_VALUE;
      sensorObj[utils.AMBIENT_COOKING_NAME] = ambientMax;
    } else {
      sensorObj[utils.IS_COOKING_NAME] = 0;
      sensorObj[utils.AMBIENT_COOKING_NAME] = 0;
    }
    /*
      var t_max = parseFloat(deviceDocData.temp_ambient_max, 10).toFixed(2);
      sensorObj[TEMP_MAX] = t_max / 100 - 50;
      var t_min = parseFloat(deviceDocData.temp_ambient_min, 10).toFixed(2);
      sensorObj[TEMP_MIN] = t_min / 100 - 50;
      sensorObj[RH_MIN] = deviceDocData.rh_min;
      sensorObj[RH_MAX] = deviceDocData.rh_max;
  */
    sensorDataArray.push(sensorObj);
  }
  sensorDataArray.sort((a, b) =>
    a.sensor_timestamp < b.sensor_timestamp ? 1 : -1
  );

  return sensorDataArray;
}
