import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import './assets/scss/Safera-Components.scss';
import './assets/scss/Safera-React.scss';
import LanguageContextProvider from './context/LanguageContext';
//import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

// const TRACKING_ID = 'G-GSLG75145K'; // Personal test ID
// ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: 'GTM-NRMK6D7',
  dataLayerName: 'SaferaDataLayer'
};
TagManager.initialize(tagManagerArgs);
ReactDOM.render(
  <React.StrictMode>
    <Main>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </Main>
  </React.StrictMode>,
  document.getElementById('main')
);
