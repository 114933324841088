/**
 * Move Device to Group.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import * as utils from '../../../utils';
import CheckAccess from '../CheckAccess';

export default async function MoveDevice(db, groupId, device, uid) {
  try {
    console.log('MOVE', device);

    if (device.isGuest) {
      const guestGrbRef = db
        .collection(utils.USERS_KEY + '/' + uid + '/' + utils.DEVICE_KEY)
        .doc(device.docId);
      await guestGrbRef.set({ groupId: groupId }, { merge: true });
    } else {
      const access = await CheckAccess(db, device.device_id, uid);
      if (access.isValid && access.isOwner) {
        const grbRef = db.collection(utils.DEVICE_KEY).doc(device.device_id);
        await grbRef.set({ groupId: groupId }, { merge: true });
      } else {
        return false;
      }
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
