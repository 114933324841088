/**
 * Get sotove type name
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';

export default function GetStoveTypeName(type, translation) {
  try {
    var typeName;
    if (translation === undefined) {
      return;
    }
    switch (type) {
      case utils.STOVE_TYPE['RT_UNKNOWN']:
        typeName = translation.stove;
        break;
      case utils.STOVE_TYPE['RT_OVEN']:
        typeName = translation.oven;
        break;
      case utils.STOVE_TYPE['RT_CERAMIC']:
        typeName = translation.ceramic;
        break;
      case utils.STOVE_TYPE['RT_INDUCTION']:
        typeName = translation.induction;
        break;
      case utils.STOVE_TYPE['RT_IRON']:
        typeName = translation.iron;
        break;
      case utils.STOVE_TYPE['RT_IRON_COIL']:
        typeName = translation.iron_coil;
        break;
      case utils.STOVE_TYPE['RT_GAS']:
        typeName = translation.gas;
        break;
      case utils.STOVE_TYPE['DCKV_CERAMIC_HOB']:
        typeName = 'Ceramic Hob';
        break;
      case utils.STOVE_TYPE['DCKV_CHARCOAL_GRILL']:
        typeName = 'Charcoal Grill';
        break;
      case utils.STOVE_TYPE['DCKV_DEEP_FRYER']:
        typeName = 'Deep Fryer';
        break;
      case utils.STOVE_TYPE['DCKV_DISHWASHER']:
        typeName = 'Dishwasher';
        break;
      case utils.STOVE_TYPE['DCKV_ELECTRIC_FRY_TOP']:
        typeName = 'Electric Fry Top';
        break;
      case utils.STOVE_TYPE['DCKV_ELECTRIC_GRILL']:
        typeName = 'Electric Grill';
        break;
      case utils.STOVE_TYPE['DCKV_ELECTRIC_TEPPANYAKI']:
        typeName = 'Electric Teppanyaki';
        break;
      case utils.STOVE_TYPE['DCKV_ELECTRIC_WOK']:
        typeName = 'Electric Wok';
        break;
      case utils.STOVE_TYPE['DCKV_GAS_FRY_TOP']:
        typeName = 'Gas Fry Top';
        break;
      case utils.STOVE_TYPE['DCKV_GAS_GRILL']:
        typeName = 'Gas Grill';
        break;
      case utils.STOVE_TYPE['DCKV_GAS_HOB']:
        typeName = 'Gas Hob';
        break;
      case utils.STOVE_TYPE['DCKV_GAS_TEPPANYAKI']:
        typeName = 'Gas Teppanyaki';
        break;
      case utils.STOVE_TYPE['DCKV_GAS_WOK']:
        typeName = 'Gas Wok';
        break;
      case utils.STOVE_TYPE['DCKV_INDUCTION_HOB']:
        typeName = 'Induction Hob';
        break;
      case utils.STOVE_TYPE['DCKV_IRON_HOB']:
        typeName = 'Iron Hob';
        break;
      case utils.STOVE_TYPE['DCKV_OVEN']:
        typeName = 'Oven';
        break;
      case utils.STOVE_TYPE['DCKV_PRESSURE_OVEN']:
        typeName = 'Pressure Oven';
        break;
      case utils.STOVE_TYPE['DCKV_PRESS_GRILL']:
        typeName = 'Press Grill';
        break;
      default:
        typeName = translation.unknown;
        break;
    }
    return typeName;
  } catch (error) {
    console.error(error);
    return;
  }
}
