/**
 * Payment Page.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useStoreState } from 'easy-peasy';
import * as utils from '../../../utils';
import { v4 as uuidv4 } from 'uuid';
import ShortInternationalDateFormat from 'components/ShortInternationalDateFormat';
import LongInternationalTimeFormat from 'components/LongInternationalTimeFormat';
import useTranslation from 'hooks/translations';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

export default function Payment({ user }) {
  const [show, setShow] = useState(false);
  const [body, setBody] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const translation = useTranslation();
  const paypalSubs = useStoreState(state => state.paypalSubscriptions);
  var activeSubscription;
  var subscriptionStatus = (
    <span className="text-warning">{translation.no_active_subscriptions}</span>
  );
  var paymentItems = [];
  var extraPaymentAccounts = [];

  if (paypalSubs !== undefined) {
    for (const sub of paypalSubs) {
      if (sub.status == utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
        if (activeSubscription !== undefined) {
          extraPaymentAccounts.push({
            key: uuidv4(),
            content: paymentAccount(sub)
          });
        } else {
          activeSubscription = sub;
        }
      }
      if (sub.history !== undefined) {
        for (const item of sub.history) {
          paymentItems.push(getPaymentItem(item));
        }
      }
    }

    if (activeSubscription !== undefined) {
      subscriptionStatus = (
        <span className="text-success">
          {translation.subscription_is_active}
        </span>
      );
    }
  }

  function getPaymentItem(item) {
    var date = ShortInternationalDateFormat(
      new Date(item.create_time).getTime(),
      user.language
    );
    var currency = item.currency_code;
    if (item.currency_code === 'EUR') {
      currency = '€';
    } else if (item.currency_code === 'USD') {
      currency = '$';
    }
    return {
      key: uuidv4(),
      content: (
        <div className="flex-column d-flex align-items-center">
          <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link m-0 w-100">
            <div className="col-auto flex-fill p-0 d-flex justify-content-between">
              <h4>{date}</h4>
            </div>
            <div className="col-2 d-flex d-md-flex justify-content-end p-0">
              <div className="dropdown d-flex justify-content-end w-100">
                <button
                  className="btn bg-white d-inline-flex justify-content-end px-0 w-100 pt-0"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  type="button"
                >
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    className="text-primary"
                  />
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <a
                    className="dropdown-item"
                    onClick={() => setBillingInfo(item)}
                  >
                    {translation.information}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link m-0 w-100">
            <div className="col-9 flex-fill p-0">
              <p className="mb-0">{translation.add_on_sms_alarms}</p>
            </div>
            <div className="col-3 text-truncate d-flex justify-content-end p-0">
              <p className="mb-0">
                {item.total} {currency}
              </p>
            </div>
          </div>
          <div className="row w-100">
            <div className="col p-0">
              <hr />
            </div>
          </div>
        </div>
      )
    };
  }

  function setBillingInfo(item) {
    if (item == undefined) {
      setBody('');
      return;
    }

    setBody(
      <div>
        <h4>{item.summary}</h4> <br />
        <Table responsive>
          <tbody>
            <tr>
              <td>{translation.date}:</td>
              <td>
                {LongInternationalTimeFormat(
                  new Date(item.create_time).getTime(),
                  user.language
                )}
              </td>
            </tr>
            <tr>
              <td>{translation.order_id}:</td>
              <td>{item.order_id != undefined ? item.order_id : ''}</td>
            </tr>
            <tr>
              <td>{translation.amount}:</td>
              <td>
                {item.total != undefined ? item.total : ''}{' '}
                {item.currency_code != undefined ? item.currency_code : ''}
              </td>
            </tr>
            <tr>
              <td>{translation.status}:</td>
              <td>{item.state != undefined ? item.state : ''}</td>
            </tr>
          </tbody>
        </Table>
        <p className="">
          {translation.brand_company}
          <br />
          {translation.m007_thanks_for_order_support_site}
        </p>
      </div>
    );
    handleShow();
  }

  function setAccountInfo(sub) {
    if (sub == undefined) {
      setBody('');
      return;
    }
    var subscriber = {};
    if (sub.subscriber != undefined) {
      subscriber = JSON.parse(sub.subscriber);
    }
    setBody(
      <div>
        <h4>{sub.summary}</h4> <br />
        <h5>{translation.subscriber}</h5>
        <p>
          {translation.email}:{' '}
          {subscriber.email_address != undefined
            ? subscriber.email_address
            : ''}
          <br />
          {translation.name}:{' '}
          {subscriber.name != undefined ? subscriber.name.given_name : ''}{' '}
          {subscriber.name != undefined ? subscriber.name.surname : ''}
        </p>
        <Table responsive>
          <tbody>
            <tr>
              <td>{translation.order_date}:</td>
              <td>
                {ShortInternationalDateFormat(
                  new Date(sub.create_time).getTime(),
                  user.language
                )}
              </td>
            </tr>
            <tr>
              <td>{translation.order_id}:</td>
              <td>{sub.order_id != undefined ? sub.order_id : ''}</td>
            </tr>
            <tr>
              <td>{translation.order_type}:</td>
              <td>{sub.resource_type != undefined ? sub.resource_type : ''}</td>
            </tr>
            <tr>
              <td>{translation.order_status}:</td>
              <td>{sub.status != undefined ? sub.status : ''}</td>
            </tr>
          </tbody>
        </Table>
        <p className="">
          {translation.brand_company}
          <br />
          {translation.m007_thanks_for_order_support_site}
        </p>
      </div>
    );
    handleShow();
  }

  function paymentAccount(sub) {
    console.log(sub);
    if (sub !== undefined) {
      if (sub.status == utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
        subscriptionStatus = (
          <>
            <span className="text-success">
              {translation.subscription_is_active} &nbsp;
            </span>
          </>
        );
      }
    }
    return (
      <>
        <div className="card shadow">
          <div className="card-body px-3 py-3 pb-3">
            <div className="d-flex align-items-center w-100">
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <div className="w-100">
                  <div className="container p-0">
                    <div className="row d-flex flex-grow-0 align-items-center flex-nowrap panel-item-heading-with-link">
                      <div className="col-auto">
                        <img
                          className="flex-shrink-0"
                          src="./img/paypal-logo.jpg"
                          width="60px"
                        />
                      </div>
                      <div className="col text-truncate d-flex flex-grow-1 p-0">
                        <div className="row flex-column">
                          <div className="col">
                            <h3 className="text-truncate text-break">PayPal</h3>
                          </div>
                          <div className="col">{subscriptionStatus}</div>
                        </div>
                      </div>
                      <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-nowrap h-100">
                        <div className="dropdown d-flex justify-content-end w-100">
                          <button
                            className="btn bg-white d-inline-flex justify-content-end p-0 w-100"
                            aria-expanded="false"
                            data-bs-toggle="dropdown"
                            type="button"
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisH}
                              className="text-primary"
                            />
                          </button>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              className="dropdown-item"
                              onClick={() => setAccountInfo(sub)}
                            >
                              {translation.information}
                              <i className="fas fa-external-link-alt text-primary ms-2"></i>
                            </a>
                            <a
                              className="dropdown-item"
                              href="https://www.paypal.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {translation.signing_in_to_paypal}
                              <i className="fas fa-external-link-alt text-primary ms-2"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <h6>{translation.payment_method}</h6>
      <div className="container p-0 pb-3">
        {paymentAccount(activeSubscription)}
        {extraPaymentAccounts.map(item => (
          <div key={item.key} className="mt-1">
            {item.content}{' '}
          </div>
        ))}
      </div>
      <h6 className="mt-4">{translation.transactions}</h6>
      <div className="container p-0 pb-3">
        <div className="card shadow">
          <div className="card-body px-3 py-3 pb-4">
            {paymentItems.map(item => (
              <div key={item.key}>{item.content}</div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>PayPal</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

Payment.propTypes = {
  user: PropTypes.object
};
