/**
 * Personal home page.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from './components/Header';
import DeviceList from './components/DeviceList';
import DeviceListHeader from './components/DeviceListHeader';
import useTranslation from 'hooks/translations';
import { useStoreState } from 'easy-peasy';
import spinGif from 'assets/img/Ellipsis58px.svg';

export default function PersonalHomePage({ user, setUser, logout, setPage }) {
  const translation = useTranslation();
  const scrollRef = useRef();
  const [opacity, setOpacity] = useState(false);
  //const [isPending, startTransition] = useTransition();

  const loading = useStoreState(state => state.loading);
  const [loadingTxt, setLoading] = useState('');

  useEffect(() => {
    if (loading) {
      //if (devices.length == 0) {
      setLoading(<img src={spinGif} alt="loading..." />);
      //}
    } else {
      setLoading('');
    }
  }, [loading]);

  function scrolled() {
    //var opacityVal = getOpacityVal();
    // startTransition(() => {
    //console.log(document.getElementById('personal-home-page').scrollTop);
    if (opacity) {
      if (document.getElementById('personal-home-page').scrollTop < 60) {
        setOpacity(false);
      }
    } else {
      if (document.getElementById('personal-home-page').scrollTop > 40) {
        setOpacity(true);
      }
    }

    // });
  }

  // function getOpacityVal() {
  //   if (document.getElementById('personal-home-page') === null) {
  //     return false;
  //   }
  //   var scrollPosition =
  //     document.getElementById('personal-home-page').scrollTop / 100;
  //   console.log(scrollPosition);
  //   if (scrollPosition > 0.3) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  //   //console.log(opacityVal);
  // }

  return (
    <div
      className="d-flex flex-column home-page h-100"
      id="personal-home-page"
      onScroll={scrolled}
      ref={scrollRef}
    >
      <Header user={user} setUser={setUser} setPage={setPage} logout={logout} />

      <div className="text-center fixed-headings">
        <DeviceListHeader opacity={opacity} />
        <p>{loadingTxt} </p>
      </div>
      <div className="space-for-headings"></div>

      <div className="container">
        <div className="flex-row justify-content-center device-cards-container row gx-3 gy-3">
          <DeviceList setPage={setPage} user={user} />
        </div>
      </div>
      <footer className="text-light d-flex flex-grow-1 justify-content-center align-items-end footer-styling mt-4">
        <p>©&nbsp;{translation.brand_company}</p>
      </footer>
    </div>
  );
}

PersonalHomePage.propTypes = {
  props: PropTypes.object,
  user: PropTypes.object,
  setUser: PropTypes.func,
  logout: PropTypes.func,
  setPage: PropTypes.func
};
