/**
 * Notifications contact info.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import * as utils from '../../../../utils';
import useTranslation from 'hooks/translations';

export default function NotificationContact({ setPage, user }) {
  const translation = useTranslation();
  return (
    <>
      <h6>{translation.recipient_of_remote_alarms}</h6>
      <div className="container p-0 pb-3">
        <div className="card shadow">
          <div className="card-body px-3 py-3 pb-3">
            <div className="d-flex align-items-center w-100">
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <div className="w-100">
                  <div className="container p-0">
                    <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                      <div className="col d-flex flex-grow-1">
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          className="fs-1 me-2"
                        />
                        <h4 className="text-break">{translation.my_account}</h4>
                      </div>
                      <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                        <a
                          className="text-break"
                          onClick={() =>
                            setPage({
                              page: utils.PERSONAL_PAGES['ACCOUNT_SETTINGS'],
                              subPage:
                                utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE[
                                  'CONTACT'
                                ]
                            })
                          }
                        >
                          {translation.edit}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-truncate mb-1">
                  <span className="text-break">{user.email}</span>
                </div>
                <div className="text-truncate">
                  <span className="text-break">{user.phoneNumber}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

NotificationContact.propTypes = {
  setPage: PropTypes.func,
  user: PropTypes.object
};
