/**
 * Safera brand translations (FR).
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

export default {
  m001_verification_subject:
    'Confirmez l’adresse e-mail de votre compte utilisateur Safera Cloud',
  m001_verification_heading: 'Bienvenue au service Safera Cloud',
  m001_verification_content_p1:
    'Votre nouveau compte Safera Cloud a été créé. Vous pouvez vous connecter au service :',
  m002_name_change_content_p1a:
    'Le nom du compte utilisateur Safera Cloud a été modifié. Le nouveau nom est',
  m002_name_change_subject:
    'Le nom du compte utilisateur Safera Cloud a été modifié',
  messages_footer_v1:
    'Avez-vous reçu ce message sans avoir créé de compte ? Consultez le site d’assistance produit Safera pour obtenir des instructions.',
  messages_footer_v2:
    'Si vous avez reçu ce message sans avoir apporté de modifications à votre compte, consultez le site d’assistance produit Safera pour obtenir des instructions.',
  m003_number_change_subject:
    'Le numéro de téléphone du compte utilisateur Safera Cloud a été modifié',
  m003_number_change_content_p1a:
    'Le numéro de téléphone du compte utilisateur Safera Cloud a été modifié. Le nouveau numéro est',
  m003_number_change_content_p1b:
    'Le numéro de téléphone du compte utilisateur Safera Cloud a été supprimé.',
  m004_new_device_subject_b:
    'a été ajouté à votre compte utilisateur Safera Cloud',
  m004_new_device_content_p1b:
    'a été ajouté à votre compte Safera Cloud, ce qui vous permet de bénéficier des avantages suivants :',
  m005_share_device_subject_b:
    'transfert ou partage vers votre compte utilisateur Safera Cloud',
  m005_share_device_p1a: 'L’utilisateur Safera Cloud',
  m005_share_device_p2:
    'Pour accepter ou rejeter la demande, connectez-vous à Safera Cloud :',
  m005_share_device_button: 'Connectez-vous à Safera Cloud',
  m006_add_device_reminder_subject:
    'Profitez des avantages de Safera Cloud - Connectez le produit Safera à votre compte',
  m007_thanks_for_order_how_to_cancel_content:
    'Vous pouvez résilier le service à tout moment en vous connectant à Safera Cloud et en sélectionnant Paramètres du compte dans votre compte.',
  m007_thanks_for_order_support_site:
    'Site d’assistance produit : support.safera.com',
  m007_thanks_for_order_support_contact: 'Contact : support@safera.com',
  m007_sms_thanks_for_order_p1a: 'L’utilisateur du service Safera Cloud',
  m009_trial_ending_subcription_continues_button:
    'Rédigez un avis sur votre produit Safera',
  m010_receipt_subject:
    'Votre reçu pour Safera Cloud - Service optionnel d’alarmes SMS 1 mois',
  m011_sms_service_ending_p2:
    'Si vous souhaitez réactiver les alarmes à distance par SMS, abonnez-vous aux alarmes SMS dans Safera Cloud :',
  m011_sms_p1a: 'Les alarmes SMS de Safera Cloud à ce numéro prendront fin le',
  m012_account_delete_subject:
    'La suppression de votre compte Safera Cloud est en cours',
  m012_account_delete_p1:
    'Votre demande de suppression de votre compte Safera Cloud est en cours et sera traitée dans un délai d’une semaine, après quoi vous ne pourrez plus vous connecter à votre compte.',
  m013_personal_information_subject:
    'Réponse à votre demande - Vos données personnelles stockées dans Safera Cloud',
  m013_personal_information_p1:
    'À votre demande, nous vous envoyons le fichier PDF chiffré joint contenant les données personnelles stockées et traitées dans Safera Cloud.',
  m013_personal_information_p3:
    'Si vous avez des questions, vous pouvez nous contacter à l’adresse tietosuoja@safera.fi.',
  m014_payment_failed_1_p1:
    'Safera Cloud n’a pas pu débiter la redevance pour le service :',
  m014_payment_failed_1_p3:
    'Vous pouvez également vous connecter au service en ouvrant l’appli mobile Safera App et en sélectionnant « Visiter Safera Cloud » dans le menu.',
  m014_sms_failed_1_p1:
    'Mettez à jour votre mode de paiement : Safera Cloud n’a pas pu débiter la redevance pour le service',
  m014_payment_failed_1_p2:
    'Mettez à jour le mode de paiement dans votre compte Safera Cloud sous « Détails de paiement » dès que possible afin de pouvoir continuer à utiliser le service sans interruption :',
  m014_payment_failed_1_p4:
    'Si vous ne souhaitez pas continuer à utiliser le service payant, vous pouvez résilier l’abonnement dans Safera Cloud sous Paramètres du compte.',
  m015_sms_payment_failed_2_p1a:
    'Mettez à jour votre mode de paiement : Les alarmes SMS de Safera Cloud à ce numéro prendront fin le',
  m016_payment_ok_subject: 'Fin du service d’alarmes SMS de Safera Cloud',
  m016_payment_ok_p2:
    'Pour vous réabonner aux alarmes SMS depuis les paramètres de votre appareil, connectez-vous à Safera Cloud :',
  m016_sms_payment_ok_p1:
    'Les alarmes SMS de Safera Cloud à ce numéro ont pris fin.',
  m017_payment_info_updated_subject:
    'Détails de paiement mis à jour - L’abonnement aux alarmes SMS de Safera Cloud a été activé',
  m017_payment_info_updated_p1:
    'L’abonnement aux alarmes SMS de Safera Cloud a été activé. Vous recevrez sous peu un reçu séparé pour votre paiement.',
  m018_replace_batteries_1_p3:
    'Lorsque le niveau de charge des piles est très faible, le dispositif passe en mode d’économie d’énergie et il ne sera alors plus connecté au service Safera Cloud et sa fonctionnalité sera limitée :',
  m018_replace_batteries_1_bullets_2:
    'Le dispositif n’enverra pas de messages d’erreur et son état de fonctionnement ne pourra pas être confirmé via le service Safera Cloud.',
  m018_replace_batteries_1_bullets_3:
    'Le dispositif n’enverra pas les données qu’il stocke à Safera Cloud.',
  m019_replace_batteries_2_p2:
    'Veuillez noter qu’avant le remplacement des piles et la reconnexion du dispositif au réseau Wi-Fi et au service Safera Cloud :',
  m019_sms_replace_batteries_p1b:
    'ne seront pas activés, car le dispositif ne peut pas se connecter au réseau Wi-Fi et au service Safera Cloud.',
  m020_wifi_disconnected_heading:
    'Le dispositif ne peut pas se connecter au réseau Wi-Fi et au service Safera Cloud',
  m020_wifi_disconnected_p2:
    'Veuillez noter que tant que le dispositif n’est pas reconnecté au service Safera Cloud :',
  m021_wifi_ok_heading:
    'Le dispositif est à nouveau opérationnel et connecté au service Safera Cloud',
  m021_sms_wifi_ok_p1b:
    'sont à nouveau activés, et le dispositif est connecté au réseau Wi-Fi et au service Safera Cloud.',
  m027_aq_alarm_p2:
    'Vous pouvez consulter l’historique de la qualité de l’air enregistré par le dispositif dans le service Safera Cloud :',
  m027_aq_alarm_p3:
    'Vous pouvez contrôler les seuils d’alarme de la qualité de l’air en vous connectant au dispositif via l’appli mobile Safera App.',
  i003_wifi_disconnected_p1:
    'Vous pouvez activer l’alarme à distance si le dispositif ne parvient pas à se connecter au réseau Wi-Fi et à Safera Cloud.',
  i003_wifi_disconnected_p2:
    'Si le dispositif n’est pas connecté au réseau Wi-Fi et à Safera Cloud :',
  device_status_ok_p1b:
    'est opérationnel et connecté au réseau Wi-Fi et à Safera Cloud normalement.',
  brand_name: 'Safera',
  site_name: 'Personal',
  full_brand_name: 'Safera Cloud Personal',
  brand_service_name: 'Service Safera Cloud Personal',
  short_brand_name: 'Safera Cloud',
  by_app: 'avec l’appli mobile Safera App',
  apps_name: 'de l’appli mobile Safera App',
  in_app: 'dans l’appli mobile Safera App',
  brand_company: 'Safera Oy',
  company_url: '',
  wizard_cloud_title: 'Mise en place du service Safera Cloud',
  wizard_cloud_log_in_heading:
    'Créez un nouveau compte ou connectez-vous à Safera Cloud',
  wizard_cloud_connect_device_p1:
    'Donnez au dispositif un nom vous permettant de le reconnaître dans Safera Cloud.',
  wizard_cloud_remote_alarms_p1:
    'Le dispositif enverra des alarmes à distance via Safera Cloud. Avec les paramètres par défaut, vous recevrez des alarmes à distance par e-mail sur les incidents liés à la cuisinière et le besoin de maintenance du dispositif.',
  wizard_cloud_connect_device_heading:
    'Connectez le dispositif à votre compte Safera Cloud',
  wizard_cloud_ready_p2a:
    'Vous pouvez vous connecter au service soit à partir du menu principal de l’appli Safera App, soit en y accédant depuis votre navigateur à l’adresse :',
  wizard_cloud_ready_p2b: 'https://cloud.safera.com',
  terms_and_policy_url: 'https://safera.imagerelay.com/share/gdpr-eng',
  terms_url:
    'https://safera.imagerelay.com/share/warranty-and-license-terms?_ga=2.86347672.1200527814.1584089005-136302795.1582637603',
  wizard_cloud_connect_device_failed2_button: 'Connectez-vous à Safera Cloud',
  wizard_cloud_connect_device_heading3:
    'Le dispositif est maintenant connecté à votre compte Safera Cloud',
  support_temp_error_url: 'https://safera.fi/guide/00',
  support_tof_error_url: 'https://safera.fi/guide/01',
  support_adc_error_url: 'https://safera.fi/guide/02',
  support_gas1_error_url: 'https://safera.fi/guide/03',
  support_gas2_error_url: 'https://safera.fi/guide/04',
  support_lpc_error_url: 'https://safera.fi/guide/05',
  support_orientation_error_url: 'https://safera.fi/guide/06',
  support_humidity_error_url: 'https://safera.fi/guide/07',
  support_invalid_sensor_orientation_url: 'https://safera.fi/guide/08',
  support_battery_low_error_url: 'https://safera.fi/guide/09',
  support_pcu_connection_error_url: 'https://safera.fi/guide/10',
  support_sensor_prosessor_error_url: 'https://safera.fi/guide/11',
  support_lens_dirty_error_url: 'https://safera.fi/guide/12',
  support_pcu_error_url: 'https://safera.fi/guide/13',
  support_pcu_voltage_error_url: 'https://safera.fi/guide/14',
  support_pcu_current_error_url: 'https://safera.fi/guide/15',
  support_pcu_lds_error_url: 'https://safera.fi/guide/16',
  support_pcu_prosessor_error_url: 'https://safera.fi/guide/17',
  support_pcu_twd_error_url: 'https://safera.fi/guide/18',
  support_pcu_power_supply_error_url: 'https://safera.fi/guide/19',
  support_pcu_relay_error_url: 'https://safera.fi/guide/20',
  support_pcu_overheating_error_url: 'https://safera.fi/guide/21',
  support_pcu_curmeas_diff_error_url: 'https://safera.fi/guide/22',
  support_pcu_curmeas_backup_error_url: 'https://safera.fi/guide/23',
  support_pcu_overcurrent_error_url: 'https://safera.fi/guide/24',
  support_pcu_z_jammed_error_url: 'https://safera.fi/guide/25',
  support_critical_error_pcu_shutdown_url: 'https://safera.fi/guide/26',
  support_offline_error_url: 'https://safera.fi/guide/27',
  support_acknowledging_an_alarm_url: 'https://safera.fi/guide/28',
  support_acknowledging_water_alarm_utl: 'https://safera.fi/guide/29',
  support_wifi_error_url: 'https://safera.fi/guide/44',
  support_home_page_url: 'https://support.safera.com/en/support/home',
  company_logo_url:
    'https://www.safera.com/wp-content/uploads/2020/10/safera-logo.png',
  company_linkedin_url: 'https://www.linkedin.com/company/safera-oy',
  company_facebook_url: 'https://www.facebook.com/stoveguard/',
  company_twitter_url: 'https://twitter.com/SAFERA_Oy',
  company_instagram_url: 'https://www.instagram.com/safera_oy/',
  company_support_email: 'support@safera.com',
  company_street_address: 'Safera Oy, Tekniikantie 4 B 02150 Espoo, Finland',
  smartthings_add_device_info_c: 'Select the Safera.'
};
