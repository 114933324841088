/**
 * Parse daylog data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import GetDayObjectV4 from './DayLogV4/GetDayObjectV4';
import GetDayObjectV6 from './DayLogV6/GetDayObjectV6';
import * as utils from 'utils';

export default function GetDayObject(doc) {
  var dayObj = {};
  if (doc == undefined) {
    return dayObj;
  }
  var recordVersion =
    doc.data()[utils.RECORD_VERSION_NAME] != undefined
      ? doc.data()[utils.RECORD_VERSION_NAME]
      : 0;
  switch (recordVersion) {
    case 4:
      dayObj = GetDayObjectV4(doc);
      break;
    case 6:
      dayObj = GetDayObjectV6(doc);
      break;
    default:
      break;
  }
  return dayObj;
}
