/**
 * Payment modal.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import PayPalBtn from './PayPalButton';
import useTranslation from 'hooks/translations';
import * as utils from 'utils';
import ToastMessage from '../ToastMessage';
import Modal from 'react-bootstrap/Modal';
import SetPayPalInProgress from 'components/DataBase/SetPayPalInProgress';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import LongInternationalTimeFormat from 'components/LongInternationalTimeFormat';
//import { useStoreState } from 'easy-peasy';
//import DisableSMS from 'components/DataBase/DisableSMS';
//import ActivePersonalSMS from 'components/DataBase/ActivePersonalSMS';

export default function PaymentModal({
  user,
  activeSub,
  trialModal,
  cancelModal,
  setInProgress,
  modalClosed,
  PayPalStatusHandler
}) {
  const uid = user.uid;
  const translation = useTranslation();
  const trialModalRef = useRef();
  const [show, setShow] = useState(false);
  const [toastContent, setToastContent] = useState({
    message: translation.history_view,
    success: true
  });
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);

  useEffect(() => {
    if (trialModal) {
      handleTrialModalShow();
    }
  }, [trialModal]);

  useEffect(() => {
    if (cancelModal) {
      handleCancelModalShow();
    }
  }, [cancelModal]);

  const [showTrialModal, setShowTrialModal] = useState(trialModal);
  const handleTrialModalClose = () => {
    modalClosed();
    setShowTrialModal(false);
  };
  const handleTrialModalShow = () => setShowTrialModal(true);

  const [showCancelModal, setShowCancelModal] = useState(cancelModal);
  const handleCancelModalClose = () => {
    modalClosed();
    setShowCancelModal(false);
  };
  const handleCancelModalShow = () => setShowCancelModal(true);

  const db = useStoreState(state => state.db);
  var planId = utils.PAYPAL_PLAN_ID;
  //console.log(activeSub);
  var paymentPeriodEnds = '';
  if (activeSub != undefined) {
    planId = activeSub.plan_id;
    if (activeSub.billing_info == undefined || activeSub.billing_info == '') {
      paymentPeriodEnds = <p>{getTrialEndTimeFromDate()}</p>;
    } else {
      paymentPeriodEnds = (
        <p>{getPaymenPeriodEnds(JSON.parse(activeSub.billing_info))}</p>
      );
    }
  }
  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: planId,
      custom_id: uid
    });
  };
  const paypalOnError = err => {
    console.log('Error: ', err);
    setToastContent({
      message: 'Payapl Subscription failed!',
      success: false
    });
    handleTrialModalClose();
    setShow(true);
  };
  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    console.log('Payapl approved');
    console.log(data);
    console.log(detail);

    payPalApproverHandler(data);
    handleTrialModalClose();
    setToastContent({
      message: 'PayPal approved',
      success: true
    });
    setShow(true);
  };

  async function payPalApproverHandler(data) {
    try {
      setInProgress();
      await SetPayPalInProgress(db, uid, data.subscriptionID);
      PayPalStatusHandler(data.subscriptionID);
    } catch (error) {
      console.error(error);
    }
  }

  const [paymentButton, setPaymentButton] = useState('');

  function getTrialEndTimeFromDate() {
    try {
      const now = new Date().getTime();
      const subscriptionCreated = new Date(activeSub.create_time).getTime();
      var start = moment(now);
      var end = moment(subscriptionCreated);
      const diffDays = start.diff(end, 'days');
      if (diffDays <= 30) {
        return LongInternationalTimeFormat(
          moment(subscriptionCreated).add(30, 'days').valueOf()
        );
      }
      return '';
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  function getPaymenPeriodEnds(billingInfo) {
    try {
      if (billingInfo.next_billing_time != undefined) {
        return LongInternationalTimeFormat(
          moment(new Date(billingInfo.next_billing_time)).valueOf()
        );
      } else if (billingInfo.last_payment != undefined) {
        return LongInternationalTimeFormat(
          moment(new Date(billingInfo.last_payment.time))
            .add(30, 'days')
            .valueOf()
        );
      }
      return '';
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  function activatePaymentButton() {
    var button = (
      <PayPalBtn
        amount="1"
        currency="USD"
        createSubscription={paypalSubscribe}
        onApprove={paypalOnApprove}
        catchError={paypalOnError}
        onError={paypalOnError}
        onCancel={paypalOnError}
      />
    );
    if (activeSub != undefined) {
      if (activeSub.status == utils.PAYPAL_SUBSCRIPTION_SUSPENDED) {
        button = (
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => activeSmsPlan(activeSub)}
          >
            {translation.activate_subscription}
          </button>
        );
      } else if (
        activeSub.status == utils.PAYPAL_SUBSCRIPTION_APPROVAL_PENDING
      ) {
        button = '';
      }
    }
    setPaymentButton(button);
  }

  async function activeSmsPlan(sub) {
    modalClosed();
    handleCancelModalClose();
    setShowTrialModal(false);
    await SetPayPalInProgress(db, uid, sub.order_id);
    updatePaypalPlan(utils.PAYPAL_ACTIVATE_SUBCRIPTION, sub);
  }

  function disablePaypalPlan(plan, sub) {
    modalClosed();
    handleCancelModalClose();
    updatePaypalPlan(plan, sub);
  }

  async function updatePaypalPlan(plan, sub) {
    const language = user.language != undefined ? user.language : 'en';
    const url =
      'https://europe-west1-safera-sense.cloudfunctions.net/PayPalSubscriptions?planId=' +
      sub.plan_id +
      '&uid=' +
      uid +
      '&orderId=' +
      sub.order_id +
      '&type=' +
      plan +
      '&language=' +
      language;

    console.log(url);
    window.location.replace(url);
  }

  function checkServicePolicy({ target }) {
    if (target.checked) {
      activatePaymentButton();
    } else {
      setPaymentButton('');
    }
    setIsPolicyChecked(target.checked);
  }

  return (
    <>
      <Modal
        role="dialog"
        tabIndex="-1"
        id="modal-start-trial"
        show={showTrialModal}
        onHide={handleTrialModalClose}
        ref={trialModalRef}
      >
        <Modal.Header className="pe-4 border-0">
          <button
            type="button"
            className="btn-close"
            onClick={handleTrialModalClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <h2 className="text-center">
            {translation.subscribe_to_sms_alarm_service}
          </h2>
          <hr />
          <p className="text-center text-black">
            {translation.sms_service_try_30_days_for_free_info_1} <br />
            <br />
            {translation.sms_service_try_30_days_for_free_info_2}{' '}
            {utils.SMS_SUBSCRIPTION_PRICE}
            {translation.per_month}.{' '}
            {translation.sms_service_try_30_days_for_free_info_3}
          </p>
          <hr />
          <div className="col">
            <div className="form-check d-flex align-items-start form-switch mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="paymentCheck"
                onChange={checkServicePolicy}
                defaultChecked={isPolicyChecked}
              />
              <label
                className="form-check-label mt-1 text-black"
                htmlFor="paymentCheck"
              >
                {translation.i_agree_to_the_terms_and_policy}
                {'. '}
                <a
                  className="text-break"
                  href={translation.terms_and_policy_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {translation.open}
                </a>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          disabled={true}
          className="d-flex justify-content-center border-0 pt-0 pb-4"
        >
          {paymentButton}
          <div id="paypal-button-container"></div>
        </Modal.Footer>
      </Modal>
      <Modal
        role="dialog"
        tabIndex="-1"
        id="modal-cancel-trial"
        show={showCancelModal}
        onHide={handleCancelModalClose}
      >
        <Modal.Header className="pe-4 border-0">
          <button
            type="button"
            className="btn-close"
            onClick={handleCancelModalClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <h2 className="text-center">{translation.cancel_payment_1}</h2>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-center text-warning w-100 fs-4 mt-2"
          />
          <hr />
          <p className="text-start text-black">
            {translation.cancel_payment_2}
          </p>
          {paymentPeriodEnds}
          <hr />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center border-0 pt-0 pb-4">
          <div className="col">
            <button
              type="button"
              className="btn btn-light w-100 shadow mb-3"
              onClick={handleCancelModalClose}
            >
              {translation.cancel_payment_3}
            </button>
            <button
              type="button"
              className="btn btn-primary w-100 shadow"
              onClick={() =>
                disablePaypalPlan(utils.PAYPAL_SUSPEND_SUBCRIPTION, activeSub)
              }
            >
              {translation.cancel_payment_4}
            </button>
            {/* <button
              type="button"
              className="btn btn-primary w-100 shadow mt-3"
              onClick={() =>
                disablePaypalPlan(utils.PAYPAL_CANCEL_SUBSCRIPTION, activeSub)
              }
            >
              Lopeta
            </button> */}
          </div>
        </Modal.Footer>
      </Modal>
      <ToastMessage
        setShow={setShow}
        success={toastContent.success}
        show={show}
        message={toastContent.message}
      />
    </>
  );
}

PaymentModal.propTypes = {
  user: PropTypes.object,
  activeSub: PropTypes.object,
  trialModal: PropTypes.bool,
  cancelModal: PropTypes.bool,
  setInProgress: PropTypes.func,
  modalClosed: PropTypes.func,
  PayPalStatusHandler: PropTypes.func
};
