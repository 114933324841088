/**
 * Create a Group.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import * as utils from '../../../utils';

export default async function CreateGroup(db, newName, device, uid) {
  try {
    //console.log(db, groupId);
    var groupId;
    if (device.isGuest) {
      const guestGrbRef = db.collection(
        utils.USERS_KEY + '/' + uid + '/' + utils.GROUP_KEY
      );
      const res = await guestGrbRef.add({ isActive: true, name: newName });
      groupId = res.id;
    } else {
      const grbRef = db.collection(utils.GROUP_KEY);
      const grbRes = await grbRef.add({ isActive: true, name: newName });
      groupId = grbRes.id;
    }
    return groupId;
  } catch (err) {
    console.error(err);
    return;
  }
}
