import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Dropdown } from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';
//import useTranslation from 'hooks/translations';

export default function MoveGroupModal({
  showModal,
  handleClose,
  groupId,
  moveGroup
}) {
  var groups = useStoreState(state => state.groups);
  const [dropTitle, setDropTitle] = useState('Select one');
  //const translation = useTranslation();

  const [selectedGroupId, setSelectedGroupId] = useState(undefined);
  var myGroupName = '';

  var groupList = [];
  for (const grbId in groups) {
    if (grbId == groupId) {
      myGroupName = groups[grbId].name;
      if (groups[grbId].parent != undefined && groups[grbId].parent != '') {
        groupList.unshift({ groupId: '', name: 'Root' });
      }
    } else {
      //console.log(groups[grbId]);
      groupList.push({ groupId: grbId, name: groups[grbId].name });
    }
  }
  var dropdown = (
    <Dropdown className="mb-3">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {dropTitle}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {groupList.map(group => (
          <Dropdown.Item
            key={uuidv4()}
            onClick={() => groupSelected(group.groupId)}
          >
            {group.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  function groupSelected(grbId) {
    console.log(grbId);
    if (grbId == '') {
      setDropTitle('Root');
    } else {
      setDropTitle(groups[grbId].name);
    }
    setSelectedGroupId(grbId);
  }

  function moveGroupHandler() {
    if (selectedGroupId == undefined) {
      console.log('Select group first');
    } else {
      moveGroup(selectedGroupId);
      handleClose();
    }
  }
  return (
    <>
      <Modal role="dialog" tabIndex="-1" id="modal-move-group" show={showModal}>
        <Modal.Header className="pe-4 border-0">
          <h4 className="modal-title text-break pe-5">
            {myGroupName}
            <br />
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <label className="pb-2">Select the group you want to move to.</label>
          {dropdown}
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 pb-4">
          <button
            className="btn btn-primary w-100 shadow"
            type="button"
            onClick={() => moveGroupHandler()}
          >
            Move
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

MoveGroupModal.propTypes = {
  showModal: PropTypes.bool,
  groupId: PropTypes.string,
  handleClose: PropTypes.func,
  moveGroup: PropTypes.func
};
